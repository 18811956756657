import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { cipher } from '../actions/helpers'

import i18next from 'i18next'

import { createToken, getTokenList, getTokenEnquire, importToken } from '../actions/api'

import { getDays } from '../actions/helpers'
import { changeDrawerState, setTokenList, setSelectedToken, setTokenEnquire, clearTokenEnquire, setShowPopup, clearTokenList, setUsersPins,
		clearUserPin, clearUserPuk, setShowTokenPopup, setTokenEnquiresList, setShowImportPopup, clearUri, setErrorResponse,
		setActionResultData, setShowActionResult, setShowResetPopup, setWarningsList, setShowSessionEndHandler } from '../actions/localStates'

import tokenIcon from '../img/logo_10.svg'
import confidantsTokenIcon from '../img/logo_12.svg'

import confidantsTokenLeft from '../img/vtms_left.svg'
import confidantsTokenRight from '../img/vtms_right.svg'

import PinPopup from '../components/PinPopup'
import ImportTokenPopup from './ImportTokenPopup'
import ErrorMessagePopup from './ErrorMessagePopup'

// import i18next from 'i18next'

class Drawer extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
	    	drawerOpen: false,
	    	showErrorPopup: false
	    }

	    this.handleDrawerClick = this.handleDrawerClick.bind(this);
	    this.createToken = this.createToken.bind(this);
	    this.handleShowPopup = this.handleShowPopup.bind(this);

	    this.handleShowImportPopup = this.handleShowImportPopup.bind(this);
	    this.importToken = this.importToken.bind(this);
	}

	componentDidMount() {
		this.setState({drawerOpen: false})
		this.props.actions.changeDrawerState(false);
	}

	handleDrawerClick () {
		this.setState({drawerOpen: !this.state.drawerOpen})
		this.props.actions.changeDrawerState(!this.state.drawerOpen);
	}

	changeSelectedToken (index) {
		var _this = this, enquires, currentTime = new Date().getTime() / 1000;
		var warnings = this.props.tokenList.warningsList;
		
		if (currentTime > this.props.base.expireTime) {
			this.props.actions.setShowSessionEndHandler(true);
			return;
		};

		this.props.actions.clearTokenEnquire();

		if (Object.keys(this.props.userReducer.usersPins).length === 0 && this.props.userReducer.usersPins.constructor === Object) {
			this.props.actions.clearTokenEnquire();
			this.props.actions.setSelectedToken(index);

			enquires = this.props.tokenList.tokenEnquiresList;
            enquires[this.props.tokenList.list[index].uri] = this.props.tokenList.list[index];
            
            if (this.props.tokenList.list[index].tokenContentDescriptorCached !== null) {
            	this.props.actions.setTokenEnquiresList(enquires);
            	window.setTimeout(function () {
					_this.props.actions.setTokenEnquire(_this.props.tokenList.list[index]);
					_this.props.actions.setSelectedToken(index);

					if (warnings[_this.props.tokenList.list[index].uri] === null || warnings[_this.props.tokenList.list[index].uri] === undefined) {
						if (_this.props.tokenList.list[index].tokenContentDescriptorCached.activeKeys.AGREEMENT !== null && _this.props.tokenList.list[index].tokenContentDescriptorCached.activeKeys.AGREEMENT !== undefined) {
	                        if (_this.props.actions.getDays(_this.props.tokenList.list[index].tokenContentDescriptorCached.certificateInfos[_this.props.tokenList.list[index].tokenContentDescriptorCached.activeKeys.AGREEMENT].notAfter) < 15 && (_this.props.tokenList.list[index].tokenContentDescriptorCached.certificateStatuses[_this.props.tokenList.list[index].tokenContentDescriptorCached.activeKeys.AGREEMENT] !== "REVOKED")) {
	                            warnings[_this.props.tokenList.list[index].uri] = true;
	                        }
	                    }

	                    if (_this.props.tokenList.list[index].tokenContentDescriptorCached.activeKeys.SIGNATURE !== null && _this.props.tokenList.list[index].tokenContentDescriptorCached.activeKeys.SIGNATURE !== undefined) {
	                        if (_this.props.actions.getDays(_this.props.tokenList.list[index].tokenContentDescriptorCached.certificateInfos[_this.props.tokenList.list[index].tokenContentDescriptorCached.activeKeys.SIGNATURE].notAfter) < 15 && (_this.props.tokenList.list[index].tokenContentDescriptorCached.certificateStatuses[_this.props.tokenList.list[index].tokenContentDescriptorCached.activeKeys.SIGNATURE] !== "REVOKED")) {
	                            warnings[_this.props.tokenList.list[index].uri] = true;
	                        }
	                    }

	                    _this.props.actions.setWarningsList(warnings)
						// console.log("--------------------------")
						// console.log(_this.props.tokenList.list[index].uri)
						// console.log(warnings[_this.props.tokenList.list[index].uri])
					}
				}, 25)
            }

            if (_this.props.tokenList.list[index].status === "RESET_BY_SO") {
				_this.props.actions.setShowResetPopup(true);
			}

		} else {
			if (this.props.userReducer.usersPins[this.props.tokenList.list[index].uri] !== undefined && this.props.userReducer.usersPins[this.props.tokenList.list[index].uri].length > 0) {
				this.props.actions.setShowTokenPopup(false);

				enquires = this.props.tokenList.tokenEnquiresList

				if (enquires[this.props.tokenList.list[index].uri] === undefined) {
					var jsonObject = {
						pin: this.props.userReducer.usersPins[this.props.tokenList.list[index].uri]
					};

					// var selectedIndex = this.props.tokenList.selectedToken;
					// this.props.actions.clearTokenEnquire();

					var cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, this.props.login.currentUtcDateTime);

					var data = {
						"ciHsmTokenUri": this.props.tokenList.list[index].uri,
						"encryptedData": cipherResult.encryptedData,
						"secretKey": cipherResult.secretKey
					}

					this.props.actions.getTokenEnquire(this.props.base.serviceUrl, this.props.login.accessToken, data)
					.then((response) => {
						enquires[this.props.tokenList.list[index].uri] = response;
						this.props.actions.setTokenEnquiresList(enquires);
						this.props.actions.setTokenEnquire(response);
						this.props.actions.setSelectedToken(index);
						if (this.props.tokenList.list[index].status === "RESET_BY_SO") {
							this.props.actions.setShowResetPopup(true);
						}
						console.log(response);
					});
				} else {
					window.setTimeout(function () {
						_this.props.actions.setTokenEnquire(_this.props.tokenList.tokenEnquiresList[_this.props.tokenList.list[index].uri]);
						_this.props.actions.setSelectedToken(index);
						if (_this.props.tokenList.list[index].status === "RESET_BY_SO") {
							_this.props.actions.setShowResetPopup(true);
						}
					}, 25)
				}

				
			} else {
				this.props.actions.clearTokenEnquire();
				this.props.actions.setSelectedToken(index);

				enquires = this.props.tokenList.tokenEnquiresList;
	            enquires[this.props.tokenList.list[index].uri] = this.props.tokenList.list[index];
	            
	            if (this.props.tokenList.list[index].tokenContentDescriptorCached !== null) {
		            this.props.actions.setTokenEnquiresList(enquires);
	            	window.setTimeout(function () {
						_this.props.actions.setTokenEnquire(_this.props.tokenList.list[index]);
						_this.props.actions.setSelectedToken(index);
						
					}, 25)
	            }

	            if (_this.props.tokenList.list[index].status === "RESET_BY_SO") {
					_this.props.actions.setShowResetPopup(true);
				}
			}
		}

	}

	showMessagePopup () {
    	this.setState({
          	showErrorPopup: !this.state.showErrorPopup
      	});
  	}

	handleShowPopup () {
		if (this.props.userReducer.userTempPin === null) {
			this.props.actions.setShowPopup(true);
		} else {
			this.createToken();
		}
	}

	handleShowImportPopup () {
		if (this.props.userReducer.userTempPin === null) {
			this.props.actions.setShowImportPopup(true);
		} else {
			this.importToken();
		}
	}

	importToken (pin, uri) {
		var publicKey = this.props.login.publicKeyTextual, sortedArray = [], resultObject;
		var date = this.props.login.currentUtcDateTime;

		this.props.actions.setShowImportPopup(false);

		var currentTime = new Date().getTime() / 1000;
		
		if (currentTime > this.props.base.expireTime) {
			this.props.actions.setShowSessionEndHandler(true);
			return;
		};

		var jsonObject = {
			pin: this.props.userReducer.userTempPin || pin
		};

		var cipherResult = cipher(jsonObject, publicKey, date);

		console.log(jsonObject);
		console.log(cipherResult.secretKey);
		console.log(cipherResult.encryptedData);

		var data = {
			"ciHsmTokenUri": this.props.userReducer.tempUri || uri,
			"encryptedData": cipherResult.encryptedData,
			"secretKey": cipherResult.secretKey
		}

		this.props.actions.importToken(this.props.base.serviceUrl, this.props.login.accessToken, data)
		.then((response) => {
			console.log(response);

			if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
				var pins = this.props.userReducer.usersPins;
				pins[this.props.userReducer.tempUri || uri] = this.props.userReducer.userTempPin;

				this.props.actions.setUsersPins(pins);
				console.log(pins);

				this.props.actions.getTokenList(this.props.base.serviceUrl, this.props.login.accessToken)
				.then((response) => {
					// this.props.actions.clearTokenList();
					this.props.actions.clearUserPin();
					this.props.actions.clearUri();
					
					function search(nameKey, myArray){
		                for (var i = 0; i < myArray.length; i += 1) {
		                    if (myArray[i].uri === nameKey) {
		                        return myArray[i];
		                    }
		                }
		            }

		            // eslint-disable-next-line
		            response.map((key) => {
		                if (key.tokenContentDescriptorCached !== null && key.tokenContentDescriptorCached !== undefined && Object.keys(key.tokenContentDescriptorCached.activeKeys).length > 0) {
		                    if ((key.tokenContentDescriptorCached.certificateInfos[key.tokenContentDescriptorCached.activeKeys.AGREEMENT] !== undefined && key.tokenContentDescriptorCached.certificateInfos[key.tokenContentDescriptorCached.activeKeys.AGREEMENT].extensions.isElectronicStamp === false) || (key.tokenContentDescriptorCached.certificateInfos[key.tokenContentDescriptorCached.activeKeys.SIGNATURE] !== undefined && key.tokenContentDescriptorCached.certificateInfos[key.tokenContentDescriptorCached.activeKeys.SIGNATURE].extensions.isElectronicStamp === false)) {
		                        sortedArray.push(key)
		                    }
		                }
		            })

		            // eslint-disable-next-line
		            response.map((key) => {
		                if (key.tokenContentDescriptorCached !== null && key.tokenContentDescriptorCached !== undefined && Object.keys(key.tokenContentDescriptorCached.activeKeys).length > 0) {
		                    if ((key.tokenContentDescriptorCached.certificateInfos[key.tokenContentDescriptorCached.activeKeys.AGREEMENT] !== undefined && key.tokenContentDescriptorCached.certificateInfos[key.tokenContentDescriptorCached.activeKeys.AGREEMENT].extensions.isElectronicStamp === true) || (key.tokenContentDescriptorCached.certificateInfos[key.tokenContentDescriptorCached.activeKeys.SIGNATURE] !== undefined && key.tokenContentDescriptorCached.certificateInfos[key.tokenContentDescriptorCached.activeKeys.SIGNATURE].extensions.isElectronicStamp === true)) {

		                        resultObject = search(key.uri, sortedArray);
		                        if (resultObject === undefined) {
		                            sortedArray.push(key)
		                        }
		                    }
		                }
		            })

		            // eslint-disable-next-line
		            response.map((key) => {
		                resultObject = search(key.uri, sortedArray);
		                if (resultObject === undefined) {
		                    sortedArray.push(key)
		                }
		            })

		            // console.log(sortedArray)

		            this.props.actions.setTokenList(sortedArray)

					console.log(response)

					if (sortedArray.length > 0) {
						this.props.actions.setSelectedToken(0)
					}

					this.props.actions.setActionResultData({status: "success", action: "importTokenLabel", message: i18next.t("success")});
                	this.props.actions.setShowActionResult(true);
				});
			} else {
				var tempData = this.props.userReducer.usersPins;

				delete tempData[this.props.userReducer.tempUri || uri]; 
				this.props.actions.setUsersPins(tempData);

				this.props.actions.clearUserPin();
				this.props.actions.clearUri();

				this.props.actions.setErrorResponse(response.json)
				this.setState({showErrorPopup: true})
			}
		});
	}

	createToken (pin, puk) {
		var publicKey = this.props.login.publicKeyTextual, _this = this, uri, index, sortedArray = [], resultObject;
		var date = this.props.login.currentUtcDateTime;

		this.props.actions.setShowPopup(false);

		var currentTime = new Date().getTime() / 1000;
		
		if (currentTime > this.props.base.expireTime) {
			this.props.actions.setShowSessionEndHandler(true);
			return;
		};

	    var jsonObject = {
			pin: this.props.userReducer.userTempPin || pin,
			puk: this.props.userReducer.userTempPuk || puk
		};

		var cipherResult = cipher(jsonObject, publicKey, date);

		console.log(jsonObject);
		console.log(cipherResult.secretKey);
		console.log(cipherResult.encryptedData);

		var data = {
			"encryptedData": cipherResult.encryptedData,
			"secretKey": cipherResult.secretKey
		}

		this.props.actions.createToken(this.props.base.serviceUrl, this.props.login.accessToken, data)
		.then((response) => {
			if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
				console.log(response);

				var pins = this.props.userReducer.usersPins;
				pins[response.uri] = this.props.userReducer.userTempPin || pin;
				uri = response.uri;

				this.props.actions.setUsersPins(pins);
				console.log(pins);

				this.props.actions.getTokenList(this.props.base.serviceUrl, this.props.login.accessToken)
				.then((response) => {
					// this.props.actions.clearTokenList();
					this.props.actions.clearUserPin();
					this.props.actions.clearUserPuk();

					if (response.code !== undefined) {
		                this.props.actions.setErrorResponse(response.json)
						this.setState({showErrorPopup: true})
		                return
		            }

		            function search(nameKey, myArray){
		                for (var i = 0; i < myArray.length; i += 1) {
		                    if (myArray[i].uri === nameKey) {
		                        return myArray[i];
		                    }
		                }
		            }

		            // eslint-disable-next-line
		            response.map((key) => {
		                if (key.tokenContentDescriptorCached !== null && key.tokenContentDescriptorCached !== undefined && Object.keys(key.tokenContentDescriptorCached.activeKeys).length > 0) {
		                    if ((key.tokenContentDescriptorCached.certificateInfos[key.tokenContentDescriptorCached.activeKeys.AGREEMENT] !== undefined && key.tokenContentDescriptorCached.certificateInfos[key.tokenContentDescriptorCached.activeKeys.AGREEMENT].extensions.isElectronicStamp === false) || (key.tokenContentDescriptorCached.certificateInfos[key.tokenContentDescriptorCached.activeKeys.SIGNATURE] !== undefined && key.tokenContentDescriptorCached.certificateInfos[key.tokenContentDescriptorCached.activeKeys.SIGNATURE].extensions.isElectronicStamp === false)) {
		                        sortedArray.push(key)
		                    }
		                }
		            })

		            // eslint-disable-next-line
		            response.map((key) => {
		                if (key.tokenContentDescriptorCached !== null && key.tokenContentDescriptorCached !== undefined && Object.keys(key.tokenContentDescriptorCached.activeKeys).length > 0) {
		                    if ((key.tokenContentDescriptorCached.certificateInfos[key.tokenContentDescriptorCached.activeKeys.AGREEMENT] !== undefined && key.tokenContentDescriptorCached.certificateInfos[key.tokenContentDescriptorCached.activeKeys.AGREEMENT].extensions.isElectronicStamp === true) || (key.tokenContentDescriptorCached.certificateInfos[key.tokenContentDescriptorCached.activeKeys.SIGNATURE] !== undefined && key.tokenContentDescriptorCached.certificateInfos[key.tokenContentDescriptorCached.activeKeys.SIGNATURE].extensions.isElectronicStamp === true)) {

		                        resultObject = search(key.uri, sortedArray);
		                        if (resultObject === undefined) {
		                            sortedArray.push(key)
		                        }
		                    }
		                }
		            })

		            // eslint-disable-next-line
		            response.map((key) => {
		                resultObject = search(key.uri, sortedArray);
		                if (resultObject === undefined) {
		                    sortedArray.push(key)
		                }
		            })

		            // console.log(sortedArray)

		            this.props.actions.setTokenList(sortedArray)
		            
					// this.props.actions.setTokenList(response)

					console.log(response)

					sortedArray.forEach(function (key, i) {
						if (key.uri === uri) {
							index = i;
						}
					})

					if (sortedArray.length > 0) {
						this.props.actions.clearTokenEnquire();
						this.props.actions.setSelectedToken(index)

		                var enquires = this.props.tokenList.tokenEnquiresList;
		                enquires[sortedArray[index].uri] = sortedArray[index];

		                if (sortedArray[index].tokenContentDescriptorCached !== null){
		                	window.setTimeout(function () {
		                    _this.props.actions.setTokenEnquiresList(enquires);
		                    _this.props.actions.setTokenEnquire(sortedArray[index]);
		                    }, 25)
		                }
					}
				});
			} else {
				this.props.actions.clearUserPin();
				this.props.actions.clearUserPuk();
				this.props.actions.setErrorResponse(response.json)
				this.setState({showErrorPopup: true})
			}
		});

	}

	render() {
		let drawerClasses = 'drawer'
		if (this.state.drawerOpen) {
		    drawerClasses = 'drawer open'
		}

		return (
			<div>
				<nav className={drawerClasses}>
			      	<ul style={{"paddingLeft": "10px"}}>
			      		<drawer-li style={{margin: "0px"}}>
				          <div style={{"cursor": "pointer"}}>
					          <span style={{float: "right", marginRight: "18px", marginTop: "12px"}} onClick={this.handleDrawerClick}>
					          {
					          	this.state.drawerOpen
					          	? <i style={{color: "#1C92C7", fontSize: "2.5em"}} className="fa fa-chevron-circle-left"></i>
					          	: <i style={{color: "#1C92C7", fontSize: "2.5em"}} className="fa fa-chevron-circle-right"></i>
					          }
					          </span>
				          </div>
				        </drawer-li>
				        <drawer-li style={{marginTop: "0px"}}>
				            <div style={{"cursor": "pointer"}}>
					          	{
					          		this.state.drawerOpen
					          		? <div style={{marginBottom: "10px"}}>
					          			<button className="btn btn-info btn-block" style={{maxWidth: "230px"}} type="button" onClick={this.handleShowPopup}>{i18next.t("createTokenLabel")}</button>
					          		</div>
					          		: <span style={{float: "right", marginRight: "18px"}}><i onClick={this.handleShowPopup} style={{color: "#1C92C7", fontSize: "2.5em"}} className="fa fa-plus-circle"></i></span>
					          	}
					          	{
					          		this.state.drawerOpen
					          		? <div style={{marginBottom: "10px"}}>
					          			<button className="btn btn-info btn-block" style={{maxWidth: "230px"}} type="button" onClick={this.handleShowImportPopup}>{i18next.t("importTokenLabel")}</button>
					          		</div>
					          		: <span style={{float: "right", marginRight: "12px"}}><i onClick={this.handleShowImportPopup} style={{color: "#1C92C7", fontSize: "2.5em"}} className="fa fa-cloud-upload"></i></span>
					          	}
				            </div>
				        </drawer-li>
				        {
							this.props.tokenList.list.length > 0
							? <div>
								{
									this.props.tokenList.list.map((token, index) => {
									    return (<drawer-li key={index} style={{marginTop: "0px"}}>
										            <div style={{"cursor": "pointer"}}>
											          	{
											          		this.state.drawerOpen
											          		? <span onClick={this.changeSelectedToken.bind(this, index)}>
											          				{
											          					token.confidants !== null && token.confidants !== undefined && token.confidants.length > 0 && (token.owner.id === this.props.login.userData.userName)
											          					? <div><img className="img" width="44" heigth="44" src={(index === this.props.tokenList.selectedToken && token.owner.id === this.props.login.userData.userName) ? confidantsTokenRight : confidantsTokenLeft} alt={token.owner.id === this.props.login.userData.userName ? confidantsTokenRight : confidantsTokenLeft} />&nbsp;{token.title}</div>
											          					: <div><img className={index === this.props.tokenList.selectedToken ? "img active-token-img" : "img"} width="44" heigth="44" src={token.owner.id === this.props.login.userData.userName ? tokenIcon : confidantsTokenIcon} alt={token.owner.id === this.props.login.userData.userName ? tokenIcon : confidantsTokenIcon} />&nbsp;{token.title}</div>
											          				}
											          			</span>
											          		: <span onClick={this.changeSelectedToken.bind(this, index)} style={{float: "right", marginRight: "12px"}}>
											          				{
											          					token.confidants !== null && token.confidants !== undefined && token.confidants.length > 0 && (token.owner.id === this.props.login.userData.userName)
											          					? <img className="img" width="44" heigth="44" src={index === this.props.tokenList.selectedToken && token.owner.id === this.props.login.userData.userName ? confidantsTokenRight : confidantsTokenLeft} alt={token.owner.id === this.props.login.userData.userName ? confidantsTokenRight : confidantsTokenLeft} />
											          					: <img className={index === this.props.tokenList.selectedToken ? "img active-token-img" : "img"} width="44" heigth="44" src={token.owner.id === this.props.login.userData.userName ? tokenIcon : confidantsTokenIcon} alt={token.owner.id === this.props.login.userData.userName ? tokenIcon : confidantsTokenIcon} />
											          				}
											          			</span>
											          	}
										            </div>
										        </drawer-li>)
									})
								}
							</div>
							: null
						}
			      	</ul>
			    </nav>
			    <div>
					{
						this.props.userReducer.showPopup
						? <PinPopup closeClientPopup={this.createToken.bind(this)} />
			            : null
			        }
			        {
						this.props.userReducer.showImportPopup
						? <ImportTokenPopup closeImportPopup={this.importToken.bind(this)} />
			            : null
			        }
			        {
						this.state.showErrorPopup
						? <ErrorMessagePopup closeMessagePopup={this.showMessagePopup.bind(this)} data={this.props.userReducer.errorResponse} />
						: null
					}
		        </div>
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer,
        tokenList: state.tokenListReducer,
        userReducer: state.userReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	createToken,
    	changeDrawerState,
    	getTokenList,
    	setTokenList, setSelectedToken,
    	getTokenEnquire, setTokenEnquire,
    	clearTokenEnquire, setShowPopup,
    	setUsersPins, clearTokenList,
    	clearUserPin, clearUserPuk,
    	setShowTokenPopup, setTokenEnquiresList,
    	setShowImportPopup, importToken, clearUri,
    	setErrorResponse, setActionResultData,
		setShowActionResult, setShowResetPopup,
		setWarningsList, getDays, setShowSessionEndHandler
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Drawer);