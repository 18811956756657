import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

import { setShowTrusteesPopup, setTokenEnquire, clearTokenEnquire, setTokenEnquiresList, setTokenList } from '../actions/localStates'
import { removeConfidants, getTokenInfoCached } from '../actions/api'

class TrusteesPopup extends Component {

  constructor(props) {
      super(props);

      this.state = {
          confidants: this.props.tokenList.list[this.props.tokenList.selectedToken].confidants
      };

      this.close = this.close.bind(this);
      this.closePopup = this.closePopup.bind(this)
      // this.deleteConfidant = this.deleteConfidant.bind(this);
  }

  componentDidMount() {
     window.addEventListener("keyup", this.closePopup);
  }

  closePopup (e) {
    if (e.keyCode === 13) {
        this.close()
    }
  }

  componentWillUnmount() {

  }

  deleteConfidant (id) {
    var _this = this;
    var data = {
      ciHsmTokenUri : this.props.tokenList.list[this.props.tokenList.selectedToken].uri,
      confidantsIdsToRemove : [id]
    }
    
    this.props.actions.removeConfidants(this.props.base.serviceUrl, this.props.login.accessToken, data)
    .then((response) => {
      console.log(response)
    })
    .then(() => {
      data = {
        ciHsmTokenUri: this.props.tokenList.list[this.props.tokenList.selectedToken].uri
      }
      this.props.actions.getTokenInfoCached(this.props.base.serviceUrl, this.props.login.accessToken, data)
      .then((response) => {
        console.log(response)
        _this.props.actions.clearTokenEnquire();

        var tokenListArr = _this.props.tokenList.list

        var enquires = _this.props.tokenList.tokenEnquiresList;
        enquires[_this.props.tokenList.list[_this.props.tokenList.selectedToken].uri] = response;

        for (var i = 0; i < tokenListArr.length; i += 1) {
          if (tokenListArr[i].uri === _this.props.tokenList.list[_this.props.tokenList.selectedToken].uri) {
              tokenListArr[i] = response
          }
        }

        _this.props.actions.setTokenList(tokenListArr)
            
        if (_this.props.tokenList.list[_this.props.tokenList.selectedToken].tokenContentDescriptorCached !== null) {
              _this.props.actions.setTokenEnquire(response);
              _this.props.actions.setTokenEnquiresList(enquires);
        }
        _this.setState({"confidants": response.confidants})
      })
    })
  }

  deleteConfidantsAll () {
    var ids = [], _this = this;
    // eslint-disable-next-line
    this.props.tokenList.list[this.props.tokenList.selectedToken].confidants.map((confidant, index) => {
      ids.push(confidant.id)
    })

    var data = {
      ciHsmTokenUri : this.props.tokenList.list[this.props.tokenList.selectedToken].uri,
      confidantsIdsToRemove : ids
    }

    this.props.actions.removeConfidants(this.props.base.serviceUrl, this.props.login.accessToken, data)
    .then((response) => {
      console.log(response)
    })
    .then(() => {
      data = {
        ciHsmTokenUri: this.props.tokenList.list[this.props.tokenList.selectedToken].uri
      }
      this.props.actions.getTokenInfoCached(this.props.base.serviceUrl, this.props.login.accessToken, data)
      .then((response) => {
        console.log(response)
        this.props.actions.clearTokenEnquire();
        var tokenListArr = _this.props.tokenList.list

        var enquires = _this.props.tokenList.tokenEnquiresList;
        enquires[_this.props.tokenList.list[_this.props.tokenList.selectedToken].uri] = response;

        for (var i = 0; i < tokenListArr.length; i += 1) {
          if (tokenListArr[i].uri === _this.props.tokenList.list[_this.props.tokenList.selectedToken].uri) {
              tokenListArr[i] = response
          }
        }

        _this.props.actions.setTokenList(tokenListArr)
            
        if (_this.props.tokenList.list[_this.props.tokenList.selectedToken].tokenContentDescriptorCached !== null) {
              _this.props.actions.setTokenEnquire(response);
              _this.props.actions.setTokenEnquiresList(enquires);
        }
        _this.setState({"confidants": response.confidants})
        _this.props.actions.setShowTrusteesPopup(false)
      })
    })
  }

  close () {
    this.props.actions.setShowTrusteesPopup(false)
  }

  render() {
      return (
          <div className='popup'>
              <div className={"popup_inner_confidants"}>
                  <div className="col-12">
                    <h4 style={{paddingLeft: "15px", paddingTop: "30px"}}>{i18next.t("trustees")}</h4>
                      <div className="col-12" style={{minHeight: "100px", maxHeight: "380px", overflowY: "auto"}}>
                        {
                          this.state.confidants.map((confidant, index) => {
                            return (<div className={index % 2 ? "col-12 row odd" : "col-12 row even"} key={index} style={{paddingLeft: "0px", paddingRight: "0px"}}>
                                      <div className="col-8 row" style={{paddingLeft: "0px", paddingRight: "0px", margin: "auto"}}>
                                          <div className="col-1" style={{paddingLeft: "0px", paddingRight: "0px", margin: "auto"}}>
                                            {
                                              index + 1 + "."
                                            }
                                          </div>
                                          <div className="col-11" style={{paddingLeft: "0px", paddingRight: "0px"}}>
                                            <label style={{paddingLeft: "0px", paddingRight: "0px", marginBottom: "0px"}} className="col-12">{confidant.commonName}</label>
                                            <label style={{paddingLeft: "0px", paddingRight: "0px"}} className="col-12">{confidant.id}</label>
                                          </div>
                                      </div>
                                      <div className="col-4" style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "right", margin: "auto"}}>
                                          <button className="btn btn-outline-info" onClick={this.deleteConfidant.bind(this, confidant.id)}>{i18next.t("accessTermination")}</button>
                                      </div>
                                      
                                    </div>)
                          })
                        }
                      </div>
                      <div style={{float: "right", marginRight: "15px", paddingTop: "10px"}}>
                          <button className="btn btn-outline-info" id="submitPin" style={{marginLeft: "5px", marginBottom: "30px"}} onClick={this.deleteConfidantsAll.bind(this)}>{i18next.t("accessTerminationAll")}</button>
                          <button className="btn btn-info" id="closeKey" style={{marginLeft: "5px", marginBottom: "30px"}} onClick={this.close}>{i18next.t("closeLabel")}</button>
                      </div>
                  </div>
              </div>
          </div>
      );
    }
}

function mapStateToProps(state) {
    return {
        base: state.base,
        selectedClient: state.clientReducer,
        login: state.loginReducer,
        tokenList: state.tokenListReducer,
        userReducer: state.userReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
      setShowTrusteesPopup, removeConfidants, getTokenInfoCached, setTokenEnquire, clearTokenEnquire, setTokenEnquiresList,
      setTokenList
    };
    return {
      actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TrusteesPopup);