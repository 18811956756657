var forge = require('node-forge');

export const pageUrl = (params) => (dispatch) => {
	var stringUrl = "";

	stringUrl = params.url + "/" + params.type + "?page=" + params.page + "&size=" + params.size

    return stringUrl;
}

export const tokenUrl = (params) => (dispatch) => {
	var stringUrl = "";

	stringUrl = params.url + "/" + params.type + "?page=" + params.page + "&size=" + params.size + "&subjectType=" + params.subjectType + "&subject=" + params.subject

    return stringUrl;
}

export const searchUrl = (params) => (dispatch) => {
	var stringUrl = "", url = "", type = "", page = "", size = "", clientName = "", fullName = "", status = "",
		userName = "", firstName = "", secondName = "";

	if (params.url !== undefined && params.url.length > 0) {
		url = params.url + "/"
	}

	if (params.type !== undefined && params.type.length > 0) {
		type = params.type
	}

	if (params.page !== undefined) {
		page = "page=" + params.page
	}

	if (params.size !== undefined) {
		size = "&size=" + params.size
	}

	if (params.clientName !== undefined && params.clientName.length > 0) {
		clientName = "&clientName=" + params.clientName
	}

	if (params.fullName !== undefined && params.fullName.length > 0) {
		fullName = "&fullName=" + params.fullName
	}

	if (params.status !== undefined && params.status.length > 0) {
		status = "&status=" + params.status
	}

	if (params.userName !== undefined && params.userName.length > 0) {
		userName = "&userName=" + params.userName
	}

	if (params.firstName !== undefined && params.firstName.length > 0) {
		firstName = "&firstName=" + params.firstName
	}

	if (params.secondName !== undefined && params.secondName.length > 0) {
		secondName = "&secondName=" + params.secondName
	}


	stringUrl = url + type + "?" + page + size + clientName + fullName + status + userName + firstName + secondName

    return stringUrl;
}

export const formatDate = (dateInString) => (dispatch) => {
	var options = {
	    timeZone: "Europe/Kiev",
	    "day": "numeric",
        "month": "numeric",
        "year": "numeric",
        "hour": "2-digit",
        "minute": "2-digit",
        "second": "2-digit"
	};

	var formatter = new Intl.DateTimeFormat([], options);

	var UTCTime = dateInString;
	var localTime = formatter.format(new Date(UTCTime));
	// var currentTime = formatter.format(new Date()); console.log(currentTime, localTime);
	// var dt = new Date(Date.parse(dateInString));
 //    var localDate = dt;

 //    var gmt = localDate;
 //    var min = gmt.getTime() / 1000 / 60;
 //    var localNow = new Date().getTimezoneOffset();
 //    var localTime = min - localNow;

 //    var dateStr = new Date(localTime * 1000 * 60);
	// var locale = window.navigator.userLanguage || window.navigator.language;

	// var clientDateTimeStr = dateStr.toLocaleString(locale, {
	  	// "day": "numeric",
    //     "month": "numeric",
    //     "year": "numeric",
    //     "hour": "2-digit",
    //     "minute": "2-digit",
    //     "second": "2-digit"
	// });

	return localTime;
}

export const getDays = (digit, value) => (dispatch) => {
	var result

	var delta = new Date(digit).getTime() - Date.now()

	function div(val, by) {
	    return (val - val % by) / by;
	}

	result = {
		value: div(delta, 1000*60*60*24),
		unit: "days"
	}

	// console.log(result)

	if (result.value < 0) {
		return 0;
	} else {
		return result.value;
	}
}

export const fromSeconds = (digit, value) => (dispatch) => {
	var result, year = 31556952,
			month  = 2629746,
			day = 86400,
			hour = 3600,
			minute = 60;

		function div(val, by) {
		    return (val - val % by) / by;
		}

		function isInt(n) {
			return Number(n) === n && n % 1 === 0
		}

		if ((digit % year) === 0 && isInt(div(digit, year))) {
			result = {
				value: div(digit, year),
				unit: "years"
			}
		} else if ((digit % month) === 0 && isInt(div(digit, month))) {
			result = {
				value: div(digit, month),
				unit: "months"
			}
		} else if ((digit % day) === 0 && isInt(div(digit, day))) {
			result = {
				value: div(digit, day),
				unit: "days"
			}
		} else if ((digit % hour) === 0 && isInt(div(digit, hour))) {
			result = {
				value: div(digit, hour),
				unit: "hours"
			}
		} else if ((digit % minute) === 0 && isInt(div(digit, minute))) {
			result = {
				value: div(digit, minute),
				unit: "minutes"
			}
		} else {
			result = {
				value: digit,
				unit: "seconds"
			}
		}
	return result;
}

export const toSeconds = (digit, value) => (dispatch) => {
	var totalSeconds, year = 31556952,
			month  = 2629746,
			day = 86400,
			hour = 3600,
			minute = 60;

	switch(value) {
		case "years":
			totalSeconds = year * digit
			break;

		case "months":
			totalSeconds = month * digit
			break;

		case "days":
			totalSeconds = day * digit
			break;

		case "hours":
			totalSeconds = hour * digit
			break;

		case "minutes":
			totalSeconds = minute * digit
			break;

		default:
			totalSeconds = digit
			break;
	}

	return totalSeconds;
}

export const isIEOrEdge = () => (dispatch) => {
	var ua = window.navigator.userAgent;

    var msie = ua.indexOf('MSIE ');
		if (msie > 0) {
		// IE 10 or older => return version number
		return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
	}

	var trident = ua.indexOf('Trident/');
	if (trident > 0) {
	// IE 11 => return version number
		var rv = ua.indexOf('rv:');
		return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
	}

	// other browser
	return false;
}

export function cipher (objectJson, publicKeyInput, date) {
	var pki = forge.pki;

	var key = forge.random.getBytesSync(16);
	var iv = forge.util.hexToBytes("00000000000000000000000000000000")
	var cipher = forge.cipher.createCipher('AES-CTR', key);

	var jsonObject = JSON.stringify(objectJson);

	// console.log(jsonObject);

	cipher.start({iv: iv});
	cipher.update(forge.util.createBuffer(jsonObject));
	cipher.finish();

	var encryptedjsonObject = cipher.output;

	// console.log(encryptedjsonObject.toHex());

	// var currentDate = new Date();
	//currentDate.setSeconds(currentDate.getSeconds() + 5);
	// var dateString = currentDate.toISOString().substring(0, 19) + "Z";
	// console.log(dateString);
	// console.log(date);

	var buffer = forge.util.createBuffer(key, 'raw');
	buffer.putBytes(forge.util.encodeUtf8(date));

	var bytes = buffer.getBytes();
	var publicKey = pki.publicKeyFromPem(publicKeyInput);

	var encrypted = publicKey.encrypt(bytes, 'RSA-OAEP', {
	  md: forge.md.sha256.create(),
	  mgf1: {
	    md: forge.md.sha256.create()
	  }
	});
	// console.log(forge.util.bytesToHex(encrypted));
	var data = {
		secretKey: forge.util.encode64(encrypted),
		encryptedData: forge.util.encode64(encryptedjsonObject.getBytes())
	}

	return data;
}