const baseState = {
    userTempPin: null,
    userTempPuk: null,
    tempUri: null,
    showPopup: false,
    showImportPopup: false,
    showTokenPopup: false,
    usersPins: {},
    tempId: null,
    showImportCertificatePopup: false,
    showGenerateKeyPopup: false,
    certificateData: null,
    fileName: "",
    errorResponse: null,
    twoFactorChannelId: null,
    twoFactorSettings: null,
    twoFactorAvailabelChannels: null,
    showTwoFactorSettings: false,
    need2fConfirm: false,
    activeView: "body",
    showChangePinPopup: false,
    oldPin: "",
    newPin: "",
    showChangePinByPukPopup: false,
    puk: "",
    showTokenPukPopup: false,
    userPuk: "",
    showResetPopup: false,
    pukForReset: "",
    newPinForReset: "",
    newPukForReset: ""
}

const userReducer = (state = baseState, { type, payload }) => {

    switch (type) {
    	case "SET_USER_PIN": {
			return {
				...state,
				userTempPin: payload
			}
		}

		case "CLEAR_USER_PIN": {
			return {
				...state,
				userTempPin: null
			}
		}

		case "SET_URI": {
			return {
				...state,
				tempUri: payload
			}
		}

		case "CLEAR_URI": {
			return {
				...state,
				tempUri: null
			}
		}

		case "SET_USER_PUK": {
			return {
				...state,
				userTempPuk: payload
			}
		}

		case "CLEAR_USER_PUK": {
			return {
				...state,
				userTempPuk: null
			}
		}

		case "SET_SHOW_POPUP": {
			return {
				...state,
				showPopup: payload
			}
		}

		case "SET_SHOW_IMPORT_POPUP": {
			return {
				...state,
				showImportPopup: payload
			}
		}

		case "SET_SHOW_TOKEN_POPUP": {
			return {
				...state,
				showTokenPopup: payload
			}
		}

		case "SET_SHOW_TOKEN_PUK_POPUP": {
			return {
				...state,
				showTokenPukPopup: payload
			}
		}

		case "SET_ALL_USER_FIELDS": {
			return {
				...state,
			    twoFactorChannelId: payload.twoFactorChannelId || null,
				twoFactorSettings: payload.twoFactorSettings || null,
				confirmedProfile: payload.confirmedProfile || null
			}
		}

		case "CLEAR_ALL_USER_FIELDS": {
			return {
				...state,
			    twoFactorChannelId: null,
				twoFactorSettings: null,
				confirmedProfile: null
			}
		}

		case "SET_SHOW_IMPORT_CERTIFICATE_POPUP": {
			return {
				...state,
				showImportCertificatePopup: payload
			}
		}

		case "SET_SHOW_GENERATE_KEY_POPUP": {
			return {
				...state,
				showGenerateKeyPopup: payload
			}
		}

		case "SET_SHOW_CHANGE_PIN_POPUP": {
			return {
				...state,
				showChangePinPopup: payload
			}
		}

		case "SET_USERS_PINS": {
			return {
				...state,
				usersPins: payload
			}
		}

		case "CLEAR_USERS_PINS": {
			return {
				...state,
				usersPins: {}
			}
		}

		case "SET_TEMP_ID": {
			return {
				...state,
				tempId: payload
			}
		}

		case "CLEAR_TEMP_ID": {
			return {
				...state,
				tempId: null
			}
		}

		case "SET_CERTIFICATE_DATA": {
			return {
				...state,
				certificateData: payload
			}
		}

		case "CLEAR_CERTIFICATE_DATA": {
			return {
				...state,
				certificateData: null
			}
		}

		case "SET_CERTIFICATE_FILE_NAME": {
			return {
				...state,
				fileName: payload
			}
		}

		case "CLEAR_CERTIFICATE_FILE_NAME": {
			return {
				...state,
				fileName: ""
			}
		}

		case "SET_ERROR_RESPONSE": {
			return {
				...state,
				errorResponse: payload
			}
		}

		case "CLEAR_ERROR_RESPONSE": {
			return {
				...state,
				errorResponse: null
			}
		}

		case "SET_TWO_FACTOR_CHANNEL_ID": {
			return {
				...state,
				twoFactorChannelId: payload
			}
		}

		case "SET_TWO_FACTOR_SETTINGS": {
			return {
				...state,
				twoFactorSettings: payload
			}
		}

		case "SET_TWO_FACTOR_AVAILABEL_CHANNELS": {
			return {
				...state,
				twoFactorAvailabelChannels: payload
			}
		}

		case "SET_SHOW_TWO_FACTOR_SETTINGS": {
			return {
				...state,
				showTwoFactorSettings: payload
			}
		}

		case "SET_NEED_2F_CONFIRM": {
			return {
				...state,
				need2fConfirm: payload
			}
		}

		case "SET_ACTIVE_VIEW": {
			return {
				...state,
				activeView: payload
			}
		}

		case "SET_OLD_PIN": {
			return {
				...state,
				oldPin: payload
			}
		}

		case "SET_NEW_PIN": {
			return {
				...state,
				newPin: payload
			}
		}

		case "SET_PUK": {
			return {
				...state,
				puk: payload
			}
		}

		case "SET_USER_PUK_CODE": {
			return {
				...state,
				userPuk: payload
			}
		}

		case "SET_SHOW_CHANGE_PIN_BY_PUK_POPUP": {
			return {
				...state,
				showChangePinByPukPopup: payload
			}
		}

		case "SET_SHOW_RESET_POPUP": {
			return {
				...state,
				showResetPopup: payload
			}
		}

		case "SET_PUK_FOR_RESET": {
			return {
				...state,
				pukForReset: payload
			}
		}

		case "SET_NEW_PIN_FOR_RESET": {
			return {
				...state,
				newPinForReset: payload
			}
		}

		case "SET_NEW_PUK_FOR_RESET": {
			return {
				...state,
				newPukForReset: payload
			}
		}

        default:
            return state;
    }
}

export default userReducer