import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

import { setLoginInput, setPasswordInput, clearLoggedInUserData, setTwoFactorCode, setSelectedProvider, setIsKerberos } from '../actions/localStates'

import logo from '../img/fon4_.svg'

class LoginView extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
	    	showPassword: false
	    }

	    this.onLoginChanged = this.onLoginChanged.bind(this);
	    this.onPasswordChanged = this.onPasswordChanged.bind(this);
	    this.logIn = this.logIn.bind(this);
	    this.keyUpHandler = this.keyUpHandler.bind(this, 'return');
	    this.handlePwdKeyUp = this.keyUpHandler.bind(this, 'PwdInput');

	    this.onTwoFactorCodeChanged = this.onTwoFactorCodeChanged.bind(this);
	    this.handleOneTimeCodeKeyUp = this.keyUpHandler.bind(this, 'oneTimeCodeInput');
	    this.logIn2f = this.logIn2f.bind(this);

	    this.onProviderChanged = this.onProviderChanged.bind(this);
	}

	componentDidMount() {
		document.addEventListener('keyup', this.keyUpHandler);
		this.props.actions.clearLoggedInUserData();
	}

	componentWillUnmount() {
		document.removeEventListener('keyup', this.keyUpHandler);
	}

	onLoginChanged (e) {
		this.props.actions.setLoginInput(e.target.value)
	}

	onPasswordChanged (e) {
		this.props.actions.setPasswordInput(e.target.value)
	}

	logIn () {
		if (this.props.base.isKerberos) {
			this.props.loginKerberos()
		} else {
			this.props.loginUser();
		}
	}

	onTwoFactorCodeChanged (e) {
		this.props.actions.setTwoFactorCode(e.target.value)
	}

	onProviderChanged (e) {
		var isKerberos = false;
		this.props.actions.setSelectedProvider(e.target.value)

		for(var i = 0; i < this.props.base.authProviders.length; i++) {
		    if (this.props.base.authProviders[i].url === e.target.value) {
		    	if (this.props.base.authProviders[i].kerberos !== undefined && this.props.base.authProviders[i].kerberos === "true")
		        isKerberos = true;
		        break;
		    }
		}
		this.props.actions.setIsKerberos(isKerberos)
	}

	logIn2f () {
		this.props.loginUser2f();
	}

	setShowPassword() {
        this.setState({showPassword: this.state.showPassword ? false : true})
    }

    hover() {
        this.setState({showPassword: true})
    }

    out() {
        this.setState({showPassword: false})
    }

    keyUpHandler(refName, e) {
        if (e.keyCode === 13) {
        	if (this.props.login.twoFactorEnabled) {
        		document.getElementById("submit2f").click();
           		document.activeElement.blur()
        	} else {
        		document.getElementById("submit").click();
           		document.activeElement.blur()
        	}
        }
    }

	render() {
		let enableLogin = ((this.props.login.login.length > 0 && this.props.login.password.length > 0) || this.props.base.isKerberos) ? true : false
		let enable2fLogin = this.props.login.twoFactorCode.length >= 4 ? true : false
		return (
			<div className="margin-top">
				<div className="row" style={{padding: "0px", backgroundColor: "#f0ebe6"}}>
					<div className="col-xl-4 col-lg-4 col-md-7 col-sm-8">
						<div className="card col-xl-10 col-lg-10 col-md-10 col-sm-10" style={{marginTop: "150px"}}>
							<div className="card-body">
								{
									this.props.login.twoFactorEnabled
									? <div>
										<h4 style={{paddingBottom: "15px"}}>{i18next.t("twoFactorPageTitle")}</h4>
										<input type="text" placeholder={i18next.t("oneTimeCodeTitle")} className="form-control" value={this.props.login.twoFactorCode} onChange={this.onTwoFactorCodeChanged} onKeyUp={this.handleOneTimeCodeKeyUp} ref="oneTimeCodeInput" />
										{
				                            this.props.tries !== undefined && this.props.tries > 0
				                            ? <label style={{paddingLeft: "0px", marginBottom: "0px", paddingTop: "10px"}}>{i18next.t("triesLeft")}{this.props.tries}</label>
				                            : null
				                        }
										<p></p>
										<button id="submit2f" className="btn btn-info" disabled={!enable2fLogin} onClick={this.logIn2f.bind(this)}>{i18next.t("signInTitle")}</button>
									</div>
									: <div>
										<h4 style={{paddingBottom: "15px"}}>{i18next.t("loginPageTitle")}</h4>
										{
											
											<select type="select" disabled={this.props.base.authProviders.length > 1 ? false : true} className="form-control" defaultValue={this.props.base.selectedAuthProvider} style={{textAlign: "center", marginBottom: "10px"}} onChange={this.onProviderChanged}>
				                                {
				                                  this.props.base.authProviders.map((provider, index) =>
				                                    <option key={index} value={provider.url}>{provider.clientTitle}</option>)
				                                }
				                            </select>
										}
										{
											this.props.base.isKerberos
											? null
											: <div>
												<input type="text" placeholder={i18next.t("loginTitle")} className="form-control" value={this.props.login.login} onChange={this.onLoginChanged} />
												<p></p>
												<div className="input-group">
													<input type={this.state.showPassword ? "input" : "password"} placeholder={i18next.t("passwordTitle")} className="form-control" value={this.props.login.password} onChange={this.onPasswordChanged} onKeyUp={this.handlePwdKeyUp} ref="PwdInput" />
												    <div className="input-group-append">
						                                <div className="input-group-text" onClick={this.setShowPassword.bind(this)} onMouseOver={this.hover.bind(this)} onMouseOut={this.out.bind(this)}><i className="fa fa-eye"></i></div>
						                            </div>
						                        </div>
												<p></p>
											</div>
										}
										
										<button id="submit" className="btn btn-info" disabled={!enableLogin} onClick={this.logIn.bind(this)}>{i18next.t("signInTitle")}</button>
									</div>
								}
							</div>
						</div>
					</div>
					{
						this.props.base.isIEBrowser
						? <div className="FlexItem" style={{padding: "0px"}}>
							<img className="img" src={logo} alt="Logo" />
						</div>
						: <div className="col-xl-8 col-lg-8 col-md-5 col-sm-4 row-login login-img" style={{padding: "0px"}}>
							<img className="img disable-img" src={logo} height="100%" alt="Logo" />
						</div>
					}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    setLoginInput, setPasswordInput,
	    clearLoggedInUserData, setTwoFactorCode,
	    setSelectedProvider, setIsKerberos
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);