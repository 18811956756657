import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setShowActionResult, setActionResultArr } from '../actions/localStates'

import i18next from 'i18next'

class ActionResultInfoPopup extends Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    	activeState: "popup-info fadein-popup",
	    	isOpen: true
	    }

	    this.closeResView = this.closeResView.bind(this);
	}

	closeResView () {
		if (!this.state.isOpen) {
			this.props.actions.setShowActionResult(false)
		} else {
			this.setState({"isOpen": false})
		}
	}

	componentDidMount() {
		var _this = this, resView, timeOut;

		resView = document.getElementById("res-view");

		if (this.props.resultData.status === "success") {
			timeOut = 2000;
		} else {
			timeOut = 9000;
		}

		window.setTimeout(function () {
			if (_this.props.login.isLoggedIn) {
				_this.setState({activeState: "popup-info fadeout-popup"})
            	resView.addEventListener( 'webkitAnimationEnd', function( event ) { _this.closeResView() }, true );
			}
        }, timeOut);
	}

	componentWillUnmount () {
		this.props.actions.setActionResultArr()
	}

	render() {
		return (
			<div id="res-view" className={this.state.activeState} onClick={this.closeResView}>
				{
					this.props.base.resultMessagesArr
					? this.props.base.resultMessagesArr.map((value, index) => {
							return <div className={value.status === "success" ? "popup-info-inner-arr success" : "popup-info-inner-arr failed"}>
								<div style={{paddingTop: "8px", marginLeft: "0px", paddingBottom: "8px", borderRightWidth: "0px", borderRightStyle: "solid", paddingRight: "8px", borderLeftWidth: "0px", borderLeftStyle: "solid", paddingLeft: "8px", lineHeight:"1"}}>
									<div style={{fontSize: "14px", fontWeight: "bold",  color: "#808080", paddingBottom: "5px"}}>
										{i18next.t(value.action)}
									</div>
									<span style={{fontSize: "12px", whiteSpace: "pre-wrap"}}>{value.message}</span>
				                </div>
							</div>
						})
					: <div className={this.props.base.status === "success" ? "popup-info-inner success" : "popup-info-inner failed"}>
						<div style={{paddingTop: "8px", marginLeft: "0px", paddingBottom: "8px", borderRightWidth: "0px", borderRightStyle: "solid", paddingRight: "8px", borderLeftWidth: "0px", borderLeftStyle: "solid", paddingLeft: "8px", lineHeight:"1"}}>
							<div style={{fontSize: "14px", fontWeight: "bold",  color: "#808080", paddingBottom: "5px"}}>
								{i18next.t(this.props.base.action)}
							</div>
							<span style={{fontSize: "12px", whiteSpace: "pre-wrap"}}>{this.props.base.message}</span>
		                </div>
					</div>
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	setShowActionResult,
    	setActionResultArr
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionResultInfoPopup);