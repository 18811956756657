import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cookies from 'universal-cookie';

import i18next from 'i18next'

import { getDays } from "../actions/helpers"

import { getTokenList, getTokenEnquire, login, verifyToken, getUserProfile, getPublicKeyCurrentUtcDateTime, init2f, login2f } from "../actions/api"
import { setTokenList, setSelectedToken, setTokenEnquire, setToken, setExpireInTime, setIsLoggedIn, setLoggedInUserData, setAllUserFields,
		setPublicKeyCurrentUtcDateTime, setErrorResponse, setTwoFactorEnabled, clearTwoFactorEnabled, clearTwoFactorCode,
		setPasswordInput, setUpdate2fSettingsGrant, setCertificateIssuingGrant, setNeed2fConfirm, setExpireTime, setNeedReLogin,
		setShowSessionEndHandler, clearTokenEnquire, setTokenEnquiresList, setTimeDelta, setChangePasswordGrant, setShowResetPopup,
        setWarningsList, setNeedToLogout } from "../actions/localStates"

import Body from '../components/Body';
import DrawerContainer from './DrawerContainer'
import ErrorMessagePopup from '../components/ErrorMessagePopup'
import TwoFactorPopup from '../components/TwoFactorPopup'

const cookies = new Cookies();

class BodyContainer extends Component {
	constructor() {
		super();
		this.state = {
		  seconds: 0,
		  settedTimeValue: false,
		  token: "",
		  showErrorPopup: false,
		  needPing: false,
          secondsSinceLastActivity: 0
		};

		this.loginRef = React.createRef();

		this.timer = 0;
		this.startTimer = this.startTimer.bind(this);
		this.countDown = this.countDown.bind(this);
		this.setTime = this.setTime.bind(this);
		this.setTimeValue = this.setTimeValue.bind(this);

		this.login2f = this.login2f.bind(this);
		this.login = this.login.bind(this);
        this.loginKerberos = this.loginKerberos.bind(this);

		this.ping = this.ping.bind(this);
	}

	componentDidMount() {
        var _this = this, sortedArray = [], resultObject;
        
        this.setState({"secondsSinceLastActivity": 0});
        this.setTime();
        this.activityWatcher();
		this.props.actions.getTokenList(this.props.base.serviceUrl, this.props.login.accessToken)
		.then((response) => {

			if (response.contentType !== undefined && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
                this.props.actions.setErrorResponse(response.json)
				this.setState({showErrorPopup: true})
                return
            }

            function search(nameKey, myArray){
                for (var i = 0; i < myArray.length; i += 1) {
                    if (myArray[i].uri === nameKey) {
                        return myArray[i];
                    }
                }
            }

            // eslint-disable-next-line
            response.map((key) => {
                if (key.tokenContentDescriptorCached !== null && key.tokenContentDescriptorCached !== undefined && Object.keys(key.tokenContentDescriptorCached.activeKeys).length > 0) {
                    if ((key.tokenContentDescriptorCached.certificateInfos[key.tokenContentDescriptorCached.activeKeys.AGREEMENT] !== undefined && key.tokenContentDescriptorCached.certificateInfos[key.tokenContentDescriptorCached.activeKeys.AGREEMENT].extensions.isElectronicStamp === false) || (key.tokenContentDescriptorCached.certificateInfos[key.tokenContentDescriptorCached.activeKeys.SIGNATURE] !== undefined && key.tokenContentDescriptorCached.certificateInfos[key.tokenContentDescriptorCached.activeKeys.SIGNATURE].extensions.isElectronicStamp === false)) {
                        sortedArray.push(key)
                    }
                }
            })

            // eslint-disable-next-line
            response.map((key) => {
                if (key.tokenContentDescriptorCached !== null && key.tokenContentDescriptorCached !== undefined && Object.keys(key.tokenContentDescriptorCached.activeKeys).length > 0) {
                    if ((key.tokenContentDescriptorCached.certificateInfos[key.tokenContentDescriptorCached.activeKeys.AGREEMENT] !== undefined && key.tokenContentDescriptorCached.certificateInfos[key.tokenContentDescriptorCached.activeKeys.AGREEMENT].extensions.isElectronicStamp === true) || (key.tokenContentDescriptorCached.certificateInfos[key.tokenContentDescriptorCached.activeKeys.SIGNATURE] !== undefined && key.tokenContentDescriptorCached.certificateInfos[key.tokenContentDescriptorCached.activeKeys.SIGNATURE].extensions.isElectronicStamp === true)) {

                        resultObject = search(key.uri, sortedArray);
                        if (resultObject === undefined) {
                            sortedArray.push(key)
                        }
                    }
                }
            })

            // eslint-disable-next-line
            response.map((key) => {
                resultObject = search(key.uri, sortedArray);
                if (resultObject === undefined) {
                    sortedArray.push(key)
                }
            })

            // console.log(sortedArray)

            this.props.actions.setTokenList(sortedArray)

			if (sortedArray.length > 0) {
				// this.props.actions.setTokenEnquire(response[0]);
				this.props.actions.setSelectedToken(0)
                this.props.actions.clearTokenEnquire();

                var enquires = this.props.tokenList.tokenEnquiresList;
                var warnings = this.props.tokenList.warningsList
                enquires[sortedArray[0].uri] = sortedArray[0];

                if (sortedArray[0].status === "RESET_BY_SO") {
                    this.props.actions.setShowResetPopup(true);
                }

                if (sortedArray[0].tokenContentDescriptorCached !== null) {
                    
                    this.props.actions.setTokenEnquiresList(enquires);
                    this.props.actions.setTokenEnquire(sortedArray[0]);

                    window.setTimeout(function () {
                        if (sortedArray[0].tokenContentDescriptorCached.activeKeys.AGREEMENT !== null && sortedArray[0].tokenContentDescriptorCached.activeKeys.AGREEMENT !== undefined) {
                            if (_this.props.actions.getDays(sortedArray[0].tokenContentDescriptorCached.certificateInfos[sortedArray[0].tokenContentDescriptorCached.activeKeys.AGREEMENT].notAfter) < 15) {
                                warnings[sortedArray[0].uri] = true;
                            }
                        }

                        if (sortedArray[0].tokenContentDescriptorCached.activeKeys.SIGNATURE !== null && sortedArray[0].tokenContentDescriptorCached.activeKeys.SIGNATURE !== undefined) {
                            if (_this.props.actions.getDays(sortedArray[0].tokenContentDescriptorCached.certificateInfos[sortedArray[0].tokenContentDescriptorCached.activeKeys.SIGNATURE].notAfter) < 15) {
                                warnings[sortedArray[0].uri] = true;
                            }
                        }

                        _this.props.actions.setWarningsList(warnings)
                    }, 25)
                }
			}
		});
	}

    activityWatcher () {
        var _this = this;
        // var secondsSinceLastActivity = 0;

        var maxInactivity = (60 * this.props.base.timeToLogout);

        
        var refreshIntervalId = setInterval(function () {
            if (!_this.props.base.showSessionEndHandler &&  _this.props.login.isLoggedIn) {
                 _this.setState({"secondsSinceLastActivity": _this.state.secondsSinceLastActivity += 1});
                // console.log(secondsSinceLastActivity + ' seconds since the user was last active');

                if (_this.state.secondsSinceLastActivity > maxInactivity) {
                    // console.log('User has been inactive for more than ' + maxInactivity + ' seconds');
                    console.log("activityWatcher")
                    _this.props.actions.setShowSessionEndHandler(true);
                    clearInterval(refreshIntervalId);
                }
            }
        }, 1000);
        

        function activity() {
            _this.setState({"secondsSinceLastActivity": 0});
        }

        var activityEvents = [
            'mousedown', 'mousemove', 'keydown',
            'scroll', 'touchstart'
        ];

        activityEvents.forEach(function(eventName) {
            document.addEventListener(eventName, activity, true);
        });
    }

	componentWillUnmount () {
		this.props.actions.setShowSessionEndHandler(false);
	}

	showMessagePopup () {
    	this.setState({
          	showErrorPopup: !this.state.showErrorPopup
      	});
  	}

	setTimeValue () {
		this.setState({settedTimeValue: false})
		clearInterval(this.timer);
		this.timer = 0;
		this.setState({needPing: false})
	}

	setTime() {
		var _this = this;
		var timeToExpire = this.props.base.expires_in;
		this.setState({seconds: timeToExpire})
		this.setState({settedTimeValue: true})
		this.setState({needPing: true})

		window.setTimeout(function () {
			_this.ping();
		}, 60000)

		this.startTimer();
	}

	ping () {
		if (this.state.needPing) {
			window.setTimeout(function () {
				fetch("8.8.8.8", {
			      method: 'GET',
			      headers: {
			          'Content-Type': 'application/json'
			      },
			      dataType: "json"
			    })
			    .then((response) => {
			    	console.log(response)
			    })
			}, 60000)
		}
	}

	startTimer () {
		if (this.timer === 0) {
		  this.timer = setInterval(this.countDown, 1000);
		}
	}

	login2f () {
        var userData, _this = this;
        var containsRole = false;
        var error = false;

        var code = {
            "twoFactorCode": this.props.login.twoFactorCode
        }
        
        
        this.props.actions.login2f(this.props.base.authUrl, code, this.props.login.accessToken)
        .then((response) => {
            if (response.code !== undefined) {
                this.props.actions.clearTwoFactorEnabled();
                this.props.actions.clearTwoFactorCode();
                this.props.actions.setPasswordInput("");
                this.props.actions.setNeed2fConfirm(false);
                error = true;
                this.props.actions.setIsLoggedIn(false);
                alert(response.message);
                return
            }

            function parseJwt (token) {
                var base64Url = token.split('.')[1];
                var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));

                return JSON.parse(jsonPayload);
            };
            this.props.actions.setToken(response.access_token);
            this.props.actions.setNeed2fConfirm(true);
            userData = parseJwt(response.access_token);
            this.props.actions.setExpireTime(userData.exp)
        })
        .then(() => {
            if (!error) {
                this.props.actions.verifyToken(this.props.base.authUrl, this.props.login.accessToken)
                .then((response) => {
                    if (response.expires_in !== undefined) {
                        this.props.actions.setExpireInTime(response.expires_in)
                    }

                    userData.scope.forEach(function (role) {
                    	if (role === "UPDATE_2F_SETTINGS_GRANT") {
                            _this.props.actions.setUpdate2fSettingsGrant(true)
                        }

                        if (role === "CERTIFICATE_ISSUING_GRANT") {
                            _this.props.actions.setCertificateIssuingGrant(true)
                        }

                        if (role === "CHANGE_PASSWORD_GRANT") {
                            _this.props.actions.setChangePasswordGrant(true)
                        }

                        if (role === "VTMS_USER" || role === "VTMS_ADMINISTRATOR") {
                            containsRole = true; 
                        } else {
                            return;
                        }
                    });


                    if (containsRole) {
                        this.props.actions.getUserProfile(this.props.base.authUrl, this.props.login.accessToken)
                        .then((response) => {
                            _this.props.actions.setLoggedInUserData(response);
                            _this.props.actions.setAllUserFields(response);

                            _this.props.actions.getPublicKeyCurrentUtcDateTime(this.props.base.serviceUrl, this.props.login.accessToken)
                            .then((response) => {
                                var delta = new Date(response.currentUtcDateTime).getTime() - Date.now()
                                _this.props.actions.setTimeDelta(delta)
                            	_this.props.actions.setTwoFactorEnabled(false);
                				_this.props.actions.clearTwoFactorCode();
                                _this.props.actions.setPublicKeyCurrentUtcDateTime(response);
                                _this.props.actions.setIsLoggedIn(true);
                                _this.props.actions.setShowSessionEndHandler(false);
                                // _this.setTimeValue()
                                _this.activityWatcher();
                                // _this.setState({"secondsSinceLastActivity": 0});
                                _this.setTime();
                            })
                        })
                    } else {
                        alert(i18next.t("premissionDenied"));
                    }
                })
            }
        })
    }

    login () {
    	var userData, _this = this, user;
        var containsRole = false;
		var error = false;

        this.props.actions.setNeedReLogin(false)

        if (this.props.base.isKerberos) {
            this.loginKerberos()
            return;
        }

		var substring = "handyman";

        if (this.props.base.selectedAuthProvider.indexOf(substring) !== -1) {
            user = {
                "grant_type" : "password",
                "user" : this.props.login.login,
                "client_id" : this.props.base.clientId,
                "password" : this.props.login.password
            }
        } else {
            user = {
                "grant_type" : "password",
                "username" : this.props.login.login,
                "client_id" : this.props.base.clientId,
                "password" : this.props.login.password
            }
        }

        var data = {
            twoFactorCode: ""
        }

		clearInterval(this.timer);
		// this.setTimeValue();
		this.props.actions.login(this.props.base.selectedAuthProvider, user)
    	.then((response) => {
            if (response.code !== undefined) {
                error = true;
                alert(response.message);
                return
            }
            function parseJwt (token) {
                var base64Url = token.split('.')[1];
                var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));

                return JSON.parse(jsonPayload);
            };
            this.props.actions.setToken(response.access_token);
            userData = parseJwt(response.access_token);

            if (userData.twof) {
                this.props.actions.init2f(this.props.base.authUrl, data, this.props.login.accessToken)
                .then((response) => {
                    console.log(response);
                })
                this.props.actions.setTwoFactorEnabled(true);
                return;
            } else {
                if (!userData.twof) {
                    this.props.actions.login2f(this.props.base.authUrl, user, this.props.login.accessToken)
                    .then((response) => {
                        if (response.code !== undefined) {
                            this.props.actions.clearTwoFactorEnabled();
                            this.props.actions.clearTwoFactorCode();
                            this.props.actions.setPasswordInput("");
                            error = true;
                            alert(response.message);
                            return
                        }

                        function parseJwt (token) {
                            var base64Url = token.split('.')[1];
                            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                            var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                            }).join(''));

                            return JSON.parse(jsonPayload);
                        };
                        this.props.actions.setToken(response.access_token);
                        userData = parseJwt(response.access_token);
                        this.props.actions.setExpireTime(userData.exp)
                    })
                    .then(() => {
                        if (!error) {
                            this.props.actions.verifyToken(this.props.base.authUrl, this.props.login.accessToken)
                            .then((response) => {
                                if (response.expires_in !== undefined) {
                                    this.props.actions.setExpireInTime(response.expires_in)
                                }
                                
                                userData.scope.forEach(function (role) {
                                	if (role === "UPDATE_2F_SETTINGS_GRANT") {
			                            _this.props.actions.setUpdate2fSettingsGrant(true)
			                        }

			                        if (role === "CERTIFICATE_ISSUING_GRANT") {
			                            _this.props.actions.setCertificateIssuingGrant(true)
			                        }

                                    if (role === "CHANGE_PASSWORD_GRANT") {
                                        _this.props.actions.setChangePasswordGrant(true)
                                    }

                                    if (role === "VTMS_USER" || role === "VTMS_ADMINISTRATOR") {
                                        containsRole = true; 
                                    } else {
                                        return;
                                    }
                                });


                                if (containsRole) {
                                    this.props.actions.getUserProfile(this.props.base.authUrl, this.props.login.accessToken)
                                    .then((response) => {
                                        _this.props.actions.setLoggedInUserData(response);
                                        _this.props.actions.setAllUserFields(response);

                                        _this.props.actions.getPublicKeyCurrentUtcDateTime(this.props.base.serviceUrl, this.props.login.accessToken)
                                        .then((response) => {
                                            var delta = new Date(response.currentUtcDateTime).getTime() - Date.now()
                                            _this.props.actions.setTimeDelta(delta)
                                            _this.props.actions.setPublicKeyCurrentUtcDateTime(response);
                                            _this.props.actions.setIsLoggedIn(true);
                                            _this.props.actions.setShowSessionEndHandler(false);
                                            _this.activityWatcher();
                                            _this.setState({"secondsSinceLastActivity": 0});
                                            _this.setTime();
                                            _this.props.actions.setShowSessionEndHandler(false);
                                            _this.props.actions.setNeedReLogin(false);
                                        })
                                    })
                                } else {
                                    alert(i18next.t("premissionDenied"));
                                }
                            })
                        }
                    })
                }
            }
    	})
    }

    loginKerberos () {
        var userData, _this = this, user;
        var containsRole = false;
        var error = false;

        user = {
            "client_id" : this.props.base.clientId
        }

        var data = {
            twoFactorCode: ""
        }

        if (this.props.base.isKerberos) {
            let xhr = new XMLHttpRequest();
            xhr.open('GET', this.props.base.selectedAuthProvider);
            xhr.withCredentials = true;

            xhr.send();

            xhr.onerror = function() {
                alert(i18next.t("kerberosError"))
            }

            xhr.onload = function() {
                let responseObj = JSON.parse(xhr.response);
                console.log(responseObj);

                if (responseObj["access_token"] !== undefined) {
                    function parseJwt (token) {
                        var base64Url = token.split('.')[1];
                        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                        }).join(''));

                        return JSON.parse(jsonPayload);
                    };

                    _this.props.actions.setToken(responseObj.access_token);
                    userData = parseJwt(responseObj.access_token);
                    console.log(userData)

                    if (userData.twof) {
                        _this.props.actions.init2f(_this.props.base.authUrl, data, _this.props.login.accessToken)
                        .then((response) => {
                            console.log(response);
                        })
                        _this.props.actions.setTwoFactorEnabled(true);
                        _this.props.actions.setIs2fEnabled(true);
                        return;
                    } else {
                        if (!userData.twof) {
                            _this.props.actions.login2f(_this.props.base.authUrl, user, _this.props.login.accessToken)
                            .then((response) => {
                                if (response.code !== undefined) {
                                    _this.props.actions.clearTwoFactorEnabled();
                                    _this.props.actions.clearTwoFactorCode();
                                    _this.props.actions.setPasswordInput("");
                                    error = true;
                                    alert(response.message);
                                    return
                                }

                                function parseJwt (token) {
                                    var base64Url = token.split('.')[1];
                                    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                                    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                                        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                                    }).join(''));

                                    return JSON.parse(jsonPayload);
                                };
                                _this.props.actions.setToken(response.access_token);
                                userData = parseJwt(response.access_token);
                                _this.props.actions.setExpireTime(userData.exp)
                            })
                            .then(() => {
                                if (!error) {
                                    _this.props.actions.verifyToken(_this.props.base.authUrl, _this.props.login.accessToken)
                                    .then((response) => {
                                        if (response.expires_in !== undefined) {
                                            _this.props.actions.setExpireInTime(response.expires_in)
                                        }
                                        
                                        userData.scope.forEach(function (role) {
                                            if (role === "UPDATE_2F_SETTINGS_GRANT") {
                                                _this.props.actions.setUpdate2fSettingsGrant(true)
                                            }

                                            if (role === "CERTIFICATE_ISSUING_GRANT") {
                                                _this.props.actions.setCertificateIssuingGrant(true)
                                            }

                                            if (role === "CHANGE_PASSWORD_GRANT") {
                                                _this.props.actions.setChangePasswordGrant(true)
                                            }

                                            if (role === "VTMS_USER" || role === "VTMS_ADMINISTRATOR") {
                                                containsRole = true; 
                                            } else {
                                                return;
                                            }
                                        });


                                        if (containsRole) {
                                            _this.props.actions.getUserProfile(_this.props.base.authUrl, _this.props.login.accessToken)
                                            .then((response) => {
                                                _this.props.actions.setLoggedInUserData(response);
                                                _this.props.actions.setAllUserFields(response);

                                                _this.props.actions.getPublicKeyCurrentUtcDateTime(_this.props.base.serviceUrl, _this.props.login.accessToken)
                                                .then((response) => {
                                                    var delta = new Date(response.currentUtcDateTime).getTime() - Date.now()
                                                    _this.props.actions.setTimeDelta(delta)
                                                    _this.props.actions.setPublicKeyCurrentUtcDateTime(response);
                                                    _this.props.actions.setIsLoggedIn(true);
                                                    _this.props.actions.setShowSessionEndHandler(false);
                                                    _this.props.actions.setNeedReLogin(false);
                                                })
                                            })
                                        } else {
                                            alert(i18next.t("premissionDenied"));
                                        }
                                    })
                                }
                            })
                        }
                    }
                }
                
            }
        }
    }

	countDown() {
        if (this.props.login.isLoggedIn === false) {
            return;
        }

   		let seconds = this.state.seconds - 1;
		this.setState({
		  seconds: seconds,
		});

		cookies.set('timestamp', Date.now());

        var currentTime = new Date().getTime() / 1000;

		if (((seconds < 0) && this.props.base.showSessionEndHandler === false) || ((currentTime > this.props.base.expireTime) && this.props.base.showSessionEndHandler === false)) {
			clearInterval(this.timer);
			this.setTimeValue();
            console.log("countDown BodyContainer")        
            this.props.actions.setShowSessionEndHandler(true);
		}
	}

	render() {
		return (<div>
			<DrawerContainer />
			<Body />
			{
				this.state.showErrorPopup
				? <ErrorMessagePopup closeMessagePopup={this.showMessagePopup.bind(this)} data={this.props.userReducer.errorResponse} />
				: null
			}
			{
				this.props.login.twoFactorEnabled
				? <TwoFactorPopup loginUser2f={this.login2f} forwardRef={this.loginRef} />
				: null
			}
			{
				this.props.base.needReLogin
				? this.login()
				: null
			}
			
		</div>);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer,
        config: state.ConfigReducer,
        tokenList: state.tokenListReducer,
        userReducer: state.userReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    getTokenList,
		setTokenList, setSelectedToken,
		getTokenEnquire, setTokenEnquire,
		setToken, setExpireInTime, setIsLoggedIn,
		login, verifyToken, getUserProfile, getPublicKeyCurrentUtcDateTime,
		setLoggedInUserData, setAllUserFields,
		setPublicKeyCurrentUtcDateTime, setErrorResponse,
		init2f, login2f, setTwoFactorEnabled,
		clearTwoFactorEnabled, clearTwoFactorCode,
		setPasswordInput, setUpdate2fSettingsGrant,
		setCertificateIssuingGrant, setNeed2fConfirm,
		setExpireTime, setNeedReLogin, setShowSessionEndHandler,
        clearTokenEnquire, setTokenEnquiresList, setTimeDelta,
        setChangePasswordGrant, setShowResetPopup, setWarningsList,
        getDays, setNeedToLogout
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BodyContainer);