import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as base32 from 'hi-base32';
import QRCode from 'qrcode.react';

import { update2fUserInfo, getUserProfile } from '../actions/api';
import { setTwoFactorChannelId, setTwoFactorSettings, setActionResultData, setShowActionResult, setLoggedInUserData, setAllUserFields, setShowLogoutHandler } from '../actions/localStates';

import i18next from 'i18next'

class TwoFactorContainerPopup extends Component {

  constructor(props) {
      super(props);
      this.state = {
          twoFactorChannelId : this.props.user.twoFactorChannelId || "",
          twoFactorSettings: this.props.user.twoFactorSettings !== null ? this.props.user.twoFactorSettings.secretKey : "",
          imgSrc: null
      }

      this.onChannelChanged = this.onChannelChanged.bind(this);
      this.handleConfirm = this.handleConfirm.bind(this);

  }

  componentDidMount() {
      if (this.props.user.twoFactorChannelId !== null && this.props.user.twoFactorSettings !== null) {
          if (this.props.user.twoFactorChannelId === "googleAuthenticator2FChannel") {
              var src = "otpauth://totp/" + i18next.t("headerTitle") + ":" + this.props.login.userData.userName + "?algorithm%3DSHA1%3Fdigits=6%3Fissuer%3D" + i18next.t("headerTitle") + "&period=30&secret=" + this.props.user.twoFactorSettings.secretKey
              this.setState({imgSrc: src})
              console.log(src)
              console.log(this.props.user.twoFactorSettings.secretKey)
          }
      }
  }

  handleConfirm () {
      var _this = this;
      var secret = {
          secretKey: this.state.twoFactorSettings
      }
      var data = {
            "twoFactorChannelId": this.state.twoFactorChannelId,
            "twoFactorSettings": secret
      }

      this.props.actions.setTwoFactorSettings(secret)
      this.props.actions.setTwoFactorChannelId(this.state.twoFactorChannelId)
      // console.log(data)
      this.props.actions.update2fUserInfo(this.props.base.authUrl, data, this.props.login.accessToken)
      .then((response) => {
          if (response.code !== undefined) {
              alert(response.message);
              this.props.closeTwoFactorContainerPopup()
              return
          } else {
              this.props.actions.getUserProfile(this.props.base.authUrl, this.props.login.accessToken)
              .then((response) => {
                  _this.props.actions.setLoggedInUserData(response);
                  _this.props.actions.setAllUserFields(response);

                  if (_this.state.twoFactorChannelId.length === 0) {
                      _this.props.actions.setTwoFactorSettings(null)
                      _this.props.actions.setTwoFactorChannelId(null)
                  }

                  _this.props.actions.setActionResultData({status: "success", action: "twoFactorParamsChanged", message: i18next.t("success")});
                  _this.props.actions.setShowActionResult(true);
                  _this.props.actions.setShowLogoutHandler(true);
                  _this.props.closeTwoFactorContainerPopup()
              })
          }
      })
  }

  onChannelChanged (e) {
    this.setState({imgSrc: null})

    function makeid(length) {
        var result           = [];
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
       }
       return result.join('');
    }

    var secret = {
        secretKey: this.state.twoFactorSettings
    }

    if (e.target.value === "googleAuthenticator2FChannel") {

        var key = base32.encode(makeid(20));
        this.setState({twoFactorSettings: key})
        this.setState({twoFactorChannelId: e.target.value})
        
        this.setState({imgSrc: "otpauth://totp/" + i18next.t("headerTitle") + ":" + this.props.login.userData.userName + "?algorithm%3DSHA1%3Fdigits=6%3Fissuer%3D" + i18next.t("headerTitle") + "&period=30&secret=" + key})
        console.log(key);
    } else {
        this.setState({twoFactorSettings: secret})
        this.setState({twoFactorChannelId: e.target.value})
    }

    if (e.target.value === "disabled") {
        
        this.setState({twoFactorSettings: secret})
        this.setState({twoFactorChannelId: ""})
    }

    console.log(e.target.value)
    
  }

  render() {
      return (
          <div className='popup'>
              <div className="popup_inner_2f">
                  <div className="col-12">
                      <h4 style={{paddingLeft: "15px", paddingTop: "30px"}}>{i18next.t("titleFor2F")}</h4>
                      <div>
                          {
                            this.props.user.twoFactorAvailabelChannels === null || this.props.user.twoFactorAvailabelChannels.length === 0
                            ? <label style={{paddingLeft: "15px"}}>notAvailable</label>
                            : <div style={{paddingLeft: "15px", paddingRight: "15px"}}>
                                <select style={{"paddingRight": "0px"}} defaultValue={(this.state.twoFactorChannelId !== null && this.state.twoFactorChannelId.length > 0) ? this.state.twoFactorChannelId : 0} className="form-control" onChange={this.onChannelChanged}>
                                  {
                                    this.props.user.twoFactorAvailabelChannels.map((channel) =>
                                      <option key={channel.id} value={channel.id} className="row" style={{marginTop: "5px"}}>{channel.title}</option>)
                                  }
                                </select>
                              </div>
                          }
                      </div>
                      <div>
                          {
                            this.state.imgSrc
                            ? <div className="col-12">
                                <label style={{marginBottom: "0px", marginTop: "10px"}}>{i18next.t("qrLabel")}</label>
                                <label style={{marginBottom: "0px", fontWeight: "600", wordWrap: "anywhere"}}>{this.state.twoFactorSettings}</label>
                              </div>
                            : null
                          }
                      </div>
                      <div>
                          {
                            this.state.imgSrc
                            ? <div className="col-12" style={{textAlign: "center"}}>
                                <QRCode value={this.state.imgSrc} level={"L"} style={{width: "200px", height: "200px"}} />
                                
                              </div>
                            : null
                          }
                      </div>
                      <div style={{float: "right", marginRight: "15px", paddingTop: "10px"}}>
                          <button className="btn btn-info" style={{marginLeft: "5px", marginBottom: "30px"}} onClick={this.handleConfirm}>{i18next.t("okLabel")}</button>
                          <button className="btn btn-outline-info" style={{marginLeft: "5px", marginBottom: "30px"}} onClick={this.props.closeTwoFactorContainerPopup}>{i18next.t("cancelLabel")}</button>
                      </div>
                  </div>
              </div>
          </div>
      );
    }
}

function mapStateToProps(state) {
    return {
        base: state.base,
        selectedClient: state.clientReducer,
        user: state.userReducer,
        login: state.loginReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
      setTwoFactorChannelId,
      setTwoFactorSettings,
      update2fUserInfo,
      setActionResultData,
      setShowActionResult, getUserProfile,
      setLoggedInUserData, setAllUserFields,
      setShowLogoutHandler
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorContainerPopup);