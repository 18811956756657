import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

class ErrorMessagePopup extends Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    	showDetails: false,
	    	techMessageCutted: "",
	    	errorMessage: "",
	    	count: 0
	    }

	    this.close = this.close.bind(this);
	}

	showDetails () {
		this.setState({showDetails: !this.state.showDetails})
	}

	componentDidMount() {
		if (this.props.data.techMessage.includes("CIHSMAGENTBASE-2-")) {
			var after = this.props.data.techMessage.substring(this.props.data.techMessage.indexOf('CIHSMAGENTBASE-2-') + 17, this.props.data.techMessage.indexOf('CIHSMAGENTBASE-2-') + 18);
			this.setState({errorMessage: i18next.t("CIHSMAGENTBASE-2-")})
			this.setState({count: after})
			return;
		}

		if (this.props.data.techMessage.includes("CIHSMAGENTBASE-8")) {
			this.setState({errorMessage: i18next.t("pinLocked")})
			return;
		}

		if (this.props.data.techMessage.includes("CIHSMAGENTBASE-26")) {
			this.setState({errorMessage: i18next.t("CIHSMAGENTBASE-26")})
			return;
		}

		if (this.props.data.techMessage.includes("CIHSMAGENTBASE-68")) {
			this.setState({errorMessage: i18next.t("CIHSMAGENTBASE-68")})
			return;
		}

		if (this.props.data.techMessage.includes("CIHSMAGENTBASE-67")) {
			this.setState({errorMessage: i18next.t("CIHSMAGENTBASE-67")})
			return;
		}

		var splits, text, resText;
		this.setState({"techMessageCutted": ""})

		if (this.props.data.techMessage.includes('"techMessage":"')) {
			splits = this.props.data.techMessage.split('"techMessage":"')
			text = splits[splits.length - 1]
			resText = text.substring(0, text.length - splits.length)
		} else {
			resText = this.props.data.techMessage
		}

		this.setState({"techMessageCutted": resText})
	}

	close () {
		this.props.closeMessagePopup()
	}

	render() {
		return (
			<div className='message-popup'>
				<div className="message-popup-inner">
                    <div className="col-12" style={{fontWeight: "bold", paddingLeft: "30px", paddingTop: "30px", paddingRight: "30px"}}>
                    	{
                    		this.state.errorMessage.length > 0
                    		? this.state.errorMessage + (this.state.count > 0 ? ((this.state.count === 1 ? i18next.t("wasOne") : i18next.t("was")) + this.state.count + i18next.t("wrongPinAttempts")) : "")
                    		: this.props.data.message
                    	}
                    </div>
                    {
                    	this.state.errorMessage.length === 0 && this.props.data.techMessage !== undefined && this.props.data.techMessage.length > 0
                    	? <div>
                    		<div className="col-12" style={{marginBottom: "5px", marginTop: "10px", paddingLeft: "30px"}}>
		                		<label onClick={this.showDetails.bind(this)} style={{"cursor": "pointer"}}>{i18next.t("detailInfo")}&nbsp;
		                    	{
									this.state.showDetails ? <i className="fa fa-chevron-up" aria-hidden="true" style={{"cursor": "pointer"}}></i>
										: <i className="fa fa-chevron-down" aria-hidden="true" style={{"cursor": "pointer"}}></i>
								}
		                		</label>
		                	</div>
		                	<div className="col-12" style={{marginTop: "5px", paddingLeft: "30px", paddingRight: "30px"}}>
		                		{
									this.state.showDetails
									? <div className="card card-height" style={{paddingTop: "5px", paddingBottom: "5px", paddingLeft: "10px", paddingRight: "10px"}}>{this.state.techMessageCutted}</div>
									: null
								}
		                    </div>
                    	</div>
                    	: null
                    }
                	
                    <div className="col-12" style={{float: "right"}}>
                    	<button className="btn btn-info" style={{marginTop: "10px", marginBottom: "30px", float: "right", marginRight: "15px"}} onClick={this.close}>{i18next.t("closeLabel")}</button>
                    </div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorMessagePopup);