import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

import { setShowResetPopup, setPukForReset, setNewPinForReset, setNewPukForReset } from '../actions/localStates'

class ResetPopup extends Component {

  constructor(props) {
      super(props);
      this.state = {
          puk: "",
          newPin: "",
          newPuk: "",
          confirmPin: "",
          confirmPuk: "",

          showPuk: false,
          showNewPin: false,
          showNewPuk: false,

          confirmedPinNotEqual: false,
          confirmedPukNotEqual: false,

          pukIsEmpty: false,
          errorMessagePuk: "",

          newPinIsEmpty: false,
          errorMessageNewPin: "",

          newPukIsEmpty: false,
          errorMessageNewPuk: "",

          errorMessageForConfirmPin: "",
          errorMessageForConfirmPuk: ""

      }

      this.handlePukInput = this.handlePukInput.bind(this);

      this.handleNewPinInput = this.handleNewPinInput.bind(this);

      this.handleNewPukInput = this.handleNewPukInput.bind(this);

      this.setPuk = this.setPuk.bind(this);
      this.closePinPopup = this.closePinPopup.bind(this);

      this.handlePinKeyUp = this.handlePinKeyUp.bind(this, 'pinInput');

      this.handleConfirmPinInput = this.handleConfirmPinInput.bind(this);
      this.handleConfirmPukInput = this.handleConfirmPukInput.bind(this);
  }

  componentDidMount() {
      this.props.actions.setPukForReset("")
      this.props.actions.setNewPinForReset("")
      this.props.actions.setNewPukForReset("")
      document.addEventListener('keyup', this.handlePinKeyUp);
  }

  componentWillUnmount() {
      document.removeEventListener('keyup', this.handlePinKeyUp);
  }

  handlePinKeyUp(refName, e) {
      if (e.keyCode === 13 && refName === "pinInput") {
          document.getElementById("resetToken").click();
          document.activeElement.blur();
      }
  }

  handlePukInput (e) {
    // var value = e.target.value
    this.setState({puk: e.target.value})
    this.props.actions.setPukForReset(e.target.value)
    e.target.value.length > 0 && e.target.value.length < 4
      ? this.setState({errorMessagePuk: "pukMinLength"})
      : e.target.value.length === 0
      ? this.setState({pukIsEmpty: true})
      : this.setState({errorMessagePuk: ""})
  }

  handleNewPinInput (e) {
    var _this = this;
    var value = e.target.value
    this.setState({newPin: e.target.value})
    this.props.actions.setNewPinForReset(e.target.value)
    e.target.value.length > 0 && e.target.value.length < 8
      ? this.setState({errorMessageNewPin: "pinMinLength"}, () => {
        if (_this.state.confirmPin.length > 0) {
          _this.state.confirmPin === value
          ? _this.setState({confirmedPinNotEqual: false}, () => {
            _this.setState({errorMessageForConfirmPin: ""})
            })
          : _this.setState({confirmedPinNotEqual: true}, () => {
            _this.setState({errorMessageForConfirmPin: "pinNotEqual"})
            })
        }
      })
      : this.setState({newPinIsEmpty: false}, () => {
        this.setState({errorMessageNewPin: ""}, () => {
          if (_this.state.confirmPin.length > 0) {
            _this.state.confirmPin === value
            ? _this.setState({confirmedPinNotEqual: false}, () => {
              _this.setState({errorMessageForConfirmPin: ""})
              })
            : _this.setState({confirmedPinNotEqual: true}, () => {
              _this.setState({errorMessageForConfirmPin: "pinNotEqual"})
              })
          }
      })
    })
  }

  handleConfirmPinInput (e) {
    this.setState({confirmPin: e.target.value})
    this.state.newPin === e.target.value
    ? this.setState({confirmedPinNotEqual: false}, () => {
      this.setState({errorMessageForConfirmPin: ""})
      })
    : this.setState({confirmedPinNotEqual: true}, () => {
      this.setState({errorMessageForConfirmPin: "pinNotEqual"})
      })
  }

  handleNewPukInput (e) {
    var _this = this;
    var value = e.target.value
    this.setState({newPuk: e.target.value})
    this.props.actions.setNewPukForReset(e.target.value)
    e.target.value.length > 0 && e.target.value.length < 4
      ? this.setState({errorMessageNewPuk: "pukMinLength"}, () => {
        if (_this.state.confirmPuk.length > 0) {
          _this.state.confirmPuk === value
          ? _this.setState({confirmedPukNotEqual: false}, () => {
            _this.setState({errorMessageForConfirmPuk: ""})
            })
          : _this.setState({confirmedPukNotEqual: true}, () => {
            _this.setState({errorMessageForConfirmPuk: "pukNotEqual"})
            })
        }
      })
      : this.setState({newPukIsEmpty: false}, () => {
        this.setState({errorMessageNewPuk: ""}, () => {
          if (_this.state.confirmPuk.length > 0) {
            _this.state.confirmPuk === value
            ? _this.setState({confirmedPukNotEqual: false}, () => {
              _this.setState({errorMessageForConfirmPuk: ""})
              })
            : _this.setState({confirmedPukNotEqual: true}, () => {
              _this.setState({errorMessageForConfirmPuk: "pukNotEqual"})
              })
          }
      })
    })
  }

  handleConfirmPukInput (e) {
    this.setState({confirmPuk: e.target.value})
    this.state.newPuk === e.target.value
    ? this.setState({confirmedPukNotEqual: false}, () => {
      this.setState({errorMessageForConfirmPuk: ""})
      })
    : this.setState({confirmedPukNotEqual: true}, () => {
      this.setState({errorMessageForConfirmPuk: "pukNotEqual"})
      })
  }

  setPuk () {
    // this.props.actions.setPukForReset(this.state.puk)
    // this.props.actions.setNewPinForReset(this.state.newPin)
    // this.props.actions.setNewPukForReset(this.state.newPuk)

    this.props.closeResetPopup();
  }

  closePinPopup () {
    this.props.actions.setShowResetPopup(false);
  }

  hoverPuk() {
        this.setState({showPuk: true})
  }

  outPuk() {
      this.setState({showPuk: false})
  }

  hoverNewPin() {
        this.setState({showNewPin: true})
  }

  outNewPin() {
      this.setState({showNewPin: false})
  }

  hoverNewPuk() {
        this.setState({showNewPuk: true})
  }

  outNewPuk() {
      this.setState({showNewPuk: false})
  }

  hoverConfirmPin() {
        this.setState({showConfirmPin: true})
  }

  outConfirmPin() {
      this.setState({showConfirmPin: false})
  }

  hoverConfirmPuk() {
        this.setState({showConfirmPuk: true})
  }

  outConfirmPuk() {
      this.setState({showConfirmPuk: false})
  }

  render() {
    let isEnabled = (this.state.puk.length > 3 && this.state.newPin.length > 7 && this.state.newPuk.length > 3 && this.state.confirmPin.length > 7 && this.state.confirmPuk.length > 3) && (!this.state.confirmedPinNotEqual && !this.state.confirmedPukNotEqual)
    ? true
    : false

      return (
          <div className='popup'>
              <div className={"popup_inner_user_pin"}>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <h4 style={{paddingLeft: "15px", paddingTop: "30px"}}>{i18next.t("resetTokenLabel")}</h4>
                      {
                        this.props.tokenList.list[this.props.tokenList.selectedToken].status === "RESET_BY_SO"
                        ? <label style={{paddingLeft: "15px", paddingRight: "15px"}}>{i18next.t("resetTokenBySo")}</label>
                        : null
                      }
                      <div>
                          <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("PUK")}</label>
                          <div className="col-12 input-group">
                              <input className={(this.state.errorMessagePuk.length > 0) ? "form-control is-invalid" : "form-control"} value={this.state.puk} type={this.state.showPuk ? "input" : "password"} onChange={this.handlePukInput} />
                              <div className="input-group-append">
                                  <div className="input-group-text" onMouseOver={this.hoverPuk.bind(this)} onMouseOut={this.outPuk.bind(this)}><i className="fa fa-eye"></i></div>
                              </div>
                          </div>
                          {
                              this.state.errorMessagePuk.length > 0
                              ? <span style={{color: "red", paddingLeft: "15px"}}>{i18next.t(this.state.errorMessagePuk)}</span>
                              : null
                          }
                      </div>
                      <div>
                          <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("newPinLabel")}</label>
                          <div className="col-12 input-group">
                              <input className={(this.state.errorMessageNewPin.length > 0) ? "form-control is-invalid" : "form-control"} value={this.state.newPin} type={this.state.showNewPin ? "input" : "password"} onChange={this.handleNewPinInput} />
                              <div className="input-group-append">
                                  <div className="input-group-text" onMouseOver={this.hoverNewPin.bind(this)} onMouseOut={this.outNewPin.bind(this)}><i className="fa fa-eye"></i></div>
                              </div>
                          </div>
                          {
                              this.state.errorMessageNewPin.length > 0
                              ? <span style={{color: "red", paddingLeft: "15px"}}>{i18next.t(this.state.errorMessageNewPin)}</span>
                              : null
                          }
                      </div>
                      <div>
                          <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("confirmPin")}</label>
                          <div className="col-12 input-group">
                              <input className={this.state.confirmedPinNotEqual ? "form-control is-invalid" : "form-control"} value={this.state.confirmPin} type={this.state.showConfirmPin ? "input" : "password"} onChange={this.handleConfirmPinInput} />
                              <div className="input-group-append">
                                  <div className="input-group-text" onMouseOver={this.hoverConfirmPin.bind(this)} onMouseOut={this.outConfirmPin.bind(this)}><i className="fa fa-eye"></i></div>
                              </div>
                          </div>
                          {
                              this.state.errorMessageForConfirmPin.length > 0
                              ? <span style={{color: "red", paddingLeft: "15px"}}>{i18next.t(this.state.errorMessageForConfirmPin)}</span>
                              : null
                          }
                      </div>
                      <div>
                          <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("newPukLabel")}</label>
                          <div className="col-12 input-group">
                              <input className={this.state.errorMessageNewPuk ? "form-control is-invalid" : "form-control"} value={this.state.newPuk} type={this.state.showNewPuk ? "input" : "password"} onChange={this.handleNewPukInput} />
                              <div className="input-group-append">
                                  <div className="input-group-text" onMouseOver={this.hoverNewPuk.bind(this)} onMouseOut={this.outNewPuk.bind(this)}><i className="fa fa-eye"></i></div>
                              </div>
                          </div>
                          {
                              this.state.errorMessageNewPuk.length > 0
                              ? <span style={{color: "red", paddingLeft: "15px"}}>{i18next.t(this.state.errorMessageNewPuk)}</span>
                              : null
                          }
                      </div>
                      <div>
                          <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("confirmPuk")}</label>
                          <div className="col-12 input-group">
                              <input className={this.state.confirmedPukNotEqual ? "form-control is-invalid" : "form-control"} value={this.state.confirmPuk} type={this.state.showConfirmPuk ? "input" : "password"} onChange={this.handleConfirmPukInput} />
                              <div className="input-group-append">
                                  <div className="input-group-text" onMouseOver={this.hoverConfirmPuk.bind(this)} onMouseOut={this.outConfirmPuk.bind(this)}><i className="fa fa-eye"></i></div>
                              </div>
                          </div>
                          {
                              this.state.errorMessageForConfirmPuk.length > 0
                              ? <span style={{color: "red", paddingLeft: "15px"}}>{i18next.t(this.state.errorMessageForConfirmPuk)}</span>
                              : null
                          }
                      </div>
                      <div style={{marginRight: "15px", paddingTop: "10px"}}>
                          <button className="btn btn-outline-info" style={{float: "right",marginLeft: "5px", marginBottom: "30px"}} onClick={this.closePinPopup}>{i18next.t("cancelLabel")}</button>
                          <button className="btn btn-info" id="resetToken" style={{float: "right", marginLeft: "5px", marginBottom: "30px"}} disabled={!isEnabled} onClick={this.setPuk}>{i18next.t("resetToken")}</button>
                      </div>
                  </div>
              </div>
          </div>
      );
    }
}

function mapStateToProps(state) {
    return {
        base: state.base,
        selectedClient: state.clientReducer,
        login: state.loginReducer,
        userReducer: state.userReducer,
        tokenList: state.tokenListReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
      setShowResetPopup, setPukForReset,
      setNewPinForReset, setNewPukForReset
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPopup);