import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

import { removeCertificate, removeCertificateRequest, removePrivateKey, removeCertificate2f, removeAllByID, removeAllByID2f } from "../actions/api"
import { setActionResultData, setShowActionResult, setErrorResponse, setNeedRefresh, setNeed2fConfirm } from "../actions/localStates"
import { formatDate, cipher } from '../actions/helpers'

import saveAs from 'file-saver';

import CertificateInfoPopup from './CertificateInfoPopup'
import ErrorMessagePopup from './ErrorMessagePopup'
import ConfirmPopup from './ConfirmPopup'
import TwoFactorConfirmPopup from './TwoFactorConfirmPopup'

class WaitingKeysView extends Component {
	constructor() {
		super();
		this.state = {
			showActions: [],
			dropdownsSetted: false,
			showCertificateInfo: false,
			showPopup: false,
			info: null,
			showConfirmPopup: false,
			activeHandler: null,
			actionInfo: "",
			active2fWaitingKeysHandler: "",
			confirm2fTitle: "",
	    	numberOfPinInput: 0,
	    	needToReEnterPin: false,
	    	isActiveView: false,
	    	id: ""
		};

		this.handleShowActions = this.handleShowActions.bind(this);
	    this.handleCloseActions = this.handleCloseActions.bind(this);
	}

	handleShowActions (event) {
		if (this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri] === undefined) {
			document.activeElement.blur();
			return;
		}
		event.preventDefault();
		this.setState({
				showActions: !this.state.showActions
			}, () => {
			document.addEventListener('click', this.handleCloseActions);
		});
	}

	handleCloseActions (event) { 
		this.setState({ showActions: false }, () => {
			document.removeEventListener('click', this.handleCloseActions);
		});
	}

	showMessagePopup () {
		if (this.state.showPopup === true && this.state.needToReEnterPin === true) {
			if (this.state.numberOfPinInput > 0) {
				this.props.actions.setNeed2fConfirm(true);
			} else {
				this.setState({needToReEnterPin: false});
				this.setState({numberOfPinInput: 0});
			}
		}
    	this.setState({
          	showPopup: !this.state.showPopup
      	});
  	}

	handleDownloadCertificate (id) {
		const byteCharacters = atob(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificates[id]);
		const byteNumbers = new Array(byteCharacters.length);

		for (let i = 0; i < byteCharacters.length; i++) {
		  byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);

		const blob = new Blob([byteArray], {type: "application/octet-stream"});
		var fileName = id + ".cer";
		saveAs(blob, fileName);
	}

	handleDownloadCertificateRequest (id) {
		const byteCharacters = atob(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequests[id]);
		const byteNumbers = new Array(byteCharacters.length);

		for (let i = 0; i < byteCharacters.length; i++) {
		  byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);

		const blob = new Blob([byteArray], {type: "application/octet-stream"});
		var fileName = id + ".p10";
		saveAs(blob, fileName);
	}

	handleDeleteCertificate (id) {
		var _this = this, currentTime = new Date().getTime() / 1000;
		
		this.setState({
	        showConfirmPopup: false
	    });

		if (currentTime > this.props.base.expireTime) {
			this.props.actions.setShowSessionEndHandler(true);
			return;
		};

		if (this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri] !== undefined && this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri].length > 0) {
			
			this.setState({isActiveView: true})

			var jsonObject = {
				pin: this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri],
				keyIdentifier: id
			};

			var cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

			var data = {
				"ciHsmTokenUri": this.props.tokenList.list[this.props.tokenList.selectedToken].uri,
				"encryptedData": cipherResult.encryptedData,
				"secretKey": cipherResult.secretKey
			}

			if (this.props.login.is2fEnabled) {
				this.props.actions.removeCertificate2f(this.props.base.serviceUrl, this.props.login.accessToken, data)
				.then((response) => {
					if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
						this.setState({confirm2fTitle: "deleteCertificate2fConfirm"})
						this.setState({active2fWaitingKeysHandler: this.handleDeleteCertificate2f.bind(this)})
						this.setState({id: id});
						this.props.actions.setNeed2fConfirm(true);
					} else {
						this.props.actions.setErrorResponse(response.json)
						this.setState({showPopup: true})
					}

					return;
				});
				
				return;
			}

			this.props.actions.removeCertificate(this.props.base.serviceUrl, this.props.login.accessToken, data)
			.then((response) => {
				if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {					
					_this.props.actions.setActionResultData({status: "success", action: "deleteCertificate", message: i18next.t("success")})
                	_this.props.actions.setShowActionResult(true)
                	_this.props.actions.setNeedRefresh(this.props.tokenList.list[this.props.tokenList.selectedToken].uri)
				} else {
					_this.props.actions.setErrorResponse(response.json)
					_this.setState({showPopup: true})
				}
			});
		}
	}

	handleDeleteCertificate2f (id) {
		var _this = this;

		this.setState({
	        showConfirmPopup: false
	    });

	    this.props.actions.setNeed2fConfirm(false);

	    if (id === true) {
			this.setState({isActiveView: false})
			this.setState({needToReEnterPin: false})
			this.setState({numberOfPinInput: 0})
			return;
		}

		if (this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri] !== undefined && this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri].length > 0) {
			var jsonObject = {
				pin: this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri],
				keyIdentifier: id
			};

			var cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

			var data = {
				"ciHsmTokenUri": this.props.tokenList.list[this.props.tokenList.selectedToken].uri,
				"encryptedData": cipherResult.encryptedData,
				"secretKey": cipherResult.secretKey,
				"twoFactorCode": this.props.login.twoFactorCode
			}

				
			this.props.actions.setShowTokenPopup(false);

			this.props.actions.removeCertificate(this.props.base.serviceUrl, this.props.login.accessToken, data)
			.then((response) => {
				if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {		
					_this.setState({numberOfPinInput: 0});
					_this.setState({isActiveView: false});			
					_this.props.actions.setActionResultData({status: "success", action: "deleteCertificate", message: i18next.t("success")})
                	_this.props.actions.setShowActionResult(true)
                	_this.props.actions.setNeedRefresh(this.props.tokenList.list[this.props.tokenList.selectedToken].uri)
				} else {
					if (response.json.code === "AUTHCOMMON-14") {
                		var currentCount = _this.state.numberOfPinInput

                		if (currentCount === 0) {
                			currentCount = _this.props.base.numberOfPinInput - 1;
                		} else {
                			currentCount = currentCount - 1;
                		}

                		_this.setState({numberOfPinInput: currentCount});
						_this.setState({needToReEnterPin: true});
                	}
					_this.props.actions.setErrorResponse(response.json)
					_this.setState({showPopup: true})
				}
			});
		}
	}

	handleDeleteCertificateRequest (id) {
		var _this = this, currentTime = new Date().getTime() / 1000;
		
		this.setState({
	        showConfirmPopup: false
	    });

		if (currentTime > this.props.base.expireTime) {
			this.props.actions.setShowSessionEndHandler(true);
			return;
		};

		if (this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri] !== undefined && this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri].length > 0) {
			var jsonObject = {
				pin: this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri],
				keyIdentifier: id
			};

			var cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

			var data = {
				"ciHsmTokenUri": this.props.tokenList.list[this.props.tokenList.selectedToken].uri,
				"encryptedData": cipherResult.encryptedData,
				"secretKey": cipherResult.secretKey
			}

			this.props.actions.removeCertificateRequest(this.props.base.serviceUrl, this.props.login.accessToken, data)
			.then((response) => {
				if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {					
					_this.props.actions.setActionResultData({status: "success", action: "deleteCertificateRequest", message: i18next.t("success")})
                	_this.props.actions.setShowActionResult(true)
                	_this.props.actions.setNeedRefresh(this.props.tokenList.list[this.props.tokenList.selectedToken].uri)
				} else {
					_this.props.actions.setErrorResponse(response.json)
					_this.setState({showPopup: true})
				}
			});
		}
	}

	handleDeletePrivateKey (id) {
		var _this = this, currentTime = new Date().getTime() / 1000;
		
		this.setState({
	        showConfirmPopup: false
	    });

		if (currentTime > this.props.base.expireTime) {
			this.props.actions.setShowSessionEndHandler(true);
			return;
		};

		if (this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri] !== undefined && this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri].length > 0) {
			
			this.setState({isActiveView: true})

			var jsonObject = {
				pin: this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri],
				keyIdentifier: id
			};

			var cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

			var data = {
				"ciHsmTokenUri": this.props.tokenList.list[this.props.tokenList.selectedToken].uri,
				"encryptedData": cipherResult.encryptedData,
				"secretKey": cipherResult.secretKey
			}

			if (this.props.login.is2fEnabled) {
				this.props.actions.removeAllByID2f(this.props.base.serviceUrl, this.props.login.accessToken, data)
				.then((response) => {
					if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
						this.setState({confirm2fTitle: "deleteKeys2fConfirm"})
						this.setState({active2fWaitingKeysHandler: this.handleDeletePrivateKey2f.bind(this)})
						this.setState({id: id});
						this.props.actions.setNeed2fConfirm(true);
					} else {
						this.props.actions.setErrorResponse(response.json)
						this.setState({showPopup: true})
						this.setState({isActiveView: false})
					}

					return;
				});
				
				return;
			}

			this.props.actions.removeAllByID(this.props.base.serviceUrl, this.props.login.accessToken, data)
			.then((response) => {
				if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {					
					_this.props.actions.setActionResultData({status: "success", action: "deleteKeys", message: i18next.t("success")})
                	_this.props.actions.setShowActionResult(true)
                	_this.props.actions.setNeedRefresh(this.props.tokenList.list[this.props.tokenList.selectedToken].uri)
				} else {
					_this.props.actions.setErrorResponse(response.json)
					_this.setState({showPopup: true})
				}
			});
		}
	}

	handleDeletePrivateKey2f (id) {
		var _this = this;

		this.setState({
	        showConfirmPopup: false
	    });

		this.props.actions.setNeed2fConfirm(false);

		if (id === true) {
			this.setState({isActiveView: false})
			this.setState({needToReEnterPin: false})
			this.setState({numberOfPinInput: 0})
			return;
		}

		if (this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri] !== undefined && this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri].length > 0) {
			var jsonObject = {
				pin: this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri],
				keyIdentifier: id
			};

			var cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

			var data = {
				"ciHsmTokenUri": this.props.tokenList.list[this.props.tokenList.selectedToken].uri,
				"encryptedData": cipherResult.encryptedData,
				"secretKey": cipherResult.secretKey,
				"twoFactorCode": this.props.login.twoFactorCode
			}

			this.props.actions.removeAllByID(this.props.base.serviceUrl, this.props.login.accessToken, data)
			.then((response) => {
				if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {					
					_this.props.actions.setActionResultData({status: "success", action: "deleteKeys", message: i18next.t("success")})
                	_this.props.actions.setShowActionResult(true)
                	_this.setState({isActiveView: false})
                	_this.props.actions.setNeedRefresh(this.props.tokenList.list[this.props.tokenList.selectedToken].uri)
				} else {
					if (response.json.code === "AUTHCOMMON-14") {
                		var currentCount = _this.state.numberOfPinInput

                		if (currentCount === 0) {
                			currentCount = _this.props.base.numberOfPinInput - 1;
                		} else {
                			currentCount = currentCount - 1;
                		}

                		_this.setState({numberOfPinInput: currentCount});
						_this.setState({needToReEnterPin: true});
                	}
            		
            		_this.props.actions.setErrorResponse(response.json)
					_this.setState({showPopup: true})
				}
			});
		}
	}

	closeInfoPopup () {
		this.setState({
	        showCertificateInfo: !this.state.showCertificateInfo
	    });
	}

	showInfo (data) {
		this.setState({info: this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[data]});
		console.log(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[data]);
		this.setState({showCertificateInfo: !this.state.showCertificateInfo});
	}

	componentDidMount() {
		
	}

	componentWillUnmount () {

	}

	closeConfirmPopup () {
		this.setState({
	        showConfirmPopup: false
	    });
	}

  	handleDeleteItem (action, id) {
  		var exit = false, _this = this;
  		console.log(action)
  		console.log(id)

  		this.props.tokenList.list.forEach(function (token, index) {
			if (token.uri === _this.props.tokenList.list[_this.props.tokenList.selectedToken].uri && token.status === "BLOCKED") {
				exit = true;
			}
		})

		if (exit) {
			return;
		}

  		switch (action) {
  			case "handleDeletePrivateKey": {
				this.setState({activeHandler: this.handleDeletePrivateKey.bind(this, id)})
				this.setState({actionInfo: "deleteKeys"})
    			break;
			}

			case "handleDeleteCertificate": {
				this.setState({activeHandler: this.handleDeleteCertificate.bind(this, id)})
				this.setState({actionInfo: "deleteCertificate"})
    			break;
			}

			case "handleDeleteCertificateRequest": {
				this.setState({activeHandler: this.handleDeleteCertificateRequest.bind(this, id)})
				this.setState({actionInfo: "deleteCertificateRequest"})
    			break;
			}

			default:
				break;
  		}

  		this.setState({
	        showConfirmPopup: true
	    });
  	}

	componentDidUpdate(prevProps) {
		if (this.props.tokenList.tokenEnquireSetted !== prevProps.tokenList.tokenEnquireSetted) {
			var _this = this, state;
			var tempState = {};
			this.setState({showActions: []})

			if (this.props.tokenList.tokenEnquire && this.props.tokenList.tokenEnquire.tokenContentDescriptorCached !== null && this.props.tokenList.tokenEnquire.tokenContentDescriptorCached !== undefined) {
				this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.keyIdentifiers.forEach((key, index) => {
					if (_this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequests[key] !== undefined && _this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequests[key].length > 0) {
						tempState[key] = false;
					}
				});
			}

			this.setState({showActions: tempState})
			this.setState({dropdownsSetted: true})
			state = tempState

			document.addEventListener('click', function (e) {
				if (e.target && e.target.id && state.hasOwnProperty(e.target.id)) {
					state[e.target.id] = true;
				} else {
					for (var key in state) {
					    state[key] = false
					}
				}

				_this.setState({showActions: state})
			});
		}
	}

	render() {
		var _this = this;
		return (
			<div>
				{
					this.props.tokenList.tokenEnquireSetted && this.props.tokenList.tokenEnquire.tokenContentDescriptorCached !== null && this.props.tokenList.tokenEnquire.tokenContentDescriptorCached !== undefined
					? <div style={{marginTop: "10px"}}>
				    	{
				    		Object.keys(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequests).length === 0 && this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequests.constructor === Object
				    		? null
				    		: <label style={{fontSize: "22px", color: "#a39f9d"}}>{i18next.t("waitingKeys")}</label>
				    	}
						{
							this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.keyIdentifiers.map((key, index) => {
								return (<div key={index}>
										{
											this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequests[key] !== undefined && this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequests[key].length > 0
											? <div style={{paddingLeft: "0px", paddingRight: "0px", marginBottom: "10px"}} className="table-border bg-color-active row col-lg-12 col-md-12 col-sm-12 col-xs-12">
												<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "left"}} className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
													<div className="table-header-white">
														<label>{i18next.t("owner")}</label>
													</div>
													<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
														<label>
															{
																this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[key].subject.commonName
															}
														</label>
														<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
															{
																i18next.t("drfou")
															}
															{
																this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[key].extensions.drfou
															}
														</label>
														<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
															{
																i18next.t("edrpou")
															}
															{
																this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[key].extensions.edrpou
															}
														</label>
													</div>
												</div>
												<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
													<div className="table-header-white">
														<label>{i18next.t("appointment")}</label>
													</div>
													<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
														<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
															{
																this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[key].extensions.keyUsage.indexOf("DIGITAL_SIGNATURE") > -1
																? i18next.t("DIGITAL_SIGNATURE")
																: null
															}
															{
																this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[key].extensions.keyUsage.indexOf("KEY_AGREEMENT") > -1
																? i18next.t("KEY_AGREEMENT")
																: null
															}
														</label>
														<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
															{
																this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[key].extensions.isElectronicStamp !== undefined && this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[key].extensions.isElectronicStamp === true
																? i18next.t("electronicStamp")
																: null
															}
														</label>
													</div>
												</div>
												<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
													<div className="table-header-white">
														<label>{i18next.t("key")}</label>
													</div>
													<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
														<div>
															<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
																{
																	i18next.t("algorithm")
																}
																{
																	this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[key].subjectPublicKeyInfo.algorithm === "1.2.840.10045.2.1" ? i18next.t("ECDSA-256") : (this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[key].subjectPublicKeyInfo.algorithm === "1.2.840.113549.1.1.1" ? i18next.t("RSA") : this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[key].subjectPublicKeyInfo.algorithm)
																}
															</label>
														</div>
														<div>
															<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
																{
																	i18next.t("identifire")
																}
															</label>
															<div className="margin-left-5" style={{wordWrap: "break-word", fontSize: "12px", textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
																{
																	key
																}
															</div>
														</div>
													</div>
												</div>
												<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
													<div className="table-header-white min-heigth-33">
														<label></label>
													</div>
												</div>
												<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-1 col-md-1 col-sm-12 col-xs-12">
													<div className="table-header-white min-heigth-33">
														<label></label>
													</div>
													<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
														<div className="dropdown" style={{marginTop: "5px"}}>
								                            <button type="button" id={key} className="btn btn-cog dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.showActions[index] !== undefined ? this.state.showActions[index].open : false}>
																<span><i  id={key} style={{fontSize: "2.5em"}} className={this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri] ? "fa fa-cog cog-active" : "fa fa-cog cog-disabled"}></i></span>
															</button>
								                            <div className={(_this.state.showActions[key] !== undefined && _this.state.showActions[key] === true && this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri] !== undefined) ? "dropdown-menu dropdown-menu-right show" : "dropdown-menu dropdown-menu-right"}>
								                                {
								                                	this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequests[key]
								                                	? <span className="dropdown-item pointer" onClick={this.handleDownloadCertificateRequest.bind(this, key)}>{i18next.t("downloadCertificateRequest")}</span>
								                                	: null
								                                }
								                                {
								                                	this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequests[key]
								                                	? <span className="dropdown-item pointer" onClick={this.showInfo.bind(this, key)}>{i18next.t("certificateRequestInfo")}</span>
								                                	: null
								                                }
								                                {
								                                	this.props.tokenList.list[this.props.tokenList.selectedToken].owner.id === this.props.login.userData.userName
			                                						? this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequests[key]
									                                	? <span className={this.props.tokenList.list[this.props.tokenList.selectedToken].status === "BLOCKED" ? "dropdown-item-body-disabled" : "dropdown-item pointer"} onClick={this.handleDeleteItem.bind(this, "handleDeletePrivateKey", key)}>{i18next.t("deleteKeys")}</span>
									                                	: null
									                                : null
								                                }
								                            </div>
								                        </div>
													</div>
												</div>
											</div>
											: null
										}
									</div>)
							})
						}
						<div>
							{
								this.state.showCertificateInfo
								? <CertificateInfoPopup certInfo={this.state.info} closePopup={this.closeInfoPopup.bind(this)} />
								: null
							}
						</div>
						{
							this.state.showPopup
							? <ErrorMessagePopup closeMessagePopup={this.showMessagePopup.bind(this)} data={this.props.userReducer.errorResponse} />
							: null
						}
						{
							this.state.showConfirmPopup
							? <ConfirmPopup closeConfirmPopup={this.closeConfirmPopup.bind(this)} confirmAction={this.state.activeHandler} data={this.state.actionInfo} />
							: null
						}
						{
							this.props.userReducer.need2fConfirm && this.state.isActiveView === true
							? <TwoFactorConfirmPopup initAction={this.state.active2fWaitingKeysHandler} title={this.state.confirm2fTitle} id={this.state.id} tries={this.state.numberOfPinInput} />
							: null
						}
						</div>
					: null
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer,
        tokenList: state.tokenListReducer,
        userReducer: state.userReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	formatDate,
    	removeCertificate, removeCertificateRequest, removePrivateKey,
    	setActionResultData, setShowActionResult, setErrorResponse,
    	setNeedRefresh, removeCertificate2f,
    	removeAllByID, removeAllByID2f, setNeed2fConfirm
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(WaitingKeysView);