import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

import { removeCertificate, removeCertificateRequest, removePrivateKey, removeCertificateRequest2f } from "../actions/api"
import { setActionResultData, setShowActionResult, setErrorResponse, setNeedRefresh, setNeed2fConfirm } from "../actions/localStates"
import { formatDate, cipher } from '../actions/helpers'

import saveAs from 'file-saver';

import CertificateInfoPopup from './CertificateInfoPopup'
import ConfirmPopup from './ConfirmPopup'

import TwoFactorConfirmPopup from './TwoFactorConfirmPopup'

class OtherCertificatesRequestsView extends Component {
	constructor() {
		super();
		this.state = {
			showActions: [],
			dropdownsSetted: false,
			showCertificateInfo: false,
			showPopup: false,
			info: null,
			showConfirmPopup: false,
			activeHandler: null,
			actionInfo: "",
			active2fOtherCertificatesHandler: "",
			confirm2fTitle: "",
	    	numberOfPinInput: 0,
	    	needToReEnterPin: false,
	    	isActiveView: false
		};

		this.handleShowActions = this.handleShowActions.bind(this);
	    this.handleCloseActions = this.handleCloseActions.bind(this);
	}

	showMessagePopup () {
		if (this.state.showPopup === true && this.state.needToReEnterPin === true) {
			if (this.state.numberOfPinInput > 0) {
				this.props.actions.setNeed2fConfirm(true);
			} else {
				this.setState({needToReEnterPin: false});
				this.setState({numberOfPinInput: 0});
			}
		}
    	this.setState({
          	showPopup: !this.state.showPopup
      	});
  	}

	handleShowActions (event) {
		if (this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri] === undefined) {
			document.activeElement.blur();
			return;
		}
		event.preventDefault();
		this.setState({
				showActions: !this.state.showActions
			}, () => {
			document.addEventListener('click', this.handleCloseActions);
		});
	}

	handleCloseActions (event) { 
		this.setState({ showActions: false }, () => {
			document.removeEventListener('click', this.handleCloseActions);
		});
	}

	handleDownloadCertificate (id) {
		const byteCharacters = atob(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[id]);
		const byteNumbers = new Array(byteCharacters.length);

		for (let i = 0; i < byteCharacters.length; i++) {
		  byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);

		const blob = new Blob([byteArray], {type: "application/octet-stream"});
		var fileName = id + ".cer";
		saveAs(blob, fileName);
	}

	handleDownloadCertificateRequest (id) {
		const byteCharacters = atob(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[id]);
		const byteNumbers = new Array(byteCharacters.length);

		for (let i = 0; i < byteCharacters.length; i++) {
		  byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);

		const blob = new Blob([byteArray], {type: "application/octet-stream"});
		var fileName = id + ".p10";
		saveAs(blob, fileName);
	}

	handleDeleteCertificate (id) {
		var _this = this, currentTime = new Date().getTime() / 1000;
		
		this.setState({
	        showConfirmPopup: false
	    });

		if (currentTime > this.props.base.expireTime) {
			this.props.actions.setShowSessionEndHandler(true);
			return;
		};

		if (this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri] !== undefined && this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri].length > 0) {
			var jsonObject = {
				pin: this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri],
				keyIdentifier: id
			};

			var cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

			var data = {
				"ciHsmTokenUri": this.props.tokenList.list[this.props.tokenList.selectedToken].uri,
				"encryptedData": cipherResult.encryptedData,
				"secretKey": cipherResult.secretKey
			}

			this.props.actions.removeCertificate(this.props.base.serviceUrl, this.props.login.accessToken, data)
			.then((response) => {
				if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {					
					_this.props.actions.setActionResultData({status: "success", action: "deleteCertificate", message: i18next.t("success")})
                	_this.props.actions.setShowActionResult(true)
                	_this.props.actions.setNeedRefresh(this.props.tokenList.list[this.props.tokenList.selectedToken].uri)
				} else {
					_this.props.actions.setErrorResponse(response.json)
					_this.setState({showPopup: true})
				}
			});
		}
	}

	handleDeleteCertificateRequest (id) {
		var _this = this, currentTime = new Date().getTime() / 1000;
		
		this.setState({
	        showConfirmPopup: false
	    });

		if (currentTime > this.props.base.expireTime) {
			this.props.actions.setShowSessionEndHandler(true);
			return;
		};

		if (this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri] !== undefined && this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri].length > 0) {
			
			this.setState({isActiveView: true})

			var jsonObject = {
				pin: this.props.userReducer.usersPins[id],
				keyIdentifier: id
			};

			var cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

			var data = {
				"ciHsmTokenUri": this.props.tokenList.list[this.props.tokenList.selectedToken].uri,
				"encryptedData": cipherResult.encryptedData,
				"secretKey": cipherResult.secretKey
			}
			
			if (this.props.login.is2fEnabled) {
				this.props.actions.removeCertificateRequest2f(this.props.base.serviceUrl, this.props.login.accessToken, data)
				.then((response) => {
					if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
						this.setState({confirm2fTitle: "deleteCertificateRequest2fConfirm"})
						this.setState({active2fOtherCertificatesRequestsHandler: this.handleDeleteCertificateRequest2f.bind(this)})
						this.setState({id: id});
						this.props.actions.setNeed2fConfirm(true);
					} else {
						this.props.actions.setErrorResponse(response.json)
						this.setState({showPopup: true})
					}

					return;
				})
				
				return;
			}

			this.props.actions.removeCertificateRequest(this.props.base.serviceUrl, this.props.login.accessToken, data)
			.then((response) => {
				if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {					
					_this.props.actions.setActionResultData({status: "success", action: "deleteCertificateRequest", message: i18next.t("success")})
                	_this.props.actions.setShowActionResult(true)
                	_this.props.actions.setNeedRefresh(this.props.tokenList.list[this.props.tokenList.selectedToken].uri)
				} else {
					_this.props.actions.setErrorResponse(response.json)
					_this.setState({showPopup: true})
				}
			});
		}
	}

	handleDeleteCertificateRequest2f (id) {
		var _this = this;

		this.setState({
	        showConfirmPopup: false
	    });

	    this.props.actions.setNeed2fConfirm(false);

		if (id === true) {
			this.setState({isActiveView: false})
			this.setState({needToReEnterPin: false})
			this.setState({numberOfPinInput: 0})
			return;
		}

		if (this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri] !== undefined && this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri].length > 0) {
			
			var jsonObject = {
				pin: this.props.userReducer.usersPins[id],
				keyIdentifier: id
			};

			var cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

			var data = {
				"ciHsmTokenUri": this.props.tokenList.list[this.props.tokenList.selectedToken].uri,
				"encryptedData": cipherResult.encryptedData,
				"secretKey": cipherResult.secretKey,
				"twoFactorCode": this.props.login.twoFactorCode
			}

			

			this.props.actions.removeCertificateRequest(this.props.base.serviceUrl, this.props.login.accessToken, data)
			.then((response) => {
				if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {					
					_this.props.actions.setActionResultData({status: "success", action: "deleteCertificateRequest", message: i18next.t("success")})
                	_this.props.actions.setShowActionResult(true)
                	_this.setState({isActiveView: false})
                	_this.props.actions.setNeedRefresh(this.props.tokenList.list[this.props.tokenList.selectedToken].uri)
				} else {
					if (response.json.code === "AUTHCOMMON-14") {
                		var currentCount = _this.state.numberOfPinInput

                		if (currentCount === 0) {
                			currentCount = _this.props.base.numberOfPinInput - 1;
                		} else {
                			currentCount = currentCount - 1;
                		}

                		_this.setState({numberOfPinInput: currentCount});
						_this.setState({needToReEnterPin: true});
                	}
					_this.props.actions.setErrorResponse(response.json)
					_this.setState({showPopup: true})
				}
			});
		}
	}

	handleDeletePrivateKey (id) {
		var _this = this, currentTime = new Date().getTime() / 1000;
		
		this.setState({
	        showConfirmPopup: false
	    });

		if (currentTime > this.props.base.expireTime) {
			this.props.actions.setShowSessionEndHandler(true);
			return;
		};

		if (this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri] !== undefined && this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri].length > 0) {
			var jsonObject = {
				pin: this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri],
				keyIdentifier: id
			};

			var cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

			var data = {
				"ciHsmTokenUri": this.props.tokenList.list[this.props.tokenList.selectedToken].uri,
				"encryptedData": cipherResult.encryptedData,
				"secretKey": cipherResult.secretKey
			}

			this.props.actions.removePrivateKey(this.props.base.serviceUrl, this.props.login.accessToken, data)
			.then((response) => {
				if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {					
					_this.props.actions.setActionResultData({status: "success", action: "deleteKeys", message: i18next.t("success")})
                	_this.props.actions.setShowActionResult(true)
				} else {
					_this.props.actions.setErrorResponse(response.json)
					_this.setState({showPopup: true})
				}
				_this.props.actions.removeCertificateRequest(this.props.base.serviceUrl, this.props.login.accessToken, data)
				.then((response) => {
					_this.props.actions.removeCertificate(this.props.base.serviceUrl, this.props.login.accessToken, data)
				});
			});
		}
	}

	closeInfoPopup () {
		this.setState({
	        showCertificateInfo: !this.state.showCertificateInfo
	    });
	}

	showInfo (data) {
		this.setState({info: this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[data]});
		this.setState({showCertificateInfo: !this.state.showCertificateInfo});
	}

	componentDidMount() {
		
	}

	componentWillUnmount () {

	}

	closeConfirmPopup () {
		this.setState({
	        showConfirmPopup: false
	    });
	}

  	handleDeleteItem (action, id) {
  		var exit = false, _this = this;

  		this.props.tokenList.list.forEach(function (token, index) {
			if (token.uri === _this.props.tokenList.list[_this.props.tokenList.selectedToken].uri && token.status === "BLOCKED") {
				exit = true;
			}
		})

		if (exit) {
			return;
		}

  		switch (action) {
  			case "handleDeletePrivateKey": {
				this.setState({activeHandler: this.handleDeletePrivateKey.bind(this, id)})
				this.setState({actionInfo: "deleteKeys"})
    			break;
			}

			case "handleDeleteCertificate": {
				this.setState({activeHandler: this.handleDeleteCertificate.bind(this, id)})
				this.setState({actionInfo: "deleteCertificate"})
    			break;
			}

			case "handleDeleteCertificateRequest": {
				this.setState({activeHandler: this.handleDeleteCertificateRequest.bind(this, id)})
				this.setState({actionInfo: "deleteCertificateRequest"})
    			break;
			}

			default:
				break;
  		}

  		this.setState({
	        showConfirmPopup: true
	    });
  	}

	componentDidUpdate(prevProps) {
		if (this.props.tokenList.tokenEnquireSetted !== prevProps.tokenList.tokenEnquireSetted) {
			var _this = this, state;
			var tempState = {};
			this.setState({showActions: []})

			function containsId (i) {
				if (_this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.keyIdentifiers.length > 0) {
					_this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.keyIdentifiers.forEach((key, index) => {
						if (_this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[key] !== undefined && _this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[key] !== i) {
							return true
						} else {
							return false
						}
					});
				} else {
					return true;
				}
				
			} 

			if (this.props.tokenList.tokenEnquire && this.props.tokenList.tokenEnquire.tokenContentDescriptorCached !== null && this.props.tokenList.tokenEnquire.tokenContentDescriptorCached !== undefined) {
				for (var i in this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos) {
					if (containsId(i)) {
						tempState[i] = false;
					}
				}
			}

			this.setState({showActions: tempState})
			this.setState({dropdownsSetted: true})
			state = tempState

			document.addEventListener('click', function (e) {
				if (e.target && e.target.id && state.hasOwnProperty(e.target.id)) {
					state[e.target.id] = true;
				} else {
					for (var key in state) {
					    state[key] = false
					}
				}

				_this.setState({showActions: state})
			});
		}
	}

	_renderData() {
		var data = [], resData = [], _this = this;

		function containsId (id) {
			if (_this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.keyIdentifiers.length > 0) {
				_this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.keyIdentifiers.forEach((key, index) => {
					if (_this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[key] !== undefined && _this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[key] !== id) {
						if (_this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.keyIdentifiers[id] !== undefined) {
							return;
						}
						data.push(
							<div key={id}>
								<div style={{paddingLeft: "0px", paddingRight: "0px", marginBottom: "10px"}} className="table-border bg-color row col-lg-12 col-md-12 col-sm-12 col-xs-12">
									<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "left"}} className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
										<div className="table-header-white">
											<label>{i18next.t("owner")}</label>
										</div>
										<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
											<label>
												{
													_this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[id].subject.commonName
												}
											</label>
											<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
												{
													i18next.t("drfou")
												}
												{
													_this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[id].extensions.drfou
												}
											</label>
											<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
												{
													i18next.t("edrpou")
												}
												{
													_this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[id].extensions.edrpou
												}
											</label>
										</div>
									</div>
									<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
										<div className="table-header-white">
											<label>{i18next.t("appointment")}</label>
										</div>
										<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
											<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
												{
													_this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[id].extensions.keyUsage.indexOf("DIGITAL_SIGNATURE") > -1
													? i18next.t("DIGITAL_SIGNATURE")
													: null
												}
												{
													_this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[id].extensions.keyUsage.indexOf("KEY_AGREEMENT") > -1
													? i18next.t("KEY_AGREEMENT")
													: null
												}
											</label>
											<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
												{
													_this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[id].extensions.isElectronicStamp !== undefined && _this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[id].extensions.isElectronicStamp === true
													? i18next.t("electronicStamp")
													: null
												}
											</label>
										</div>
									</div>
									<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
										<div className="table-header-white">
											<label>{i18next.t("key")}</label>
										</div>
										<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
											<div>
												<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
													{
														i18next.t("algorithm")
													}
													{
														_this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[id].subjectPublicKeyInfo.algorithm === "1.2.840.10045.2.1" ? i18next.t("ECDSA-256") : (_this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[id].subjectPublicKeyInfo.algorithm === "1.2.840.113549.1.1.1" ? i18next.t("RSA") : _this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[id].subjectPublicKeyInfo.algorithm)
													}
												</label>
											</div>
											<div>
												<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
													{
														i18next.t("identifire")
													}
												</label>
												<div className="margin-left-5" style={{wordWrap: "break-word", fontSize: "12px", textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
													{
														key
													}
												</div>
											</div>
										</div>
									</div>
									<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
										<div className="table-header-white min-heigth-33">
											<label></label>
										</div>
										<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
										</div>
									</div>
									<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-1 col-md-1 col-sm-12 col-xs-12">
										<div className="table-header-white min-heigth-33">
											<label></label>
										</div>
										<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
											<div className="dropdown" style={{marginTop: "5px"}}>
					                            <button type="button" id={id} className="btn btn-cog dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded={_this.state.showActions[id] !== undefined ? _this.state.showActions[id].open : false}>
													<span><i  id={id} style={{fontSize: "2.5em"}} className={_this.props.userReducer.usersPins[_this.props.tokenList.list[_this.props.tokenList.selectedToken].uri] ? "fa fa-cog cog-active" : "fa fa-cog cog-disabled"}></i></span>
												</button>
					                            <div className={(_this.state.showActions[id] !== undefined && _this.state.showActions[id] === true && _this.props.userReducer.usersPins[_this.props.tokenList.list[_this.props.tokenList.selectedToken].uri] !== undefined) ? "dropdown-menu dropdown-menu-right show" : "dropdown-menu dropdown-menu-right"}>
					                                {
					                                	_this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[id]
					                                	? <span className="dropdown-item pointer" onClick={_this.handleDownloadCertificate.bind(_this, id)}>{i18next.t("downloadCertificate")}</span>
					                                	: null
					                                }
					                                {
					                                	_this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[id]
					                                	? <span className="dropdown-item pointer" onClick={_this.showInfo.bind(_this, id)}>{i18next.t("certificateInfo")}</span>
					                                	: null
					                                }
					                                {
					                                	_this.props.tokenList.list[_this.props.tokenList.selectedToken].owner.id === _this.props.login.userData.userName
					                                	? _this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[id]
						                                	? <span className={_this.props.tokenList.list[_this.props.tokenList.selectedToken].status === "BLOCKED" ? "dropdown-item-body-disabled" : "dropdown-item pointer"} onClick={_this.handleDeleteItem.bind(_this, "handleDeleteCertificate", id)}>{i18next.t("deleteCertificate")}</span>
						                                	: null
						                                : null
					                                }
					                            </div>
					                        </div>
										</div>
									</div>
								</div>
							</div>)
						return data;
					}
				});
			} else {
				data.push(
					<div key={id}>
						<div style={{paddingLeft: "0px", paddingRight: "0px", marginBottom: "10px"}} className="table-border bg-color row col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "left"}} className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
								<div className="table-header-white">
									<label>{i18next.t("owner")}</label>
								</div>
								<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
									<label>
										{
											_this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[id].subject.commonName
										}
									</label>
									<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
										{
											i18next.t("drfou")
										}
										{
											_this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[id].extensions.drfou
										}
									</label>
									<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
										{
											i18next.t("edrpou")
										}
										{
											_this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[id].extensions.edrpou
										}
									</label>
								</div>
							</div>
							<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
								<div className="table-header-white">
									<label>{i18next.t("appointment")}</label>
								</div>
								<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
									<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
										{
											_this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[id].extensions.keyUsage.indexOf("DIGITAL_SIGNATURE") > -1
											? i18next.t("DIGITAL_SIGNATURE")
											: null
										}
										{
											_this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[id].extensions.keyUsage.indexOf("KEY_AGREEMENT") > -1
											? i18next.t("KEY_AGREEMENT")
											: null
										}
									</label>
									<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
										{
											_this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[id].extensions.isElectronicStamp !== undefined && _this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[id].extensions.isElectronicStamp === true
											? i18next.t("electronicStamp")
											: null
										}
									</label>
								</div>
							</div>
							<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
								<div className="table-header-white">
									<label>{i18next.t("key")}</label>
								</div>
								<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
									<div>
										<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
											{
												i18next.t("algorithm")
											}
											{
												_this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[id].subjectPublicKeyInfo.algorithm === "1.2.840.10045.2.1" ? i18next.t("ECDSA-256") : (_this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[id].subjectPublicKeyInfo.algorithm === "1.2.840.113549.1.1.1" ? i18next.t("RSA") : _this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[id].subjectPublicKeyInfo.algorithm)
											}
										</label>
									</div>
									<div>
										<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
											{
												i18next.t("identifire")
											}
										</label>
										<div className="margin-left-5" style={{wordWrap: "break-word", fontSize: "12px", textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
											{
												id
											}
										</div>
									</div>
								</div>
							</div>
							<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
								<div className="table-header-white">
									<label>{i18next.t("certificate")}</label>
								</div>
								<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
									<div>
										<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
											{
												i18next.t("notBefore")
											}
											{
												_this.props.actions.formatDate(_this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[id].notBefore)
											}
										</label>
									</div>
									<div>
										<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
											{
												i18next.t("notAfter")
											}
											{
												_this.props.actions.formatDate(_this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[id].notAfter)
											}
										</label>
									</div>
									<div>
										<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
											{
												i18next.t("issuer")
											}
											{
												_this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[id].issuer.commonName
											}
										</label>
									</div>
								</div>
							</div>
							<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-1 col-md-1 col-sm-12 col-xs-12">
								<div className="table-header-white min-heigth-33">
									<label></label>
								</div>
								<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
									<div className="dropdown" style={{marginTop: "5px"}}>
			                            <button type="button" id={id} className="btn btn-cog dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded={_this.state.showActions[id] !== undefined ? _this.state.showActions[id].open : false}>
											<span><i  id={id} style={{fontSize: "2.5em"}} className={_this.props.userReducer.usersPins[_this.props.tokenList.list[_this.props.tokenList.selectedToken].uri] ? "fa fa-cog cog-active" : "fa fa-cog cog-disabled"}></i></span>
										</button>
			                            <div className={(_this.state.showActions[id] !== undefined && _this.state.showActions[id] === true && _this.props.userReducer.usersPins[_this.props.tokenList.list[_this.props.tokenList.selectedToken].uri] !== undefined) ? "dropdown-menu dropdown-menu-right show" : "dropdown-menu dropdown-menu-right"}>
			                                {
			                                	_this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[id]
			                                	? <span className="dropdown-item pointer" onClick={_this.handleDownloadCertificate.bind(_this, id)}>{i18next.t("downloadCertificate")}</span>
			                                	: null
			                                }
			                                {
			                                	_this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[id]
			                                	? <span className="dropdown-item pointer" onClick={_this.showInfo.bind(_this, id)}>{i18next.t("certificateInfo")}</span>
			                                	: null
			                                }
			                                {
			                                	_this.props.tokenList.list[_this.props.tokenList.selectedToken].owner.id === _this.props.login.userData.userName
					                            ? _this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[id]
				                                	? <span className={_this.props.tokenList.list[_this.props.tokenList.selectedToken].status === "BLOCKED" ? "dropdown-item-body-disabled" : "dropdown-item pointer"} onClick={_this.handleDeleteItem.bind(_this, "handleDeleteCertificate", id)}>{i18next.t("deleteCertificate")}</span>
				                                	: null
				                                : null
			                                }
			                            </div>
			                        </div>
								</div>
							</div>
						</div>
					</div>)

			return data;
			}
		}

		if (this.props.tokenList.tokenEnquire && this.props.tokenList.tokenEnquire.tokenContentDescriptorCached !== null && this.props.tokenList.tokenEnquire.tokenContentDescriptorCached !== undefined) {
			for (var id in this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos) {
				resData.push(containsId(id));
			}

			return resData;
		}
	}

	render() {
		return (
			<div>
				{
					this.props.tokenList.tokenEnquireSetted
					? <div style={{marginTop: "10px"}}>
				    	{
							this.state.showActions && Object.keys(this.state.showActions).length === 0 && this.state.showActions.constructor === Object
							? null
							: <label style={{fontSize: "22px", color: "#a39f9d"}}>{i18next.t("otherCertificates")}</label>
						}

							<div>
								{	
									this._renderData()
								}
							</div>
						</div>
					: null
				}
				<div>
					{
						this.state.showCertificateInfo
						? <CertificateInfoPopup certInfo={this.state.info} closePopup={this.closeInfoPopup.bind(this)} />
						: null
					}
					{
						this.state.showConfirmPopup
						? <ConfirmPopup closeConfirmPopup={this.closeConfirmPopup.bind(this)} confirmAction={this.state.activeHandler} data={this.state.actionInfo} />
						: null
					}
					{
						this.props.userReducer.need2fConfirm && this.state.isActiveView === true
						? <TwoFactorConfirmPopup initAction={this.state.active2fOtherCertificatesRequestsHandler} title={this.state.confirm2fTitle} id={this.state.id} tries={this.state.numberOfPinInput} />
						: null
					}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer,
        tokenList: state.tokenListReducer,
        userReducer: state.userReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	formatDate,
    	removeCertificate, removeCertificateRequest, removePrivateKey,
    	setActionResultData, setShowActionResult, setErrorResponse,
    	setNeedRefresh, setNeed2fConfirm, removeCertificateRequest2f
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(OtherCertificatesRequestsView);