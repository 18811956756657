import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Dropzone from 'react-dropzone';
import i18next from 'i18next'

import { setShowImportCertificatePopup, setCertificateData, clearCertificateData, setCertificateFileName } from '../actions/localStates'

const dropzoneRef = createRef();

class ImportCertificatePopup extends Component {

  constructor(props) {
      super(props);
      this.state = {

      }

      this.closePopup = this.closePopup.bind(this);
      this.handleOnDrop = this.handleOnDrop.bind(this);

  }

  closePopup () {
    this.props.actions.clearCertificateData();
    this.props.actions.setShowImportCertificatePopup(false);
  }

  componentDidMount() {

  }

  handleOnDrop (files) {
    var file = files[0], data, _this = this;

    if (file) {
        var reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = function (evt) {
            data = evt.target.result;
            console.log(data)

            var binaryData = evt.target.result;
            //Converting Binary Data to base 64
            var base64String = window.btoa(binaryData);

            _this.props.actions.setCertificateFileName(file.name)
            _this.props.actions.setCertificateData(base64String)
            console.log(base64String)
        }
        reader.onerror = function (evt) {
            document.getElementById("fileContents").innerHTML = "error reading file";
        }
    }
  }

  render() {
      return (
          <div className='popup'>
              <div className="popup_inner_import">
                  <div style={{paddingLeft: "15px", paddingRight: "15px"}}>
                  <h4 style={{paddingTop: "30px"}}>{i18next.t("importCertificate")}</h4>
                    <Dropzone ref={dropzoneRef} accept=".cer, .crt" onDrop={this.handleOnDrop}>
                      {({getRootProps, getInputProps}) => (
                        <div {...getRootProps()} style={{cursor: "pointer", marginTop: "16px"}} className="dropzone">
                          <input {...getInputProps()} />
                          {
                            this.props.userReducer.certificateData
                            ? <p style={{marginTop: "16px", wordWrap: "break-word"}}>{this.props.userReducer.fileName}</p>
                            : <p style={{cursor: "pointer", marginTop: "16px"}}>{i18next.t("dragAndDropLabel")}</p>
                          }
                        </div>
                      )}
                    </Dropzone>
                  </div>
                  <div style={{float: "right", marginRight: "15px", paddingTop: "10px"}}>
                      <button className="btn btn-outline-info" style={{marginLeft: "5px", marginBottom: "30px"}} onClick={this.closePopup}>{i18next.t("cancelLabel")}</button>
                  </div>
                  <div style={{float: "right", paddingTop: "10px"}}>
                      <button className="btn btn-info" style={{marginLeft: "5px", marginBottom: "30px"}} disabled={this.props.userReducer.certificateData ? false : true} onClick={this.props.closeCertificatePopup}>{i18next.t("okLabel")}</button>
                  </div>
              </div>
          </div>
      );
  }
}

function mapStateToProps(state) {
    return {
        base: state.base,
        userReducer: state.userReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
        setShowImportCertificatePopup,
        setCertificateData, clearCertificateData,
        setCertificateFileName
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportCertificatePopup);