const baseState = {
    list: [],
    selectedToken: null,
    tokenEnquire: null,
    tokenEnquireSetted: false,
    tokenEnquiresList: {},
    warningsList: {}
}

const tokenListReducer = (state = baseState, { type, payload }) => {

    switch (type) {
    	case "SET_TOKEN_LIST": {
			return {
				...state,
				list: payload
			}
		}

		case "CLEAR_TOKEN_LIST": {
			return {
				...state,
				list: []
			}
		}

		case "SET_SELECTED_TOKEN": {
			return {
				...state,
				selectedToken: payload
			}
		}

		case "CLEAR_SELECTED_TOKEN": {
			return {
				...state,
				selectedToken: null
			}
		}

		case "SET_TOKEN_ENQUIRE": {
			return {
				...state,
				tokenEnquire: payload,
				tokenEnquireSetted: true
			}
		}

		case "CLEAR_TOKEN_ENQUIRE": {
			return {
				...state,
				tokenEnquire: null,
				tokenEnquireSetted: false
			}
		}

		case "SET_TOKEN_ENQUIRES_LIST": {
			return {
				...state,
				tokenEnquiresList: payload
			}
		}

		case "CLEAR_TOKEN_ENQUIRES_LIST": {
			return {
				...state,
				tokenEnquiresList: {}
			}
		}

		case "SET_WARNINGS_LIST": {
			return {
				...state,
				warningsList: payload
			}
		}

		case "CLEAR_WARNINGS_LIST": {
			return {
				...state,
				warningsList: {}
			}
		}

        default:
            return state;
    }
}

export default tokenListReducer