import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

import { removeCertificateRequest, removeCertificate, removePrivateKey, removeCertificate2f, removeAllByID, removeAllByID2f } from "../actions/api"
import { clearCertificateFileName, setActionResultData, setShowActionResult, setNeedRefresh, setErrorResponse, setNeed2fConfirm, setWarningsList } from "../actions/localStates"
import { formatDate, cipher, getDays } from '../actions/helpers'

import saveAs from 'file-saver';

import CertificateInfoPopup from './CertificateInfoPopup'
import ErrorMessagePopup from './ErrorMessagePopup'
import ConfirmPopup from './ConfirmPopup'
import TwoFactorConfirmPopup from './TwoFactorConfirmPopup'

import WarningPopup from './WarningPopup'

class ActiveKeysView extends Component {
	constructor() {
		super();
		this.state = {
			showAgreementActions: false,
			showSignatureActions: false,
			showCertificateInfo: false,
			showPopup: false,
			info: null,
			showConfirmPopup: false,
			activeHandler: null,
			actionInfo: "",
			active2fActiveKeysHandler: "",
			confirm2fTitle: "",
	    	numberOfPinInput: 0,
	    	needToReEnterPin: false,
	    	isActiveView: false,
	    	id: "",
	    	signatureType: null

		};

		this.handleShowAgreementActions = this.handleShowAgreementActions.bind(this);
	    this.handleCloseAgreementActions = this.handleCloseAgreementActions.bind(this);

	    this.handleShowSignatureActions = this.handleShowSignatureActions.bind(this);
	    this.handleCloseSignatureActions = this.handleCloseSignatureActions.bind(this);
	}

	showWarning (uri, signatureType) {
		var warnings = this.props.tokenList.warningsList

		warnings[uri] = true;

		if (signatureType !== undefined) {
			this.setState({signatureType: signatureType})
		} else {
			this.setState({signatureType: null})
		}

		this.props.actions.setWarningsList(warnings)
	}

	handleShowAgreementActions (event) {
		if (this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri] === undefined) {
			document.activeElement.blur();
			return;
		}
		event.preventDefault();
		this.setState({
				showAgreementActions: !this.state.showAgreementActions
			}, () => {
			document.addEventListener('click', this.handleCloseAgreementActions);
		});
	}

	handleCloseAgreementActions (event) { 
		this.setState({ showAgreementActions: false }, () => {
			document.removeEventListener('click', this.handleCloseAgreementActions);
		});  
	}

	handleShowSignatureActions (event) {
		if (this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri] === undefined) {
			document.activeElement.blur();
			return;
		}
		event.preventDefault();
		this.setState({
				showSignatureActions: !this.state.showSignatureActions
			}, () => {
			document.addEventListener('click', this.handleCloseSignatureActions);
		});
	}

	handleCloseSignatureActions (event) { 
		this.setState({ showSignatureActions: false }, () => {
			document.removeEventListener('click', this.handleCloseSignatureActions);
		});  
	}

	handleDownloadCertificate (id) {
		const byteCharacters = atob(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificates[id]);
		const byteNumbers = new Array(byteCharacters.length);

		for (let i = 0; i < byteCharacters.length; i++) {
		  byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);

		const blob = new Blob([byteArray], {type: "application/octet-stream"});
		var fileName = id + ".cer";
		saveAs(blob, fileName);
	}

	handleDownloadCertificateRequest (id) {
		const byteCharacters = atob(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequests[id]);
		const byteNumbers = new Array(byteCharacters.length);

		for (let i = 0; i < byteCharacters.length; i++) {
		  byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);

		const blob = new Blob([byteArray], {type: "application/octet-stream"});
		var fileName = id + ".cer";
		saveAs(blob, fileName);
	}

	handleDeleteCertificate (id) {
		var _this = this, currentTime = new Date().getTime() / 1000;

		this.setState({
	        showConfirmPopup: false
	    });

		if (currentTime > this.props.base.expireTime) {
			this.props.actions.setShowSessionEndHandler(true);
			return;
		};

		if (this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri] !== undefined && this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri].length > 0) {
			
			this.setState({isActiveView: true})

			var jsonObject = {
				pin: this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri],
				keyIdentifier: id
			};

			var cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

			var data = {
				"ciHsmTokenUri": this.props.tokenList.list[this.props.tokenList.selectedToken].uri,
				"encryptedData": cipherResult.encryptedData,
				"secretKey": cipherResult.secretKey
			}
			
			if (this.props.login.is2fEnabled) {
				this.props.actions.removeCertificate2f(this.props.base.serviceUrl, this.props.login.accessToken, data)
				.then((response) => {
					if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
						this.setState({confirm2fTitle: "deleteCertificate2fConfirm"})
						this.setState({active2fActiveKeysHandler: this.handleDeleteCertificate2f.bind(this)})
						this.setState({id: id});
						this.props.actions.setNeed2fConfirm(true);
					} else {
						this.props.actions.setErrorResponse(response.json)
						this.setState({showPopup: true})
					}

					return;
				});
				
				return;
			}

			this.props.actions.removeCertificate(this.props.base.serviceUrl, this.props.login.accessToken, data)
			.then((response) => {
				if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {					
					_this.props.actions.setActionResultData({status: "success", action: "deleteCertificate", message: i18next.t("success")})
                	_this.props.actions.setShowActionResult(true)
                	_this.props.actions.setNeedRefresh(this.props.tokenList.list[this.props.tokenList.selectedToken].uri)
				} else {
					_this.props.actions.setErrorResponse(response.json)
					_this.setState({showPopup: true})
				}

				console.log(response);
			});

			this.setState({showConfirmPopup: false})
		}
	}

	handleDeleteCertificate2f (id) {
		var _this = this;

		this.setState({
	        showConfirmPopup: false
	    });

	    this.props.actions.setNeed2fConfirm(false);
	    this.setState({showConfirmPopup: false})

	    if (id === true) {
			this.setState({isActiveView: false})
			this.setState({needToReEnterPin: false})
			this.setState({numberOfPinInput: 0})
			return;
		}

		if (this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri] !== undefined && this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri].length > 0) {

			var jsonObject = {
				pin: this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri],
				keyIdentifier: id
			};

			var cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

			var data = {
				"ciHsmTokenUri": this.props.tokenList.list[this.props.tokenList.selectedToken].uri,
				"encryptedData": cipherResult.encryptedData,
				"secretKey": cipherResult.secretKey,
				"twoFactorCode": this.props.login.twoFactorCode
			}

			this.props.actions.removeCertificate(this.props.base.serviceUrl, this.props.login.accessToken, data)
			.then((response) => {
				if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {					
					_this.props.actions.setActionResultData({status: "success", action: "deleteCertificate", message: i18next.t("success")})
                	_this.props.actions.setShowActionResult(true)
                	_this.setState({isActiveView: false})
                	_this.props.actions.setNeedRefresh(this.props.tokenList.list[this.props.tokenList.selectedToken].uri)
				} else {
					if (response.json.code === "AUTHCOMMON-14") {
                		var currentCount = _this.state.numberOfPinInput

                		if (currentCount === 0) {
                			currentCount = _this.props.base.numberOfPinInput - 1;
                		} else {
                			currentCount = currentCount - 1;
                		}

                		_this.setState({numberOfPinInput: currentCount});
						_this.setState({needToReEnterPin: true});
                	}
					_this.props.actions.setErrorResponse(response.json)
					_this.setState({showPopup: true})
				}

				console.log(response);
			});
		}
	}

	handleDeleteCertificateRequest (id) {
		var _this = this, currentTime = new Date().getTime() / 1000;
		
		if (currentTime > this.props.base.expireTime) {
			this.props.actions.setShowSessionEndHandler(true);
			return;
		};

		if (this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri] !== undefined && this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri].length > 0) {
			var jsonObject = {
				pin: this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri],
				keyIdentifier: id
			};

			var cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

			var data = {
				"ciHsmTokenUri": this.props.tokenList.list[this.props.tokenList.selectedToken].uri,
				"encryptedData": cipherResult.encryptedData,
				"secretKey": cipherResult.secretKey
			}

			this.props.actions.removeCertificateRequest(this.props.base.serviceUrl, this.props.login.accessToken, data)
			.then((response) => {
				if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {					
					_this.props.actions.setActionResultData({status: "success", action: "deleteCertificateRequest", message: i18next.t("success")})
                	_this.props.actions.setShowActionResult(true)
                	_this.props.actions.setNeedRefresh(this.props.tokenList.list[this.props.tokenList.selectedToken].uri)
				} else {
					_this.props.actions.setErrorResponse(response.json)
					_this.setState({showPopup: true})
				}
			});

			this.setState({showConfirmPopup: false})
		}
	}

	handleDeletePrivateKey (id) {
		var _this = this, currentTime = new Date().getTime() / 1000;

		this.setState({
	        showConfirmPopup: false
	    });

	    if (currentTime > this.props.base.expireTime) {
			this.props.actions.setShowSessionEndHandler(true);
			return;
		};

		if (this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri] !== undefined && this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri].length > 0) {
			
			this.setState({isActiveView: true})

			var jsonObject = {
				pin: this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri],
				keyIdentifier: id
			};

			var cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

			var data = {
				"ciHsmTokenUri": this.props.tokenList.list[this.props.tokenList.selectedToken].uri,
				"encryptedData": cipherResult.encryptedData,
				"secretKey": cipherResult.secretKey
			}

			if (this.props.login.is2fEnabled) {
				this.props.actions.removeAllByID2f(this.props.base.serviceUrl, this.props.login.accessToken, data)
				.then((response) => {
					if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
						this.setState({confirm2fTitle: "deleteKeys2fConfirm"})
						this.setState({active2fActiveKeysHandler: this.handleDeletePrivateKey2f.bind(this)})
						this.setState({id: id});
						this.props.actions.setNeed2fConfirm(true);
					} else {
						this.props.actions.setErrorResponse(response.json)
						this.setState({showPopup: true})
						this.setState({isActiveView: false})
					}

					return;
				});
				
				return;
			}

			this.props.actions.removeAllByID(this.props.base.serviceUrl, this.props.login.accessToken, data)
			.then((response) => {
				if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {					
					_this.props.actions.setActionResultData({status: "success", action: "deleteKeys", message: i18next.t("success")})
                	_this.props.actions.setShowActionResult(true)
                	_this.props.actions.setNeedRefresh(this.props.tokenList.list[this.props.tokenList.selectedToken].uri)
				} else {
					_this.props.actions.setErrorResponse(response.json)
					_this.setState({showPopup: true})
				}
			});
		}
	}

	handleDeletePrivateKey2f (id) {
		var _this = this;

		this.setState({
	        showConfirmPopup: false
	    });

		this.props.actions.setNeed2fConfirm(false);

		if (id === true) {
			this.setState({isActiveView: false})
			this.setState({needToReEnterPin: false})
			this.setState({numberOfPinInput: 0})
			return;
		}

		if (this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri] !== undefined && this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri].length > 0) {
			var jsonObject = {
				pin: this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri],
				keyIdentifier: id
			};

			var cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

			var data = {
				"ciHsmTokenUri": this.props.tokenList.list[this.props.tokenList.selectedToken].uri,
				"encryptedData": cipherResult.encryptedData,
				"secretKey": cipherResult.secretKey,
				"twoFactorCode": this.props.login.twoFactorCode
			}
					
			this.props.actions.removeAllByID(this.props.base.serviceUrl, this.props.login.accessToken, data)
			.then((response) => {
				if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {					
					_this.props.actions.setActionResultData({status: "success", action: "deleteKeys", message: i18next.t("success")})
                	_this.props.actions.setShowActionResult(true)
                	_this.setState({isActiveView: false})
                	_this.props.actions.setNeedRefresh(this.props.tokenList.list[this.props.tokenList.selectedToken].uri)
				} else {
					if (response.json.code === "AUTHCOMMON-14") {
                		var currentCount = _this.state.numberOfPinInput

                		if (currentCount === 0) {
                			currentCount = _this.props.base.numberOfPinInput - 1;
                		} else {
                			currentCount = currentCount - 1;
                		}

                		_this.setState({numberOfPinInput: currentCount});
						_this.setState({needToReEnterPin: true});
                	}
            		
            		_this.props.actions.setErrorResponse(response.json)
					_this.setState({showPopup: true})
				}
			});
		}
	}

	componentDidMount() {

	}

	componentWillUnmount () {

	}

	closeInfoPopup () {
		this.setState({
	        showCertificateInfo: !this.state.showCertificateInfo
	    });
	}

	showMessagePopup () {
		if (this.state.showPopup === true && this.state.needToReEnterPin === true) {
			if (this.state.numberOfPinInput > 0) {
				this.props.actions.setNeed2fConfirm(true);
			} else {
				this.setState({needToReEnterPin: false});
				this.setState({numberOfPinInput: 0});
			}
		}
    	this.setState({
          	showPopup: !this.state.showPopup
      	});
  	}

  	closeConfirmPopup () {
		this.setState({
	        showConfirmPopup: false
	    });
	}

  	handleDeleteItem (action, id) {
  		var exit = false, _this = this;
  		console.log(action)
  		console.log(id)

  		this.props.tokenList.list.forEach(function (token, index) {
			if (token.uri === _this.props.tokenList.list[_this.props.tokenList.selectedToken].uri && token.status === "BLOCKED") {
				exit = true;
			}
		})

		if (exit) {
			return;
		}

  		switch (action) {
  			case "handleDeletePrivateKey": {
				this.setState({activeHandler: this.handleDeletePrivateKey.bind(this, id)})
				this.setState({actionInfo: "deleteKeys"})
    			break;
			}

			case "handleDeleteCertificate": {
				this.setState({activeHandler: this.handleDeleteCertificate.bind(this, id)})
				this.setState({actionInfo: "deleteCertificate"})
    			break;
			}

			case "handleDeleteCertificateRequest": {
				this.setState({activeHandler: this.handleDeleteCertificateRequest.bind(this, id)})
				this.setState({actionInfo: "deleteCertificateRequest"})
    			break;
			}

			default:
				break;
  		}

  		this.setState({
	        showConfirmPopup: true
	    });
  	}

	showInfo (data) {
		this.setState({info: this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[data]});
		console.log(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[data]);
		this.setState({showCertificateInfo: !this.state.showCertificateInfo});
	}

	render() {
		let showAgreementOpen = 'dropdown-menu dropdown-menu-right'
		if (this.state.showAgreementActions) {
			showAgreementOpen = 'dropdown-menu dropdown-menu-right show'
		}

		let showSignatureOpen = 'dropdown-menu dropdown-menu-right'
		if (this.state.showSignatureActions) {
			showSignatureOpen = 'dropdown-menu dropdown-menu-right show'
		}

		return (
			<div>
				{
					this.props.tokenList.tokenEnquire && this.props.tokenList.tokenEnquire.tokenContentDescriptorCached !== null && this.props.tokenList.tokenEnquire.tokenContentDescriptorCached !== undefined
					? <div style={{marginTop: "10px"}}>
						{
							Object.keys(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys).length === 0 && this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.constructor === Object
							? null
							: <div>
								<label style={{fontSize: "22px", color: "#a39f9d"}}>{i18next.t("activeKeys")}</label>
								{
									this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT !== undefined
									? <div style={{paddingLeft: "0px", paddingRight: "0px", marginBottom: "10px"}} className={
										this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT] === undefined
										? "table-border bg-color-active row col-lg-12 col-md-12 col-sm-12 col-xs-12"
										: this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT] === "REVOKED" || this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT] === "EXPIRED" || this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT] === "INVALID"
										? "table-border-blocked row col-lg-12 col-md-12 col-sm-12 col-xs-12"
										: this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT] === "BLOCKED" || this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT] === "NOT_YET_VALID"
										? "table-border-blocked-cert row col-lg-12 col-md-12 col-sm-12 col-xs-12"
										: "table-border bg-color-active row col-lg-12 col-md-12 col-sm-12 col-xs-12"
										}>
										<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "left"}} className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
											<div className={this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT] !== undefined && (this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT] === "BLOCKED" || this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT] === "NOT_YET_VALID")
											? "table-header-blocked-active-keys"
											: "table-header"
											}>
												<label>{i18next.t("owner")}</label>
											</div>
											<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
												<label>
													{
														this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT].subject.commonName
													}
												</label>
												<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
													{
														i18next.t("drfou")
													}
													{
														this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT].extensions.drfou
													}
												</label>
												<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
													{
														i18next.t("edrpou")
													}
													{
														this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT].extensions.edrpou
													}
												</label>
											</div>
										</div>
										<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
											<div className={this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT] !== undefined && (this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT] === "BLOCKED" || this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT] === "NOT_YET_VALID")
											? "table-header-blocked-active-keys"
											: "table-header"}>
												<label>{i18next.t("appointment")}</label>
											</div>
											<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
												<div>
													<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
														{
															this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT] !== undefined && this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT].extensions.keyUsage.indexOf("KEY_AGREEMENT") > -1
															? i18next.t("KEY_AGREEMENT")
															: i18next.t("DIGITAL_SIGNATURE")
														}
													</label>
												</div>
												<div>
													<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
														{
															this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT] !== undefined && this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT].extensions.isElectronicStamp === true
															? i18next.t("electronicStamp")
															: null
														}
													</label>
												</div>
											</div>
										</div>
										<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
											<div className={this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT] !== undefined && (this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT] === "BLOCKED" || this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT] === "NOT_YET_VALID")
											? "table-header-blocked-active-keys"
											: "table-header"}>
												<label>{i18next.t("key")}</label>
											</div>
											<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
												<div>
													<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
														{
															i18next.t("algorithm")
														}
														{
															this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT].subjectPublicKeyInfo.algorithm === "1.2.840.10045.2.1" ? i18next.t("ECDSA-256") : (this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT].subjectPublicKeyInfo.algorithm === "1.2.840.113549.1.1.1" ? i18next.t("RSA") : this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT].subjectPublicKeyInfo.algorithm)
														}
													</label>
												</div>
												<div>
													<label className="row col-12" style={{textAlign: "left", paddingLeft: "0px", paddingRight: "0px", marginBottom: "0px"}}>
														{
															i18next.t("identifire")
														}
													</label>
													<div style={{wordWrap: "break-word", fontSize: "12px", textAlign: "left"}}>
														{
															this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT
														}
													</div>
												</div>
											</div>
										</div>
										<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
											<div className={this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT] !== undefined && (this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT] === "BLOCKED" || this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT] === "NOT_YET_VALID")
											? "table-header-blocked-active-keys"
											: "table-header"}>
												<label>{i18next.t("certificate")}</label>
											</div>
											<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
												<div>
													<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
														{
															i18next.t("certStatus")
														}
														{
															this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT] !== undefined ? i18next.t(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT]) : i18next.t("ACTIVE")
														}
													</label>
												</div>
												<div>
													<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
														{
															i18next.t("notBefore")
														}
														{
															this.props.actions.formatDate(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT].notBefore)
														}
													</label>
												</div>
												<div>
													<label className={this.props.actions.getDays(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT].notAfter) < 15 && this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT] !== "REVOKED" ? "col-12 red-color" : "col-12"} style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
														{
															i18next.t("notAfter")
														}
														{
															this.props.actions.formatDate(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT].notAfter)
														}
														{
															this.props.actions.getDays(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT].notAfter) < 15 && this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT] !== "REVOKED"
															? <i className="fa fa-exclamation-triangle" style={{"paddingLeft": "5px", "cursor": "pointer"}} onClick={this.showWarning.bind(this, this.props.tokenList.list[this.props.tokenList.selectedToken].uri, "agreement")}></i>
															: null
														}
													</label>
												</div>
												<div>
													<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
														{
															i18next.t("issuer")
														}
														{
															this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT].issuer.commonName
														}
													</label>
												</div>
											</div>
										</div>
										<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-1 col-md-1 col-sm-12 col-xs-12">
											<div className={this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT] !== undefined && (this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT] === "BLOCKED" || this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT] === "NOT_YET_VALID")
											? "table-header-blocked-active-keys min-heigth-33"
											: "table-header min-heigth-33"}>
												<label></label>
											</div>
											<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
												<div className="dropdown" style={{marginTop: "5px"}}>
						                            <button type="button" onClick={this.handleShowAgreementActions} className="btn btn-cog dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.showAgreementActions}>
														<span><i style={{fontSize: "2.5em"}} className={this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri] ? "fa fa-cog cog-active" : "fa fa-cog cog-disabled"}></i></span>
													</button>
						                            <div className={showAgreementOpen}>
						                                {
						                                	this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificates[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT]
						                                	? <span className="dropdown-item pointer" onClick={this.handleDownloadCertificate.bind(this, this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT)}>{i18next.t("downloadCertificate")}</span>
						                                	: null
						                                }
						                                {
						                                	this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequests[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT]
						                                	? <span className="dropdown-item pointer" onClick={this.handleDownloadCertificateRequest.bind(this, this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT)}>{i18next.t("downloadCertificateRequest")}</span>
						                                	: null
						                                }
						                                {
						                                	this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificates[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT]
						                                	? <span className="dropdown-item pointer" onClick={this.showInfo.bind(this, this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT)}>{i18next.t("certificateInfo")}</span>
						                                	: null
						                                }
						                                {
						                                	this.props.tokenList.list[this.props.tokenList.selectedToken].owner.id === this.props.login.userData.userName
			                                				? this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificates[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT]
							                                	? <span className={this.props.tokenList.list[this.props.tokenList.selectedToken].status === "BLOCKED" ? "dropdown-item-body-disabled" : "dropdown-item pointer"} onClick={this.handleDeleteItem.bind(this, "handleDeleteCertificate", this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT)}>{i18next.t("deleteCertificate")}</span>
							                                	: null
							                                : null
						                                }
						                                {
						                                	this.props.tokenList.list[this.props.tokenList.selectedToken].owner.id === this.props.login.userData.userName
			                                				? this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequests[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT]
							                                	? <span className={this.props.tokenList.list[this.props.tokenList.selectedToken].status === "BLOCKED" ? "dropdown-item-body-disabled" : "dropdown-item pointer"} onClick={this.handleDeleteItem.bind(this, "handleDeleteCertificateRequest", this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT)}>{i18next.t("deleteCertificateRequest")}</span>
							                                	: null
							                                : null
						                                }
														{
						                                	this.props.tokenList.list[this.props.tokenList.selectedToken].owner.id === this.props.login.userData.userName
			                                				? this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificates[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT]
							                                	? <span className={this.props.tokenList.list[this.props.tokenList.selectedToken].status === "BLOCKED" ? "dropdown-item-body-disabled" : "dropdown-item pointer"} onClick={this.handleDeleteItem.bind(this, "handleDeletePrivateKey", this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT)}>{i18next.t("deleteKeys")}</span>
							                                	: null
							                                :null
						                                }
						                            </div>
						                        </div>
											</div>
										</div>
									</div>
									: null
								}
								{
									this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE !== undefined
									? <div style={{paddingLeft: "0px", paddingRight: "0px"}} className={
										this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE] === undefined
										? "table-border bg-color-active row col-lg-12 col-md-12 col-sm-12 col-xs-12"
										: this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE] === "REVOKED" || this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE] === "EXPIRED" || this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE] === "INVALID"
										? "table-border-blocked row col-lg-12 col-md-12 col-sm-12 col-xs-12"
										: this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE] === "BLOCKED" || this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE] === "NOT_YET_VALID"
										? "table-border-blocked-cert row col-lg-12 col-md-12 col-sm-12 col-xs-12"
										: "table-border bg-color-active row col-lg-12 col-md-12 col-sm-12 col-xs-12"
										}>
										<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "left"}} className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
											<div className={this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE] !== undefined && (this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE] === "BLOCKED" || this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE] === "NOT_YET_VALID")
											? "table-header-blocked-active-keys"
											: "table-header"
											}>
												<label>{i18next.t("owner")}</label>
											</div>
											<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
												<label>
													{
														this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE].subject.commonName
													}
												</label>
												<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
													{
														i18next.t("drfou")
													}
													{
														this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE].extensions.drfou
													}
												</label>
												<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
													{
														i18next.t("edrpou")
													}
													{
														this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE].extensions.edrpou
													}
												</label>
											</div>
										</div>
										<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
											<div className={this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE] !== undefined && (this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE] === "BLOCKED" || this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE] === "NOT_YET_VALID")
											? "table-header-blocked-active-keys"
											: "table-header"
											}>
												<label>{i18next.t("appointment")}</label>
											</div>
											<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
												<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
													{
														this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE] !== undefined && this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE].extensions.keyUsage.indexOf("DIGITAL_SIGNATURE") > -1
														? i18next.t("DIGITAL_SIGNATURE")
														: i18next.t("KEY_AGREEMENT")
													}
												</label>
												<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
													{
														this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE] !== undefined && this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE].extensions.isElectronicStamp === true
														? i18next.t("electronicStamp")
														: null
													}
												</label>
											</div>
										</div>
										<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
											<div className={this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE] !== undefined && (this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE] === "BLOCKED" || this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE] === "NOT_YET_VALID")
											? "table-header-blocked-active-keys"
											: "table-header"
											}>
												<label>{i18next.t("key")}</label>
											</div>
											<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
												<div>
													<div>
														<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
															{
																i18next.t("algorithm")
															}
															{
																this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE].subjectPublicKeyInfo.algorithm === "1.2.840.10045.2.1" ? i18next.t("ECDSA-256") : (this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE].subjectPublicKeyInfo.algorithm === "1.2.840.113549.1.1.1" ? i18next.t("RSA") : this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE].subjectPublicKeyInfo.algorithm)
															}
														</label>
													</div>
													<div>
														<label className="row col-12" style={{textAlign: "left", paddingLeft: "0px", paddingRight: "0px", marginBottom: "0px"}}>
															{
																i18next.t("identifire")
															}
														</label>
														<div style={{wordWrap: "break-word", fontSize: "12px", textAlign: "left"}}>
															{
																this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE
															}
														</div>
													</div>
												</div>
											</div>
										</div>
										<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
											<div className={this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE] !== undefined && (this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE] === "BLOCKED" || this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE] === "NOT_YET_VALID")
											? "table-header-blocked-active-keys"
											: "table-header"
											}>
												<label>{i18next.t("certificate")}</label>
											</div>
											<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
												<div>
													<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
														{
															i18next.t("certStatus")
														}
														{
															this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE] !== undefined ? i18next.t(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE]) : i18next.t("ACTIVE")
														}
													</label>
												</div>
												<div>
													<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
														{
															i18next.t("notBefore")
														}
														{
															this.props.actions.formatDate(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE].notBefore)
														}
													</label>
												</div>
												<div>
													<label className={this.props.actions.getDays(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE].notAfter) < 15 && this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE] !== "REVOKED" ? "col-12 red-color" : "col-12"} style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
														{
															i18next.t("notAfter")
														}
														{
															this.props.actions.formatDate(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE].notAfter)
														}
														{
															this.props.actions.getDays(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE].notAfter) < 15 && this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE] !== "REVOKED"
															? <i className="fa fa-exclamation-triangle" style={{"paddingLeft": "5px", "cursor": "pointer"}} onClick={this.showWarning.bind(this, this.props.tokenList.list[this.props.tokenList.selectedToken].uri, "signature")}></i>
															: null
														}
													</label>
												</div>
												<div>
													<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
														{
															i18next.t("issuer")
														}
														{
															this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE].issuer.commonName
														}
													</label>
												</div>
											</div>
										</div>
										<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-1 col-md-1 col-sm-12 col-xs-12">
											<div className={this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE] !== undefined && (this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE] === "BLOCKED" || this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateStatuses[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE] === "NOT_YET_VALID")
											? "table-header-blocked-active-keys min-heigth-33"
											: "table-header min-heigth-33"
											}>
												<label></label>
											</div>
											<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
												<div className="dropdown" style={{marginTop: "5px"}}>
						                            <button type="button" onClick={this.handleShowSignatureActions} className="btn btn-cog dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.showSignatureActions}>
														<span><i style={{fontSize: "2.5em"}} className={this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri] ? "fa fa-cog cog-active" : "fa fa-cog cog-disabled"}></i></span>
													</button>
						                            <div className={showSignatureOpen}>
						                                {
						                                	this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificates[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE]
						                                	? <span className="dropdown-item pointer" onClick={this.handleDownloadCertificate.bind(this, this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE)}>{i18next.t("downloadCertificate")}</span>
						                                	: null
						                                }
						                                {
						                                	this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequests[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE]
						                                	? <span className="dropdown-item pointer" onClick={this.handleDownloadCertificateRequest.bind(this, this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE)}>{i18next.t("downloadCertificateRequest")}</span>
						                                	: null
						                                }
						                                {
						                                	this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificates[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE]
						                                	? <span className="dropdown-item pointer" onClick={this.showInfo.bind(this, this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE)}>{i18next.t("certificateInfo")}</span>
						                                	: null
						                                }
						                                {
						                                	this.props.tokenList.list[this.props.tokenList.selectedToken].owner.id === this.props.login.userData.userName
			                                				? this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificates[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE]
							                                	? <span className={this.props.tokenList.list[this.props.tokenList.selectedToken].status === "BLOCKED" ? "dropdown-item-body-disabled" : "dropdown-item pointer"} onClick={this.handleDeleteItem.bind(this, "handleDeleteCertificate", this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE)}>{i18next.t("deleteCertificate")}</span>
							                                	: null
							                                : null
						                                }
						                                {
						                                	this.props.tokenList.list[this.props.tokenList.selectedToken].owner.id === this.props.login.userData.userName
			                                				? this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequests[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE]
							                                	? <span className={this.props.tokenList.list[this.props.tokenList.selectedToken].status === "BLOCKED" ? "dropdown-item-body-disabled" : "dropdown-item pointer"} onClick={this.handleDeleteItem.bind(this, "handleDeleteCertificateRequest", this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE)}>{i18next.t("deleteCertificateRequest")}</span>
							                                	: null
							                                : null
						                                }
														{
						                                	this.props.tokenList.list[this.props.tokenList.selectedToken].owner.id === this.props.login.userData.userName
			                                				? this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificates[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE]
							                                	? <span className={this.props.tokenList.list[this.props.tokenList.selectedToken].status === "BLOCKED" ? "dropdown-item-body-disabled" : "dropdown-item pointer"} onClick={this.handleDeleteItem.bind(this, "handleDeletePrivateKey", this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE)}>{i18next.t("deleteKeys")}</span>
							                                	: null
							                                : null
						                                }
						                            </div>
						                        </div>
											</div>
										</div>
									</div>
									: null
								}
								{
						        	this.props.tokenList.list[this.props.tokenList.selectedToken] && this.props.tokenList.warningsList[this.props.tokenList.list[this.props.tokenList.selectedToken].uri] !== undefined && this.props.tokenList.warningsList[this.props.tokenList.list[this.props.tokenList.selectedToken].uri] === true
						        	? <WarningPopup tokenUri={this.props.tokenList.list[this.props.tokenList.selectedToken].uri} signatureType={this.state.signatureType} />
						        	: null
						        }
							</div>
						}
						<div>
							{
								this.state.showCertificateInfo
								? <CertificateInfoPopup certInfo={this.state.info} closePopup={this.closeInfoPopup.bind(this)} />
								: null
							}
						</div>
						{
							this.state.showPopup
							? <ErrorMessagePopup closeMessagePopup={this.showMessagePopup.bind(this)} data={this.props.userReducer.errorResponse} />
							: null
						}
						{
							this.state.showConfirmPopup
							? <ConfirmPopup closeConfirmPopup={this.closeConfirmPopup.bind(this)} confirmAction={this.state.activeHandler} data={this.state.actionInfo} />
							: null
						}
						{
							this.props.userReducer.need2fConfirm && this.state.isActiveView
							? <TwoFactorConfirmPopup initAction={this.state.active2fActiveKeysHandler} title={this.state.confirm2fTitle} id={this.state.id} tries={this.state.numberOfPinInput} />
							: null
						}
					</div>
					: null
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer,
        tokenList: state.tokenListReducer,
        userReducer: state.userReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	formatDate,
    	removeCertificateRequest, removeCertificate, removePrivateKey,
    	cipher, clearCertificateFileName, setActionResultData,
		setShowActionResult, setNeedRefresh, setErrorResponse,
		removeCertificate2f, setNeed2fConfirm,
		removeAllByID, removeAllByID2f, getDays,
		setWarningsList
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveKeysView);