import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Cookies from 'universal-cookie';

import { getAvailabelChannels, kerberosAuth } from '../actions/api'

import { clearLoggedInUserData, clearToken, setIsLoggedIn, setSelectedUser, setAllUserFields, setShowUserInfo,
			setLoginInput, setPasswordInput, clearTokenList, clearSelectedToken, clearUserPin, clearUserPuk, clearUsersPins, clearTempId, clearTokenEnquire,
			clearCertificateData, clearTokenEnquiresList, clearTwoFactorEnabled, clearTwoFactorCode, setShowTwoFactorSettings,
			setTwoFactorAvailabelChannels, setTwoFactorChannelId, setTwoFactorSettings, setUpdate2fSettingsGrant, setIs2fEnabled,
			setShowLogoutHandler, setShowActionResult, setCertificateAutoIssuing, setNeed2fConfirm, setExpireTime, setNeedToLogout,
			setShowSessionEndHandler, setNeedReLogin, setShowTokenPopup, setChangePasswordGrant, clearWarningsList, setToken, setShowTrusteesPopup } from '../actions/localStates'

import AboutPopup from './AboutPopup'
import LogoutPopup from './LogoutPopup'
import Popup from '../containers/Popup'

import i18next from 'i18next'

const cookies = new Cookies();

class Header extends Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    	showOptions: false,
	    	showPopup: false,
	    	showApps: false,
	    	showPasswordPopup: false
	    }

	    this.handleShowOptions = this.handleShowOptions.bind(this);
	    this.closeMenu = this.closeMenu.bind(this);
	    this.logoutHandler = this.logoutHandler.bind(this);
	    this.showVersion = this.showVersion.bind(this);

	    this.getCurrentUser = this.getCurrentUser.bind(this);

	    this.handleShowApps = this.handleShowApps.bind(this);
	    this.closeApps = this.closeApps.bind(this);

	    this.twoFactorSettings = this.twoFactorSettings.bind(this);

	    this.handleChangePassword = this.handleChangePassword.bind(this);
	}

	handleShowOptions (event) {
		event.preventDefault();
		this.setState({
				showOptions: !this.state.showOptions
			}, () => {
			document.addEventListener('click', this.closeMenu);
		});
	}

	logoutHandler () {
		this.props.actions.clearLoggedInUserData();
		this.props.actions.clearToken();
		this.props.actions.setIsLoggedIn(false);
		this.props.actions.setLoginInput("");
		this.props.actions.setPasswordInput("");
		this.props.actions.clearTokenList();
		this.props.actions.clearSelectedToken();
		this.props.actions.clearUserPin();
		this.props.actions.clearUserPuk();
		this.props.actions.clearUsersPins();
		this.props.actions.clearTempId();
		this.props.actions.clearTokenEnquire();
		this.props.actions.clearCertificateData();
		this.props.actions.clearTokenEnquiresList();
		this.props.actions.clearTwoFactorEnabled();
		this.props.actions.clearTwoFactorCode();
		this.props.actions.setShowTwoFactorSettings(false);
		this.props.actions.setTwoFactorChannelId(null);
		this.props.actions.setTwoFactorSettings(null);
		this.props.actions.setUpdate2fSettingsGrant(false);
		this.props.actions.setIs2fEnabled(false);
		this.props.actions.setShowLogoutHandler(false);
		this.props.actions.setShowActionResult(false);
		this.props.actions.setCertificateAutoIssuing(false);
		this.props.actions.setNeed2fConfirm(false);
		this.props.actions.setExpireTime(0);
		this.props.actions.setShowSessionEndHandler(false);
		this.props.actions.setNeedToLogout(false);
		this.props.actions.setNeedReLogin(false);
		this.props.actions.setShowTokenPopup(false);
		this.props.actions.setChangePasswordGrant(false);
		this.props.actions.clearWarningsList();
		this.props.actions.setShowTrusteesPopup(false);
	}

	getCurrentUser () {
		this.props.actions.setSelectedUser(this.props.login.userData);
		this.props.actions.setAllUserFields(this.props.login.userData);
		this.props.actions.setShowUserInfo(true);
	}

	twoFactorSettings () {
		if (!this.props.login.update2fSettingsGrant) {
			return
		}

		var currentTime = new Date().getTime() / 1000;
		if (currentTime > this.props.base.expireTime) {
			this.props.actions.setShowSessionEndHandler(true);
			return;
		};

		this.props.actions.getAvailabelChannels(this.props.base.authUrl, this.props.login.accessToken)
		.then((response) => {
			this.props.actions.setTwoFactorAvailabelChannels([{id: "disabled",title: i18next.t("disabledTitle")}].concat(response));
			console.log(response);
		});

		this.props.actions.setAllUserFields(this.props.login.userData);
		this.props.actions.setShowTwoFactorSettings(true);
	}

	showVersion () {
		this.setState({
	        showPopup: !this.state.showPopup
	    });
	}

	handleShowApps (event) {
		event.preventDefault();
		this.setState({
				showApps: !this.state.showApps
			}, () => {
			document.addEventListener('click', this.closeApps);
		});
	}

	componentDidMount() {

	}

	closePopup () {
		this.setState({
	        showPopup: !this.state.showPopup
	    });
	}

	showMenu(event) {
		event.preventDefault();

		this.setState({ showOptions: true }, () => {
			document.addEventListener('click', this.closeMenu);
		});
	}

	closeApps(event) { 
		this.setState({ showApps: false }, () => {
			document.removeEventListener('click', this.closeApps);
		});  
	}

	closeMenu(event) { 
		this.setState({ showOptions: false }, () => {
			document.removeEventListener('click', this.closeMenu);
		});  
	}

	redirectToApp(url) {
		if (this.props.login.accessToken !== null) {
			cookies.set('tempTokenData', this.props.login.accessToken, { path: '/', expires: new Date(Date.now() + 10000), domain: this.props.base.domain });
		}

		window.location.href = url;
	}

	handleChangePassword () {
		this.setState({
	        showPasswordPopup: !this.state.showPasswordPopup
	    });
	}

	render() {
		let showOptionsOpen = 'dropdown-menu dropdown-menu-right'
		if (this.state.showOptions) {
			showOptionsOpen = 'dropdown-menu dropdown-menu-right show'
		}

		let showAppsOpen = 'dropdown-menu'
		if (this.state.showApps) {
			showAppsOpen = 'dropdown-menu show'
		}

		if (this.props.base.needToLogout !== false) {
			this.logoutHandler()
		}

		return (
			<div className={this.props.login.loggedInUserData ? "header-heigth" : "no-login-header"} style={{backgroundColor: "#1C92C7", positon: "fixed", top: "0px", left: "0px", zIndex: "10"}}>
				<div className="row col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{paddingLeft: "0px", paddingRight: "0px"}}>
					{
						this.props.base.apps !== undefined && this.props.base.apps.length > 0
						? <div style={{paddingRight: "0px", paddingTop: "5px", float: "right"}}>
							<div style={{textAlign: "right",  color: "white"}}>
								<div className="btn-group">
									<button type="button" style={{color: "white"}} onClick={this.handleShowApps} className="btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.showApps}>
										<i className="fa fa-bars fa-2x"></i>
									</button>
									<div className={showAppsOpen} style={{minWidth: "200px", width: "100%", backgroundColor: "#1C92C7"}}>
										{
											this.props.base.apps.map((item, index) => {
												return <span className="dropdown-item header-span" key={index} onClick={this.redirectToApp.bind(this, item.url)} style={{color: "white", textDecoration: "none", cursor: "pointer"}}>{item.clientTitle}</span>
											})	
										}
									</div>
								</div>
							</div>
						</div>
						: null
					}
					<span className="col-lg-8 col-md-8 col-sm-8 col-xs-10" style={{textAlign: "left", fontSize: "24px", paddingTop: "10px", color: "white"}}>
						{i18next.t("headerTitle")}
					</span>

					<div style={{paddingRight: "0px", paddingTop: "15px", float: "right"}}>
						{
							this.props.login.loggedInUserData
							? <div style={{textAlign: "right",  color: "white"}}>
								<div className="btn-group dropdown topright" style={{minWidth: "200px"}}>
									<button type="button" style={{color: "white"}} onClick={this.handleShowOptions} className="btn dropdown-toggle display-none" data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.showOptions}>
										<span>{this.props.login.loggedInUserName}</span>&nbsp;
										<span style={{textAlign: "right"}}>({this.props.login.loggedInUserId})</span>
									</button>
									<button type="button" style={{color: "white", textAlign: "right"}} onClick={this.handleShowOptions} className="btn dropdown-toggle display-none-min" data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.showOptions}>
										<span>{this.props.login.loggedInUserId}</span>
									</button>
									<div className={showOptionsOpen} style={{minWidth: "5rem", backgroundColor: "#1C92C7"}}>
										<span className="dropdown-item header-span display-none-min" disabled style={{color: "white", cursor: "none"}}>{this.props.login.loggedInUserName}</span>
										<span className="dropdown-item header-span" onClick={this.getCurrentUser} style={{cursor: "pointer", color: "white"}}>{i18next.t("userDataButtonText")}</span>
										<span className={this.props.login.update2fSettingsGrant ? "dropdown-item header-span" : "dropdown-item-disabled header-span-disabled"} onClick={this.twoFactorSettings}>{i18next.t("twoFactor")}</span>
										{
											this.props.login.changePasswordGrant
											? <span className="dropdown-item header-span" onClick={this.handleChangePassword} style={{cursor: "pointer", color: "white"}}>{i18next.t("changePasswordLabel")}</span>
											: null
										}
										<span className="dropdown-item header-span" style={{cursor: "pointer", color: "white"}} onClick={this.showVersion}>{i18next.t("aboutButtonText")}</span>
										<span className="dropdown-item header-span" onClick={this.logoutHandler} style={{cursor: "pointer", color: "white"}}>{i18next.t("exitButtonText")}</span>
									</div>
								</div>
							</div>
							: null
						}
					</div>
				</div>
				{
					this.state.showPopup
					? <AboutPopup closePopup={this.closePopup.bind(this)} />
		            : null
		        }
		        {
					this.props.base.showLogoutHandler
					? <LogoutPopup logout={this.logoutHandler} />
		            : null
		        }
		        {
					this.props.base.needToLogout
					? this.logoutHandler()
					: null
				}

				{
					this.state.showPasswordPopup
					? <Popup closePopup={this.handleChangePassword.bind(this)} />
		            : null
		        }
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    clearLoggedInUserData, clearToken,
	    setIsLoggedIn,
		setLoginInput, setPasswordInput,
		setSelectedUser, setAllUserFields, setShowUserInfo,
		clearTokenList, clearSelectedToken,
		clearUserPin, clearUserPuk, clearUsersPins,
		clearTempId, clearTokenEnquire,
		clearCertificateData, clearTokenEnquiresList,
		clearTwoFactorEnabled, clearTwoFactorCode,
		setShowTwoFactorSettings, getAvailabelChannels,
		setTwoFactorAvailabelChannels,
		setTwoFactorChannelId, setTwoFactorSettings,
		setUpdate2fSettingsGrant, setIs2fEnabled,
		setShowLogoutHandler, setShowActionResult,
		setCertificateAutoIssuing, setNeed2fConfirm,
		setExpireTime, setNeedToLogout,
		setShowSessionEndHandler, setNeedReLogin,
		setShowTokenPopup, setChangePasswordGrant,
		clearWarningsList, kerberosAuth, setToken,
		setShowTrusteesPopup
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);