import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

import { setShowTokenPukPopup, setUserPukCode } from '../actions/localStates'

class TokenPukPopup extends Component {

  constructor(props) {
      super(props);
      this.state = {
          puk: ""
      }

      this.handlePukInput = this.handlePukInput.bind(this);

      this.setPuk = this.setPuk.bind(this);
      this.closePopup = this.closePopup.bind(this);

      this.handlePukKeyUp = this.handlePukKeyUp.bind(this, 'pukInput');
  }

  componentDidMount() {
      this.props.actions.setUserPukCode("")
      var element = document.getElementById("puk");
      element.focus();
      document.addEventListener('keyup', this.handlePukKeyUp);
  }

  componentWillUnmount() {
      document.removeEventListener('keyup', this.handlePukKeyUp);
  }

  handlePukKeyUp(refName, e) {
      if (e.keyCode === 13 && refName === "pukInput") {
          document.getElementById("submitPuk").click();
          document.activeElement.blur();
      }
  }

  handlePukInput (e) {
    // console.log(e.target.value)
    this.setState({puk: e.target.value})
    this.props.actions.setUserPukCode(e.target.value);
  }

  setPuk () {
    // var pins = this.props.userReducer.usersPins;

    // pins[this.props.userReducer.tempId] = this.state.pin;

    this.props.actions.setUserPukCode(this.state.puk);
    this.props.closeTokenPukPopup(this.props.userReducer.tempId);
    this.props.actions.setShowTokenPukPopup(false);
  }

  closePopup () {
    this.props.actions.setUserPukCode("")
    this.props.actions.setShowTokenPukPopup(false);
  }

  hoverPuk() {
        this.setState({showPuk: true})
  }

  outPuk() {
      this.setState({showPuk: false})
  }

  render() {
    let isEnabled = this.state.puk.length >= 4
    ? true
    : false

      return (
          <div className='popup'>
              <div className={"popup_inner_user_token_pin"}>
                  <div className="col-12" style={{marginTop: "30px"}}>
                      <div>
                          <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingTop: "0px", paddingBottom: "0px"}}>{i18next.t("pukInput")}</label>
                          <div className="col-12 input-group">
                              <input id="puk" className="form-control" value={this.state.puk} type={this.state.showPuk ? "input" : "password"} onChange={this.handlePukInput} />
                              <div className="input-group-append">
                                  <div className="input-group-text" onMouseOver={this.hoverPuk.bind(this)} onMouseOut={this.outPuk.bind(this)}><i className="fa fa-eye"></i></div>
                              </div>
                          </div>

                      </div>
                      <div style={{float: "right", marginRight: "15px", paddingTop: "10px"}}>
                          <button className="btn btn-info" id="submitPuk" style={{marginLeft: "5px", marginBottom: "30px"}} disabled={!isEnabled} onClick={this.setPuk}>{i18next.t("deleteToken")}</button>
                          <button className="btn btn-outline-info" style={{marginLeft: "5px", marginBottom: "30px"}} onClick={this.closePopup}>{i18next.t("cancelLabel")}</button>
                      </div>
                  </div>
              </div>
          </div>
      );
    }
}

function mapStateToProps(state) {
    return {
        base: state.base,
        selectedClient: state.clientReducer,
        login: state.loginReducer,
        userReducer: state.userReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
      setShowTokenPukPopup, setUserPukCode
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TokenPukPopup);