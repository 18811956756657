import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

import { login, verifyToken, getUserProfile, getPublicKeyCurrentUtcDateTime, init2f, login2f } from '../actions/api';
import { setIsLoggedIn, setToken, setLoggedInUserData, setExpireInTime, setAllUserFields, setPublicKeyCurrentUtcDateTime, setTwoFactorEnabled,
        clearTwoFactorEnabled, clearTwoFactorCode, setPasswordInput, setUpdate2fSettingsGrant, setIs2fEnabled, setCertificateIssuingGrant,
        setExpireTime, setTimeDelta, setChangePasswordGrant, setShowSessionEndHandler, setNeedReLogin } from '../actions/localStates'

import LoginView from '../components/LoginView';

class LoginContainer extends Component {

	constructor(props) {
    super(props);

        this.state = {
            token: "",
            numberOfPinInput: 0,
            inputs2f: 0
        }

        this.loginRef = React.createRef();

        this.login = this.login.bind(this);
        this.login2f = this.login2f.bind(this);
        this.loginKerberos = this.loginKerberos.bind(this);
    }

	componentDidMount() {
        
	}

    loginKerberos () {
        var userData, _this = this, user;
        var containsRole = false;
        var error = false;

        user = {
            "client_id" : this.props.base.clientId
        }

        var data = {
            twoFactorCode: ""
        }

        if (this.props.base.isKerberos) {
            let xhr = new XMLHttpRequest();
            xhr.open('GET', this.props.base.selectedAuthProvider);
            xhr.withCredentials = true;

            xhr.send();

            xhr.onerror = function() {
                alert(i18next.t("kerberosError"))
            }

            xhr.onload = function() {
                let responseObj = JSON.parse(xhr.response);
                console.log(responseObj);

                if (responseObj["access_token"] !== undefined) {
                    function parseJwt (token) {
                        var base64Url = token.split('.')[1];
                        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                        }).join(''));

                        return JSON.parse(jsonPayload);
                    };

                    _this.props.actions.setToken(responseObj.access_token);
                    userData = parseJwt(responseObj.access_token);
                    console.log(userData)

                    if (userData.twof) {
                        _this.props.actions.init2f(_this.props.base.authUrl, data, _this.props.login.accessToken)
                        .then((response) => {
                            console.log(response);
                        })
                        _this.props.actions.setTwoFactorEnabled(true);
                        _this.props.actions.setIs2fEnabled(true);
                        return;
                    } else {
                        if (!userData.twof) {
                            _this.props.actions.login2f(_this.props.base.authUrl, user, _this.props.login.accessToken)
                            .then((response) => {
                                if (response.code !== undefined) {
                                    _this.props.actions.clearTwoFactorEnabled();
                                    _this.props.actions.clearTwoFactorCode();
                                    _this.props.actions.setPasswordInput("");
                                    error = true;
                                    alert(response.message);
                                    return
                                }

                                function parseJwt (token) {
                                    var base64Url = token.split('.')[1];
                                    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                                    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                                        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                                    }).join(''));

                                    return JSON.parse(jsonPayload);
                                };
                                _this.props.actions.setToken(response.access_token);
                                userData = parseJwt(response.access_token);
                                _this.props.actions.setExpireTime(userData.exp)
                            })
                            .then(() => {
                                if (!error) {
                                    _this.props.actions.verifyToken(_this.props.base.authUrl, _this.props.login.accessToken)
                                    .then((response) => {
                                        if (response.expires_in !== undefined) {
                                            _this.props.actions.setExpireInTime(response.expires_in)
                                        }
                                        
                                        userData.scope.forEach(function (role) {
                                            if (role === "UPDATE_2F_SETTINGS_GRANT") {
                                                _this.props.actions.setUpdate2fSettingsGrant(true)
                                            }

                                            if (role === "CERTIFICATE_ISSUING_GRANT") {
                                                _this.props.actions.setCertificateIssuingGrant(true)
                                            }

                                            if (role === "CHANGE_PASSWORD_GRANT") {
                                                _this.props.actions.setChangePasswordGrant(true)
                                            }

                                            if (role === "VTMS_USER" || role === "VTMS_ADMINISTRATOR") {
                                                containsRole = true; 
                                            } else {
                                                return;
                                            }
                                        });


                                        if (containsRole) {
                                            _this.props.actions.getUserProfile(_this.props.base.authUrl, _this.props.login.accessToken)
                                            .then((response) => {
                                                _this.props.actions.setLoggedInUserData(response);
                                                _this.props.actions.setAllUserFields(response);

                                                _this.props.actions.getPublicKeyCurrentUtcDateTime(_this.props.base.serviceUrl, _this.props.login.accessToken)
                                                .then((response) => {
                                                    var delta = new Date(response.currentUtcDateTime).getTime() - Date.now()
                                                    _this.props.actions.setTimeDelta(delta)
                                                    _this.props.actions.setPublicKeyCurrentUtcDateTime(response);
                                                    _this.props.actions.setIsLoggedIn(true);
                                                    _this.props.actions.setShowSessionEndHandler(false);
                                                    _this.props.actions.setNeedReLogin(false);
                                                })
                                            })
                                        } else {
                                            alert(i18next.t("premissionDenied"));
                                        }
                                    })
                                }
                            })
                        }
                    }
                }
                
            }
        }
    }

    login2f () {
        var userData, _this = this;
        var containsRole = false;
        var error = false;

        var code = {
            "twoFactorCode": this.props.login.twoFactorCode
        }
        
        
        this.props.actions.login2f(this.props.base.authUrl, code, this.props.login.accessToken)
        .then((response) => {
            if (response.code !== undefined) {
                if (response.code === "AUTHCOMMON-14") {
                    var currentCount = this.state.numberOfPinInput

                    var count = this.state.inputs2f

                    if (currentCount === 0) {
                        currentCount = this.props.base.numberOfPinInput - 1;
                    } else {
                        currentCount = currentCount - 1;
                        count += 1;
                    }

                    this.setState({inputs2f: count});

                    if (this.state.inputs2f === this.props.base.numberOfPinInput - 1) {
                        this.setState({inputs2f: 0});
                        this.setState({numberOfPinInput: 0});
                        this.props.actions.clearTwoFactorEnabled();
                        this.props.actions.clearTwoFactorCode();
                        this.props.actions.setPasswordInput("");
                        error = true;
                        alert(response.message);
                        return
                    }

                    this.setState({numberOfPinInput: currentCount});
                    this.props.actions.clearTwoFactorCode();
                    error = true;
                    alert(response.message);
                    return

                } else {
                    this.props.actions.clearTwoFactorEnabled();
                    this.props.actions.clearTwoFactorCode();
                    this.props.actions.setPasswordInput("");
                    error = true;
                    alert(response.message);
                    return
                }
            }

            function parseJwt (token) {
                var base64Url = token.split('.')[1];
                var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));

                return JSON.parse(jsonPayload);
            };

            this.setState({numberOfPinInput: 0});
            this.props.actions.setToken(response.access_token);
            userData = parseJwt(response.access_token);
            this.props.actions.setExpireTime(userData.exp)
        })
        .then(() => {
            if (!error) {
                this.props.actions.verifyToken(this.props.base.authUrl, this.props.login.accessToken)
                .then((response) => {
                    if (response.expires_in !== undefined) {
                        this.props.actions.setExpireInTime(response.expires_in)
                    }

                    userData.scope.forEach(function (role) {
                        if (role === "UPDATE_2F_SETTINGS_GRANT") {
                            _this.props.actions.setUpdate2fSettingsGrant(true)
                        }

                        if (role === "CERTIFICATE_ISSUING_GRANT") {
                            _this.props.actions.setCertificateIssuingGrant(true)
                        }

                        if (role === "CHANGE_PASSWORD_GRANT") {
                            _this.props.actions.setChangePasswordGrant(true)
                        }

                        if (role === "VTMS_USER" || role === "VTMS_ADMINISTRATOR") {
                            containsRole = true; 
                        } else {
                            return;
                        }
                    });


                    if (containsRole) {
                        this.props.actions.getUserProfile(this.props.base.authUrl, this.props.login.accessToken)
                        .then((response) => {
                            _this.props.actions.setLoggedInUserData(response);
                            _this.props.actions.setAllUserFields(response);

                            _this.props.actions.getPublicKeyCurrentUtcDateTime(this.props.base.serviceUrl, this.props.login.accessToken)
                            .then((response) => {
                                var delta = new Date(response.currentUtcDateTime).getTime() - Date.now()
                                _this.props.actions.setTimeDelta(delta)
                                _this.props.actions.setTwoFactorEnabled(false);
                                _this.props.actions.clearTwoFactorCode();
                                _this.props.actions.setPublicKeyCurrentUtcDateTime(response);
                                _this.props.actions.setIsLoggedIn(true);
                                _this.props.actions.setShowSessionEndHandler(false);
                                _this.props.actions.setNeedReLogin(false);
                            })
                        })
                    } else {
                        alert(i18next.t("premissionDenied"));
                    }
                })
            }
        })
    }

	login () {
        var userData, _this = this, user;
        var containsRole = false;
        var error = false;

        var substring = "handyman";

        _this.props.actions.setShowSessionEndHandler(false);
        _this.props.actions.setNeedReLogin(false);

        if (this.props.base.selectedAuthProvider.indexOf(substring) !== -1) {
            user = {
                "grant_type" : "password",
                "user" : this.props.login.login,
                "client_id" : this.props.base.clientId,
                "password" : this.props.login.password
            }
        } else {
            user = {
                "grant_type" : "password",
                "username" : this.props.login.login,
                "client_id" : this.props.base.clientId,
                "password" : this.props.login.password
            }
        }

        var data = {
            twoFactorCode: ""
        }

    	this.props.actions.login(this.props.base.selectedAuthProvider, user)
    	.then((response) => {
            if (response.code !== undefined) {
                alert(response.message);
                return
            }

            function parseJwt (token) {
                var base64Url = token.split('.')[1];
                var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));

                return JSON.parse(jsonPayload);
            };

            this.props.actions.setToken(response.access_token);
            userData = parseJwt(response.access_token);
            console.log(userData)

            if (userData.twof) {
                this.props.actions.init2f(this.props.base.authUrl, data, this.props.login.accessToken)
                .then((response) => {
                    console.log(response);
                })
                this.props.actions.setTwoFactorEnabled(true);
                this.props.actions.setIs2fEnabled(true);
                return;
            } else {
                if (!userData.twof) {
                    this.props.actions.login2f(this.props.base.authUrl, user, this.props.login.accessToken)
                    .then((response) => {
                        if (response.code !== undefined) {
                            this.props.actions.clearTwoFactorEnabled();
                            this.props.actions.clearTwoFactorCode();
                            this.props.actions.setPasswordInput("");
                            error = true;
                            alert(response.message);
                            return
                        }

                        function parseJwt (token) {
                            var base64Url = token.split('.')[1];
                            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                            var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                            }).join(''));

                            return JSON.parse(jsonPayload);
                        };
                        this.props.actions.setToken(response.access_token);
                        userData = parseJwt(response.access_token);
                        this.props.actions.setExpireTime(userData.exp)
                    })
                    .then(() => {
                        if (!error) {
                            this.props.actions.verifyToken(this.props.base.authUrl, this.props.login.accessToken)
                            .then((response) => {
                                if (response.expires_in !== undefined) {
                                    this.props.actions.setExpireInTime(response.expires_in)
                                }
                                
                                userData.scope.forEach(function (role) {
                                    if (role === "UPDATE_2F_SETTINGS_GRANT") {
                                        _this.props.actions.setUpdate2fSettingsGrant(true)
                                    }

                                    if (role === "CERTIFICATE_ISSUING_GRANT") {
                                        _this.props.actions.setCertificateIssuingGrant(true)
                                    }

                                    if (role === "CHANGE_PASSWORD_GRANT") {
                                        _this.props.actions.setChangePasswordGrant(true)
                                    }

                                    if (role === "VTMS_USER" || role === "VTMS_ADMINISTRATOR") {
                                        containsRole = true; 
                                    } else {
                                        return;
                                    }
                                });


                                if (containsRole) {
                                    this.props.actions.getUserProfile(this.props.base.authUrl, this.props.login.accessToken)
                                    .then((response) => {
                                        _this.props.actions.setLoggedInUserData(response);
                                        _this.props.actions.setAllUserFields(response);

                                        _this.props.actions.getPublicKeyCurrentUtcDateTime(this.props.base.serviceUrl, this.props.login.accessToken)
                                        .then((response) => {
                                            var delta = new Date(response.currentUtcDateTime).getTime() - Date.now()
                                            _this.props.actions.setTimeDelta(delta)
                                            _this.props.actions.setPublicKeyCurrentUtcDateTime(response);
                                            _this.props.actions.setIsLoggedIn(true);
                                            _this.props.actions.setShowSessionEndHandler(false);
                                            _this.props.actions.setNeedReLogin(false);
                                        })
                                    })
                                } else {
                                    alert(i18next.t("premissionDenied"));
                                }
                            })
                        }
                    })
                }
            }
        })
	}

	render() {
		return (
			<div>
				<LoginView loginUser={this.login} loginUser2f={this.login2f} loginKerberos={this.loginKerberos} forwardRef={this.loginRef} tries={this.state.numberOfPinInput} />
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	login, setIsLoggedIn,
        verifyToken, setToken,
        setLoggedInUserData,
        setExpireInTime,
        getUserProfile,
        setAllUserFields,
        getPublicKeyCurrentUtcDateTime,
        setPublicKeyCurrentUtcDateTime,
        init2f, login2f, setTwoFactorEnabled,
        clearTwoFactorEnabled, clearTwoFactorCode,
        setPasswordInput,
        setUpdate2fSettingsGrant, setIs2fEnabled,
        setCertificateIssuingGrant,
        setExpireTime, setTimeDelta,
        setChangePasswordGrant, setShowSessionEndHandler,
        setNeedReLogin
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);