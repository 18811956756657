import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

class ConfirmPopup extends Component {

  constructor(props) {
      super(props);
      this.state = {
      }

  }

  componentDidMount() {

  }

  render() {
      return (
          <div className='popup'>
                <div className="popup_inner_confirm">
                    <div className="col-12" style={{paddingTop: "30px", fontSize: "18px", color: "grey"}}>
                        <label>{i18next.t("removeLabel")}{i18next.t(this.props.data)}</label>
                        <div style={{float: "right", paddingTop: "10px"}}>
                          <button className="btn btn-outline-info" style={{marginRight: "10px", width: "75px", marginBottom: "30px"}} onClick={this.props.confirmAction}>{i18next.t("okLabel")}</button>
                          <button className="btn btn-outline-info" style={{marginRight: "0px", width: "75px", marginBottom: "30px"}} onClick={this.props.closeConfirmPopup}>{i18next.t("cancelLabel")}</button>
                        </div>
                    </div>
                </div>
          </div>
      );
  }
}

function mapStateToProps(state) {
    return {
        base: state.base
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
      
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPopup);