import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

import { setWarningsList } from '../actions/localStates'
import { getDays } from '../actions/helpers'

class WarningPopup extends Component {

  constructor(props) {
      super(props);

      this.okbutton = this.okbutton.bind(this);
  }

  componentDidMount () {

  }

  componentWillUnmount () {

  }

  okbutton () {
    var warnings = this.props.tokenList.warningsList
    warnings[this.props.tokenUri] = false;
    this.props.actions.setWarningsList(warnings)
  }

  render() {
      return (
          <div className='popup'>
              <div className={"popup_inner_user_warning"}>
                  <div className="col-12" style={{marginTop: "30px"}}>
                  {
                    this.props.signatureType === null
                    ? <div>
                      {
                        this.props.tokenList.tokenEnquire && this.props.tokenList.tokenEnquire.tokenContentDescriptorCached !== null
                        ? <div>
                            {
                              Object.keys(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys).length > 0 && this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.constructor === Object
                              ? <div>
                                  {
                                    this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT !== undefined
                                    ? new Date(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT].notAfter).getTime() < (Date.now())
                                      ? <label style={{marginLeft: "15px", marginRight: "15px"}}>{i18next.t("expiredAgreement")}</label>
                                      : new Date(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT].notAfter).getTime() > (Date.now()) && this.props.actions.getDays(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT].notAfter) < 15
                                        ? <label style={{marginLeft: "15px", marginRight: "15px"}}>{i18next.t("warningAgreement") + " " + this.props.actions.getDays(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT].notAfter) + " " + (this.props.actions.getDays(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT].notAfter) === 1 ? i18next.t("warningDay") : this.props.actions.getDays(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT].notAfter) > 1 && this.props.actions.getDays(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT].notAfter) < 5 ? i18next.t("warningDays") : i18next.t("warningDayss"))}</label>
                                        : null
                                    : null
                                  }
                                </div>
                              : null
                            }
                          </div>
                        : null
                      }
                      {
                        this.props.tokenList.tokenEnquire && this.props.tokenList.tokenEnquire.tokenContentDescriptorCached !== null
                        ? <div>
                            {
                              Object.keys(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys).length > 0 && this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.constructor === Object
                              ? <div>
                                  {
                                    this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE !== undefined
                                    ? new Date(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE].notAfter).getTime() < (Date.now())
                                      ? <label style={{marginLeft: "15px", marginRight: "15px"}}>{i18next.t("expiredSignature")}</label>
                                      : new Date(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE].notAfter).getTime() > (Date.now()) && this.props.actions.getDays(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE].notAfter) < 15
                                        ? <label style={{marginLeft: "15px", marginRight: "15px"}}>{i18next.t("warningSignature") + " " + this.props.actions.getDays(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE].notAfter) + " " + (this.props.actions.getDays(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE].notAfter) === 1 ? i18next.t("warningDay") : this.props.actions.getDays(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE].notAfter) > 1 && this.props.actions.getDays(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE].notAfter) < 5 ? i18next.t("warningDays") : i18next.t("warningDayss"))}</label>
                                        : null
                                    : null
                                  }
                                </div>
                              : null
                            }
                          </div>
                        : null
                      }
                    </div>
                    : this.props.signatureType === "signature"
                    ? <div>
                          {
                            Object.keys(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys).length > 0 && this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.constructor === Object
                            ? <div>
                                {
                                  this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE !== undefined
                                  ? new Date(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE].notAfter).getTime() < (Date.now())
                                    ? <label style={{marginLeft: "15px", marginRight: "15px"}}>{i18next.t("expiredSignature")}</label>
                                    : new Date(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE].notAfter).getTime() > (Date.now()) && this.props.actions.getDays(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE].notAfter) < 15
                                      ? <label style={{marginLeft: "15px", marginRight: "15px"}}>{i18next.t("warningSignature") + " " + this.props.actions.getDays(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE].notAfter) + " " + (this.props.actions.getDays(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE].notAfter) === 1 ? i18next.t("warningDay") : this.props.actions.getDays(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE].notAfter) > 1 && this.props.actions.getDays(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE].notAfter) < 5 ? i18next.t("warningDays") : i18next.t("warningDayss"))}</label>
                                      : null
                                  : null
                                }
                              </div>
                            : null
                          }
                        </div>
                    : <div>
                        {
                          Object.keys(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys).length > 0 && this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.constructor === Object
                          ? <div>
                              {
                                this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT !== undefined
                                ? new Date(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT].notAfter).getTime() < (Date.now())
                                  ? <label style={{marginLeft: "15px", marginRight: "15px"}}>{i18next.t("expiredAgreement")}</label>
                                  : new Date(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT].notAfter).getTime() > (Date.now()) && this.props.actions.getDays(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT].notAfter) < 15
                                    ? <label style={{marginLeft: "15px", marginRight: "15px"}}>{i18next.t("warningAgreement") + " " + this.props.actions.getDays(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT].notAfter) + " " + (this.props.actions.getDays(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT].notAfter) === 1 ? i18next.t("warningDay") : this.props.actions.getDays(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT].notAfter) > 1 && this.props.actions.getDays(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT].notAfter) < 5 ? i18next.t("warningDays") : i18next.t("warningDayss"))}</label>
                                    : null
                                : null
                              }
                            </div>
                          : null
                        }
                      </div>
                  }
                    {
                      this.props.signatureType === null
                      ? <label style={{marginLeft: "15px", marginRight: "15px"}}>{i18next.t("warningLastRow")}</label>
                      : <label style={{marginLeft: "15px", marginRight: "15px"}}>{i18next.t("warningLastRowOne")}</label>
                    }
                      
                      <div style={{float: "right", marginRight: "15px", paddingTop: "10px"}}>
                          <button className="btn btn-outline-info" style={{marginLeft: "5px", marginBottom: "30px"}} onClick={this.okbutton}>{i18next.t("okLabel")}</button>
                      </div>
                  </div>
              </div>
          </div>
      );
    }
}

function mapStateToProps(state) {
    return {
        base: state.base,
        selectedClient: state.clientReducer,
        login: state.loginReducer,
        userReducer: state.userReducer,
        tokenList: state.tokenListReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
      setWarningsList, getDays
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(WarningPopup);