export const getConfiguration = (url, token) => (dispatch) => {
  return fetch(url + "/configuration/", {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return response.json()
      } else {
        return response.json()
      }
    })
}

export const login = (url, user) => (dispatch) => {
  return fetch(url + "/token", {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json'
      },
      dataType: "json",
      body: JSON.stringify(user)
    }).then(response => {
      if(response.ok) {
        return response.json()
      } else {
        return response.json();
      }
    })
}

export const getRole = (url, roleId, token) => (dispatch) => {
  return fetch(url + "/role/" + roleId, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return response.json()
      }
    })
}

export const getRoles = (url, token) => (dispatch) => {
  return fetch(url + "/role", {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return response.json()
      }
    })
}

export const getDefaults = () => (dispatch) => {
    return fetch(process.env.PUBLIC_URL + "/config.json", {
        method: 'GET',
        dataType: "json"
      }).then(response => {
        return response.json()
    });
}

export const getVersion = () => (dispatch) => {
    return fetch(process.env.PUBLIC_URL + "/version.json", {
        method: 'GET',
        dataType: "json"
      }).then(response => {
        return response.json()
    });
}

export const verifyToken = (url, token) => (dispatch) => {
  return fetch(url + "/token", {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json'
      },
      body: token,
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return response.json()
      }
    })
}

export const getUserProfile = (url, token) => (dispatch) => {

  return fetch(url + "/user/self/profile", {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return response.json()
      }
    })
}

export const getTokenList = (url, token) => (dispatch) => {

  return fetch(url + "/token.list", {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return response.json()
      } else {
        return response.json().then(json => {
          return {
            contentType: response.headers.get("Content-Type"),
            json: json
          }
        })
      }
    })
}

export const createToken = (url, token, data) => (dispatch) => {

  return fetch(url + "/token.create", {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data)
    }).then(response => {
      if(response.ok) {
        return response.json()
      } else {
        return response.json().then(json => {
          return {
            contentType: response.headers.get("Content-Type"),
            json: json
          }
        })
      }  
    })
}

export const getPublicKeyCurrentUtcDateTime = (url, token) => (dispatch) => {

  return fetch(url + "/protectedParameters/settings", {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      }
    }).then(response => {
      if(response.ok) {
        return response.json()
      }
    })
}

export const getTokenEnquire = (url, token, data) => (dispatch) => {

  return fetch(url + "/token.enquire", {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data)
    }).then(response => {
      if(response.ok) {
        return response.json()
      } else {
        return response.json().then(json => {
          return {
            contentType: response.headers.get("Content-Type"),
            json: json
          }
        })
      }
    })
}

export const removeCertificateRequest = (url, token, data) => (dispatch) => {

  return fetch(url + "/certificateRequest.remove", {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data)
    }).then(response => {
      if(response.ok) {
        return true;
      } else {
        return response.json().then(json => {
          return {
            contentType: response.headers.get("Content-Type"),
            json: json
          }
        })
      }
    })
}

export const removeCertificateRequest2f = (url, token, data) => (dispatch) => {

  return fetch(url + "/certificateRequest.remove.2f", {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data)
    }).then(response => {
      if(response.ok) {
        return true;
      } else {
        return response.json().then(json => {
          return {
            contentType: response.headers.get("Content-Type"),
            json: json
          }
        })
      }
    })
}

export const removeCertificate = (url, token, data) => (dispatch) => {

  return fetch(url + "/certificate.remove", {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data)
    }).then(response => {
      if(response.ok) {
        return true;
      } else {
        return response.json().then(json => {
          return {
            contentType: response.headers.get("Content-Type"),
            json: json
          }
        })
      }
    })
}

export const removeCertificate2f = (url, token, data) => (dispatch) => {

  return fetch(url + "/certificate.remove.2f", {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data)
    }).then(response => {
      if(response.ok) {
        return true;
      } else {
        return response.json().then(json => {
          return {
            contentType: response.headers.get("Content-Type"),
            json: json
          }
        })
      }
    })
}

export const removeConfidants = (url, token, data) => (dispatch) => {

  return fetch(url + "/confidants.remove", {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data)
    }).then(response => {
      if(response.ok) {
        return true;
      } else {
        return response.json().then(json => {
          return {
            contentType: response.headers.get("Content-Type"),
            json: json
          }
        })
      }
    })
}

export const getTokenInfoCached = (url, token, data) => (dispatch) => {

  return fetch(url + "/token.info", {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data)
    }).then(response => {
      if(response.ok) {
        return response.json();
      } else {
        return response.json().then(json => {
          return {
            contentType: response.headers.get("Content-Type"),
            json: json
          }
        })
      }
    })
}

export const removePrivateKey = (url, token, data) => (dispatch) => {

  return fetch(url + "/privateKey.remove", {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data)
    }).then(response => {
      if(response.ok) {
        return true
      } else {
        return response.json().then(json => {
          return {
            contentType: response.headers.get("Content-Type"),
            json: json
          }
        })
      }
    })
}

export const removeAllByID = (url, token, data) => (dispatch) => {

  return fetch(url + "/allById.remove", {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data)
    }).then(response => {
      if(response.ok) {
        return true
      } else {
        return response.json().then(json => {
          return {
            contentType: response.headers.get("Content-Type"),
            json: json
          }
        })
      }
    })
}

export const removeAllByID2f = (url, token, data) => (dispatch) => {

  return fetch(url + "/allById.remove.2f", {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data)
    }).then(response => {
      if(response.ok) {
        return true
      } else {
        return response.json().then(json => {
          return {
            contentType: response.headers.get("Content-Type"),
            json: json
          }
        })
      }
    })
}

export const blockToken = (url, token, data) => (dispatch) => {

  return fetch(url + "/token.block", {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data)
    }).then(response => {
      if(response.ok) {
        return response.json()
      } else {
        return response.json().then(json => {
          return {
            contentType: response.headers.get("Content-Type"),
            json: json
          }
        })
      }
    })
}

export const blockToken2f = (url, token, data) => (dispatch) => {

  return fetch(url + "/token.block.2f", {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data)
    }).then(response => {
      if(response.ok) {
        return response.json()
      } else {
        return response.json().then(json => {
          return {
            contentType: response.headers.get("Content-Type"),
            json: json
          }
        })
      }
    })
}

export const unblockToken = (url, token, data) => (dispatch) => {

  return fetch(url + "/token.unblock", {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data)
    }).then(response => {
      if(response.ok) {
        return response.json()
      } else {
        return response.json().then(json => {
          return {
            contentType: response.headers.get("Content-Type"),
            json: json
          }
        })
      }
    })
}

export const unblockToken2f = (url, token, data) => (dispatch) => {

  return fetch(url + "/token.unblock.2f", {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data)
    }).then(response => {
      if(response.ok) {
        return response.json()
      } else {
        return response.json().then(json => {
          return {
            contentType: response.headers.get("Content-Type"),
            json: json
          }
        })
      }
    })
}

export const importCertificate = (url, token, data) => (dispatch) => {

  return fetch(url + "/certificate.import", {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data)
    }).then(response => {
      if(response.ok) {
        return true;
      } else {
        return response.json().then(json => {
          return {
            contentType: response.headers.get("Content-Type"),
            json: json
          }
        })
      }
    })
}

export const importToken = (url, token, data) => (dispatch) => {

  return fetch(url + "/token.import", {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data)
    }).then(response => {
      if(response.ok) {
        return response.json();
      } else {
        return response.json().then(json => {
          return {
            contentType: response.headers.get("Content-Type"),
            json: json
          }
        })
      }
    })
}

export const init2f = (url, data, token) => (dispatch) => {
  return fetch(url + "/token.user.2f", {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data),
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return response.json();
      } else {
        return response.json();
      }
    })
}

export const login2f = (url, data, token) => (dispatch) => {
  return fetch(url + "/token.user", {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data),
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return response.json();
      } else {
        return response.json();
      }
    })
}

export const getRegistrarCips = (url, token) => (dispatch) => {
  return fetch(url + "/registrarCips", {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return response.json();
      } else {
        return response.json().then(json => {
          return {
            contentType: response.headers.get("Content-Type"),
            json: json
          }
        })
      }
    })
}

export const getCertificateProfiles = (url, cipId, token) => (dispatch) => {
  return fetch(url + "/certificateProfiles/" + cipId, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return response.json();
      } else {
        return response.json().then(json => {
          return {
            contentType: response.headers.get("Content-Type"),
            json: json
          }
        })
      }
    })
}

export const generateKeys = (url, data, token) => (dispatch) => {

  return fetch(url + "/keypair.generate", {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data)
    }).then(response => {
      if(response.ok) {
        return response.json();
      } else {
        return response.json().then(json => {
          return {
            contentType: response.headers.get("Content-Type"),
            json: json
          }
        })
      }
    })
}

export const generateKeys2f = (url, data, token) => (dispatch) => {

  return fetch(url + "/keypair.generate.2f", {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data)
    }).then(response => {
      if(response.ok) {
        return response.json();
      } else {
        return response.json().then(json => {
          return {
            contentType: response.headers.get("Content-Type"),
            json: json
          }
        })
      }
    })
}

export const certificateIssue = (url, data, token) => (dispatch) => {

  return fetch(url + "/certificate.issue", {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data)
    }).then(response => {
      if(response.ok) {
        return response.json();
      } else {
        return response.json().then(json => {
          return {
            contentType: response.headers.get("Content-Type"),
            json: json
          }
        })
      }
    })
}

export const certificateIssue2f = (url, data, token) => (dispatch) => {

  return fetch(url + "/certificate.issue.2f", {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data)
    }).then(response => {
      if(response.ok) {
        return response.json();
      } else {
        return response.json().then(json => {
          return {
            contentType: response.headers.get("Content-Type"),
            json: json
          }
        })
      }
    })
}

export const update2fUserInfo = (url, data, token) => (dispatch) => {
  return fetch(url + "/user/self/2f", {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data),
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return true;
      } else {
        return response.json();
      }
    })
}

export const getAvailabelChannels = (url, token) => (dispatch) => {
  return fetch(url + "/2f", {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return response.json();
      } else {
        return response.json();
      }
    })
}

export const removeToken = (url, token, data) => (dispatch) => {

  return fetch(url + "/token.remove", {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data)
    }).then(response => {
      if(response.ok) {
        return true
      } else {
        return response.json().then(json => {
          return {
            contentType: response.headers.get("Content-Type"),
            json: json
          }
        })
      }
    })
}

export const removeToken2f = (url, token, data) => (dispatch) => {

  return fetch(url + "/token.remove.2f", {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data)
    }).then(response => {
      if(response.ok) {
        return true
      } else {
        return response.json().then(json => {
          return {
            contentType: response.headers.get("Content-Type"),
            json: json
          }
        })
      }
    })
}

export const resetToken = (url, token, data) => (dispatch) => {

  return fetch(url + "/token.reset", {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data)
    }).then(response => {
      if(response.ok) {
        return true
      } else {
        return response.json().then(json => {
          return {
            contentType: response.headers.get("Content-Type"),
            json: json
          }
        })
      }
    })
}

export const resetToken2f = (url, token, data) => (dispatch) => {

  return fetch(url + "/token.reset.2f", {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data)
    }).then(response => {
      if(response.ok) {
        return true
      } else {
        return response.json().then(json => {
          return {
            contentType: response.headers.get("Content-Type"),
            json: json
          }
        })
      }
    })
}

export const changePinByPin = (url, token, data) => (dispatch) => {

  return fetch(url + "/pinByPin.change", {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data)
    }).then(response => {
      if(response.ok) {
        return true
      } else {
        return response.json().then(json => {
          return {
            contentType: response.headers.get("Content-Type"),
            json: json
          }
        })
      }
    })
}

export const changePinByPin2f = (url, token, data) => (dispatch) => {

  return fetch(url + "/pinByPin.change.2f", {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data)
    }).then(response => {
      if(response.ok) {
        return true
      } else {
        return response.json().then(json => {
          return {
            contentType: response.headers.get("Content-Type"),
            json: json
          }
        })
      }
    })
}

export const changePinByPuk = (url, token, data) => (dispatch) => {

  return fetch(url + "/pinByPuk.change", {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data)
    }).then(response => {
      if(response.ok) {
        return true
      } else {
        return response.json().then(json => {
          return {
            contentType: response.headers.get("Content-Type"),
            json: json
          }
        })
      }
    })
}

export const changePinByPuk2f = (url, token, data) => (dispatch) => {

  return fetch(url + "/pinByPuk.change.2f", {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data)
    }).then(response => {
      if(response.ok) {
        return true
      } else {
        return response.json().then(json => {
          return {
            contentType: response.headers.get("Content-Type"),
            json: json
          }
        })
      }
    })
}

export const changeUserPassword = (url, pass, token) => (dispatch) => {
  return fetch(url + "/user/self/password", {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(pass),
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return true;
      } else {
        return response.json();
      }
    })
}

export const kerberosAuth = (url) => (dispatch) => {
  return fetch(url, {
      method: 'GET',
      headers: {
          'credentials': 'include'
      },
      mode: "no-cors"
    }).then(response => {
      if(response.ok) {
        return response.json();
      } else {
        return response.headers.forEach(function(val, key) { console.log(key + ' -> ' + val); });;
      }
    })
}