import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setShowUserInfo } from "../actions/localStates"

import i18next from 'i18next'

class UserInfoPopup extends Component {

  constructor(props) {
      super(props);
      this.state = {
      }

      this.closePopup = this.closePopup.bind(this)
  }

  componentDidMount() {

  }

  closePopup () {
    this.props.actions.setShowUserInfo(false);
  }


  render() {
      return (
          <div className='popup'>
              <div className="popup_inner_user_info">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{marginTop: "15px", paddingLeft: "0px", paddingRight: "0px"}}>
                <h4 style={{paddingLeft: "15px"}}>{i18next.t("userInfoTitle")}</h4>
                <div className="row col-12" style={{paddingLeft: "0px", paddingRight: "0px"}}>
                  
                </div>
                <div className="row col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{paddingLeft: "0px", paddingRight: "0px"}}>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12" style={{paddingLeft: "0px", paddingRight: "0px"}}>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{paddingLeft: "0px", paddingRight: "0px"}}>
                              <label className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px"}}>{i18next.t("uniqueIdLabel")}</label>
                              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <input className="form-control" type="text" disabled value={this.props.selectedUser.userData.userName} />
                      </div>
                    </div>
                    <label className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", marginTop: "5px", paddingBottom: "0px"}}>{i18next.t("nameLabel")}</label>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <input className="form-control" type="text" disabled value={this.props.selectedUser.userData.firstName} />
                    </div>
                    <label className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", marginTop: "5px", paddingBottom: "0px"}}>{i18next.t("surnameLabel")}</label>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <input className="form-control" type="text" disabled value={this.props.selectedUser.userData.secondName} />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingLeft: "0px", paddingBottom: "0px"}}>{i18next.t("rolesLabel")}</label>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 user_info_roles" style={{border: "1px solid rgba(0, 0, 0, 0.125)", borderRadius: "0.25rem", "listStyle": "none"}}>
                              <div className="row col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{paddingLeft: "0px", paddingTop: "5px"}}>
                                {
                                  this.props.selectedUser.userData.roles.length > 0
                                      ? this.props.selectedUser.userData.roles.map((role, index) => {
                                        return (<div key={index} className="form-check form-check-inline col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{marginBottom: "5px", paddingRight: "0px"}}>
                                      <input type="checkbox" disabled checked={this.props.selectedUser.userData.roles !== undefined ? true : false } className="form-check-input" />
                                      <label className="form-check-label">
                                        { 
                                          i18next.t(this.props.selectedUser.userData.roles[index])
                                        }
                                      </label>
                                    </div>)
                                  })
                                  : null
                                }
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{paddingLeft: "0px", paddingRight: "0px"}}>
                  <label className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", marginTop: "5px", paddingBottom: "0px"}}>{i18next.t("departmentLabel")}</label>
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <input className="form-control" type="text" disabled value={this.props.selectedUser.userData.department} />
                  </div>
                </div>

                <div className="row col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{paddingLeft: "0px", paddingRight: "0px"}}>
                  <label className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", marginTop: "5px", paddingBottom: "0px"}}>{i18next.t("positionLabel")}</label>
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <input className="form-control" type="text" disabled value={this.props.selectedUser.userData.position} />
                  </div>
                </div>

                <div style={{paddingTop: "10px"}}>
                    <button className="btn btn-info" style={{float: "right", marginRight: "15px", width: "75px", marginBottom: "15px"}} onClick={this.closePopup}>Ok</button>
                </div>
              </div>
          </div>
      </div>
      );
  }
}

function mapStateToProps(state) {
    return {
        base: state.base,
        selectedUser: state.loginReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
        setShowUserInfo
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInfoPopup);