import { combineReducers } from 'redux';
import loginReducer from './loginReducer';
import tokenListReducer from './tokenListReducer';
import userReducer from './userReducer'
import keyGenReducer from "./keyGenReducer"
import keyGenProfilesReducer from "./keyGenProfilesReducer"

const baseState = {
    drawerOpen: false,
    selectedTab: 0,
    createClientMode: false,
    createUserMode: false,
    showCreateOrEditClientForm: false,
    showCreateOrEditUserForm: false,
    authUrl: "",
    serviceUrl: "",
    clientId: "",
    defaultsSetted: false,
    version: "",
    build: "",
    showUserInfo: false,
    searchIsOpen: false,
    isIEBrowser: false,
    domain: "",
    apps: [],
    globalProcessing: false,
    supportAD: "false",
    status: "success",
    action: "",
    message: "",
    resultMessagesArr: null,
    showActionResult: false,
    expires_in: null,
    selectedAuthProvider: "",
    authProviders:[],
    needRefresh: false,
    showLogoutHandler: false,
    numberOfPinInput: 3,
    showError: false,
    expireTime: 0,
    showSessionEndHandler: false,
    needToLogout: false,
    needReLogin: false,
    waitingTime: 0,
    timeToLogout: 15,
    isKerberos: false,
    showTrusteesPopup: false
}

const base = (state = baseState, { type, payload }) => {

    switch (type) {
    	case "CHANGE_DRAWER_STATE": {
			return {
				...state,
				drawerOpen: payload
			}
		}

        case "CHANGE_SEARCH_STATE": {
            return {
                ...state,
                searchIsOpen: payload
            }
        }

		case "CHANGE_SELECTED_TAB": {
			return {
				...state,
				selectedTab: payload
			}
		}

        case "SET_CREATE_CLIENT_MODE": {
            return {
                ...state,
                createClientMode: payload
            }
        }

        case "SET_CREATE_USER_MODE": {
            return {
                ...state,
                createUserMode: payload
            }
        }

        case "SET_SHOW_CREATE_OR_EDIT_FORM": {
            return {
                ...state,
                showCreateOrEditClientForm: payload
            }
        }

        case "SET_SHOW_CREATE_OR_EDIT_USER_FORM": {
            return {
                ...state,
                showCreateOrEditUserForm: payload
            }
        }

        case 'SET_SHOW_USER_INFO': {
            return {
                ...state,
                showUserInfo: payload
            }
        }

        case "SET_DEFAULTS": {
            return {
                ...state,
                authUrl: payload.authUrl,
                serviceUrl: payload.serviceUrl,
                clientId: payload.clientId,
                selectedAuthProvider: payload.authProviders ? payload.authProviders[0].url : "https://ccs-dev-api.cipher.kiev.ua/ccs/auth-2",
                defaultsSetted: true,
                apps: payload.apps || [],
                domain: "." + payload.domain || "",
                supportAD: payload.supportAD || "false",
                authProviders: payload.authProviders || [{url: "https://ccs-dev-api.cipher.kiev.ua/ccs/auth-2", clientTitle: "Шифр-Auth"}],
                numberOfPinInput: parseInt(payload.numberOfPinInput, 10) || 3,
                waitingTime: parseInt(payload.waitingTimeSec, 10) || 30,
                timeToLogout: parseInt(payload.timeToLogoutMin, 10) || 15
            }
        }

        case 'SET_VERSION': {
            return {
                ...state,
                version: payload.version,
                build: payload.build
            }
        }

        case 'SET_IS_IE_BROWSER': {
            return {
                ...state,
                isIEBrowser: payload
            }
        }

        case 'SET_GLOBAL_PROCESSING': {
            return {
                ...state,
                globalProcessing: payload
            }
        }

        case "SET_SHOW_ACTION_RESULT": {
            return {
                ...state,
                showActionResult: payload
            }
        }

        case "SET_ACTION_RESULT_DATA": {
            return {
                ...state,
                status: payload.status,
                action: payload.action,
                message: payload.message
            }
        }

        case "SET_ACTION_RESULT_ARR": {
            return {
                ...state,
                resultMessagesArr: payload
            }
        }

        case "SET_EXPIRE_IN_TIME": {
            return {
                ...state,
                expires_in: payload
            }
        }

        case "SET_SELECTED_PROVIDER": {
            return {
                ...state,
                selectedAuthProvider: payload
            }
        }

        case "SET_NEED_REFRESH": {
            return {
                ...state,
                needRefresh: payload
            }
        }

        case "SET_SHOW_LOGOUT_HANDLER": {
            return {
                ...state,
                showLogoutHandler: payload
            }
        }

        case "SET_SHOW_ERROR": {
            return {
                ...state,
                showError: payload
            }
        }

        case "SET_EXPIRE_TIME": {
            return {
                ...state,
                expireTime: payload
            }
        }

        case "SET_SHOW_SESSION_END_HANDLER": {
            return {
                ...state,
                showSessionEndHandler: payload
            }
        }

        case "SET_NEED_TO_LOGOUT": {
            return {
                ...state,
                needToLogout: payload
            }
        }

        case "SET_NEED_RELOGIN": {
            return {
                ...state,
                needReLogin: payload
            }
        }

        case "SET_IS_KERBEROS": {
            return {
                ...state,
                isKerberos: payload
            }
        }

        case "SHOW_TRUSTEES_POPUP": {
            return {
                ...state,
                showTrusteesPopup: payload
            }
        }

        default:
            return state;
    }

}

export default combineReducers({
    base: base,
    loginReducer,
    tokenListReducer,
    userReducer,
    keyGenReducer,
    keyGenProfilesReducer
});


