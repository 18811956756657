import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { } from "../actions/localStates"
import { } from "../actions/api"

import Drawer from '../components/Drawer';

class DrawerContainer extends Component {
	constructor() {
		super();
		this.state = {

		};

	}

	componentDidMount() {

	}

	componentWillUnmount () {

	}

	render() {
		return (
			<Drawer />
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer,
        config: state.ConfigReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {

    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DrawerContainer);